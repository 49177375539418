import React, { ReactElement } from 'react';
import { CardGrid } from '@morressier/ts';
import { CardSkeleton } from './Card';

interface GridSkeletonProps {
  items?: number;
  distribution?: 'list' | 'grid';
}

export const GridSkeleton: React.FC<GridSkeletonProps> = ({ items = 3, distribution = 'list' }) => {
  const Items: ReactElement[] = [];

  for (let i = 0; i < items; i += 1) {
    Items.push(<CardSkeleton key={i} />);
  }

  return <CardGrid distribution={distribution}>{Items}</CardGrid>;
};
