import React, { useCallback, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import useSWR from 'swr';

import { fetchProfileInfo } from 'api/people';
import { useAuthContext } from 'store';

import { getPageRange } from './helpers';
import { ProfileModal } from './ProfileModal';

const PAGE_LIMIT = 3;

export const useParticipantProfile = (event: MorressierEvent, speakers: EventSpeaker[]) => {
  const eventId = event.id;
  const { query } = useRouter();
  const navigationTitle = query.sessionId && 'Other Speakers in this Session';
  const [currentParticipant, setCurrentParticipant] = useState<string | undefined>();
  const getCurrParticipantPage = useCallback(() => {
    const totalPages = Math.floor(speakers.length / PAGE_LIMIT);
    const page = Math.floor(
      speakers.findIndex(speaker => speaker._id === currentParticipant) / PAGE_LIMIT,
    );

    return page >= totalPages ? totalPages - 1 : page;
  }, [speakers, currentParticipant]);
  const [currentPage, setCurrentPage] = useState(getCurrParticipantPage());
  const range = getPageRange({
    page: currentPage,
    limit: PAGE_LIMIT,
    total: speakers.length,
  });
  let currentPagePeople =
    currentPage >= 0
      ? speakers
          .filter(participant => participant._id !== currentParticipant)
          .slice(range.start, range.end)
      : [];
  currentPagePeople =
    currentPagePeople.length < 3
      ? speakers.filter(participant => participant._id !== currentParticipant).slice(-PAGE_LIMIT)
      : currentPagePeople;
  const { data, error } = useSWR(
    currentParticipant ? [eventId, currentParticipant] : null,
    fetchProfileInfo,
  );

  const setPage = (page: -1 | 1) => setCurrentPage(currentPage + page);

  const renderProfileInfoModal = () =>
    currentParticipant && (
      <ProfileModal
        participant={{
          ...data,
          event,
          userId: currentParticipant,
          navigationTitle,
          showNavigation: currentPagePeople.length > 0,
          showExploreAllSpeakers: !query.sessionId,
          selectParticipant: setCurrentParticipant,
          otherParticipants: currentPagePeople,
          isLoading: !data && !error,
          pagination: {
            paging: range.paging,
            previous: range.previous,
            next: range.next,
            setPrevious: () => setPage(-1),
            setNext: () => setPage(1),
          },
        }}
        cancelAction={() => {
          setCurrentParticipant(undefined);
        }}
      />
    );

  useEffect(() => {
    if (currentParticipant) {
      document.body.style.setProperty('overflow', 'hidden');
    } else if (!currentParticipant) {
      document.body.style.removeProperty('overflow');
    }

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, [currentParticipant]);

  useEffect(() => {
    setCurrentPage(getCurrParticipantPage());
  }, [getCurrParticipantPage]);

  return {
    currentParticipant,
    renderProfileInfoModal,
    setCurrentParticipant,
    loading: !data && !error,
  };
};

export const usePrivateProfile = (isPublicProfile: boolean) => {
  const { state: authState } = useAuthContext();
  const hideDetails = !authState?.profile?.id && !isPublicProfile;

  return {
    hideDetails,
    isLoading: authState?.isLoadingProfile,
  };
};
