import { Flexbox, Spinner, Text } from '@morressier/ts';

import { Grid } from '@material-ui/core';
import { FaLock, FaUnlock } from 'react-icons/fa';

import { BuyATicketButton } from 'components/BuyTicketModal/BuyATicketButton';
import { Line } from 'components/BuyTicketModal/styles';
import { getAPSEventId } from 'components/EventStandardContainer/hooks';
import { SignUpButton } from 'components/SingUpButton.tsx';
import { FeatureFlags } from 'config/featureFlags';
import { useAuthedUser } from 'hooks/useIsAuthedUser';
import { useAuthContext } from 'store';
import { useCurrency } from 'store/modules/eventTicketing';

import * as Styles from '../../containers/EventHome/styles';
import { RegisterButton } from './RegisterButton';
import { canBuyTickets } from './utils';

export const MorressierOrExternalTicketing: React.FC<{
  provider: 'morressier' | 'external';
  ticketingProduct: TicketingProduct;
  currency: string;
}> = ({ provider, currency, ticketingProduct }) =>
  provider === 'morressier' ? (
    <div>
      <Text color="grey" fontWeight="semiBold">
        Tickets start at...
      </Text>
      <Text color="secondaryBrand" fontWeight="semiBold">
        {currency}
        {ticketingProduct.ticket?.price || '0.00'}
      </Text>
      <Line />
      <BuyATicketButton fullWidth />
    </div>
  ) : (
    <div>
      <Line />
      <BuyATicketButton openExternal fullWidth />
    </div>
  );

export const TicketingWidget: React.FC<{
  event: MorressierEvent;
  ticketingProduct: TicketingProduct;
  hasAccessToProduct: boolean;
  isLoadingAccess: boolean;
}> = ({ event, ticketingProduct, hasAccessToProduct, isLoadingAccess }) => {
  const { state: authState } = useAuthContext();
  const isAuthed = useAuthedUser();
  const fullName = authState?.profile?.full_name;
  const hasAccess = hasAccessToProduct;
  const { sales_setup } = ticketingProduct;
  const allowBuyTicket = canBuyTickets(sales_setup);

  const isTicketingFlagEnabled = FeatureFlags.Event_Ticketing.isEnabled();
  const currency = useCurrency();

  // current APS event don't follow ticketing and reg business model. We need to hide the widget for such event
  const isAPSEvent = !!getAPSEventId(event.id);

  const provider = event.ticketing?.provider;
  if (isAPSEvent || !provider) return null;

  return isTicketingFlagEnabled ? (
    <>
      <Grid item xs={12} md={3}>
        <Styles.BuyTicketCard>
          <div>
            <Text color="grey" fontWeight="bold" size="caption_new">
              {event.embargoed ? (
                <>
                  <Styles.SvgWrapper>
                    <FaLock size="0.5rem" />
                  </Styles.SvgWrapper>
                  Private Event
                </>
              ) : (
                <>
                  <Styles.SvgWrapper>
                    <FaUnlock size="0.5rem" />
                  </Styles.SvgWrapper>
                  Public Event
                </>
              )}
            </Text>
            <Text fontWeight="bold">{event.name}</Text>
          </div>
          <>
            {isLoadingAccess || authState?.isLoadingProfile ? (
              <Flexbox style={{ height: 'inherit' }} justifyContent="center" alignItems="center">
                <Spinner size="2rem" />
              </Flexbox>
            ) : (
              <>
                {hasAccess ? (
                  <div>
                    <Text>👋🏽 Hallo{fullName && ` ${fullName}`}, Welcome to the Event.</Text>
                    <Line style={{ margin: '1rem 0' }} />
                    {ticketingProduct.ticketEnabled &&
                      ['external', 'morressier'].includes(provider) && (
                        <Text size="caption_new" color="grey">
                          {allowBuyTicket
                            ? 'Want to invite your colleagues? '
                            : "You can't add more tickets. Sales are closed. "}
                          <BuyATicketButton openExternal asLink fullWidth />
                        </Text>
                      )}
                  </div>
                ) : (
                  <>
                    {ticketingProduct.ticketEnabled &&
                    ['external', 'morressier'].includes(provider) ? (
                      <MorressierOrExternalTicketing
                        ticketingProduct={ticketingProduct}
                        provider={provider as 'external' | 'morressier'}
                        currency={currency}
                      />
                    ) : (
                      <div>
                        <Text color="grey" fontWeight="semiBold">
                          Free Event.
                        </Text>
                        <Line />
                        {isAuthed ? <RegisterButton fullWidth /> : <SignUpButton fullWidth />}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </Styles.BuyTicketCard>
      </Grid>
    </>
  ) : null;
};
