import { Text } from '@morressier/ts';

import * as Styles from './styles';

export const OrganizerCard: React.FC<{
  eventName?: string;
  organizationName?: string;
  organizationLogo?: string;
}> = ({ eventName, organizationName, organizationLogo }) => (
    <Styles.OrganizerCardWrapper>
      {organizationLogo && (
        <Styles.OrganiserImage
          src={organizationLogo}
          alt={
            eventName
              ? `The logo of the organization ${eventName}`
              : 'Here is placed the logo of the organization'
          }
        />
      )}
      <Text>{organizationName}</Text>
    </Styles.OrganizerCardWrapper>
  );
