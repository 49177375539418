import React from 'react';
import { Menu, MenuButton, MenuList, MenuItem } from '@reach/menu-button';
import { BiCalendarPlus } from 'react-icons/bi';
import { google, outlook, ics, CalendarEvent } from 'calendar-link';
import { Text, borderRadius, color, zIndex, colorFromPalette } from '@morressier/ts';
import styled from 'styled-components';
import { CalendarIcon } from 'icons';
import { useSesionUrl } from 'hooks/useSessionUrl';
import { useRegAndTicketingContext } from 'store/modules/eventTicketing';

const StyledMenuButton = styled(MenuButton).attrs({
  color: 'secondary',
})<{ small?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  font-size: ${props => (props.small ? '0.875rem' : '1rem')};
  color: ${color('secondaryBrand')};
  border: 1px solid ${color('lightGrey')};
  background: none;
  appearance: none;
  cursor: pointer;
  ${borderRadius('small')}

  &[aria-expanded="true"] {
    border-color: ${color('secondaryBrand')};
  }

  &:hover:not(:disabled) {
    border-color: ${colorFromPalette('borderHover')};
  }
`;

const StyledMenuList = styled(MenuList)`
  z-index: ${zIndex('popperFlyout')};

  &[data-reach-menu-items],
  &[data-reach-menu-list] {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0.25rem 0;
    ${borderRadius('small')};
    background: ${color('white')};
    box-shadow: rgba(0, 0, 0, 0.17) 0px 0px 8px 0px;
  }

  [data-reach-menu-item] {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 1rem;
    color: ${color('secondaryBrand')};
    width: 100%;
    cursor: pointer;

    .calendar-icon {
      margin-right: 1rem;
    }
  }

  [data-reach-menu-item][data-selected] {
    background-color: ${color('sidebarGrey')};
    color: inherit;
  }
`;

type CalendarEventType =
  | '_id'
  | 'description'
  | 'name'
  | 'start_date'
  | 'end_date'
  | 'event'
  | 'location'
  | 'title'
  | 'type';
interface CalendarExportDropdownProps {
  session: Pick<MorressierSession | MorressierNetworkingSession, CalendarEventType>;
  hideLabel?: boolean;
  type?: MorressierSessionType;
  eventId?: string;
  meetingUrl?: string;
  onExportClick?: () => void;
}

const CalendarExportDropdown: React.FC<CalendarExportDropdownProps> = ({
  session,
  hideLabel,
  type,
  eventId,
  meetingUrl,
  onExportClick,
}) => {
  const sessionUrl = useSesionUrl({ ...session, type }, eventId);
  const url = meetingUrl || sessionUrl;
  const urlTag = `<a href="${url}">${url}</a>`;

  let accessLink = '';
  if (meetingUrl) {
    accessLink = `Join meeting: ${urlTag}`;
  } else if (url) {
    accessLink = `View Session: ${urlTag}`;
  }

  const outLookDescription = `${
    session.description ? `${session.description}<br/><br/>` : ''
  }${accessLink}`;
  const iCalDescription = `${session.description ? `${session.description}\n\n` : ''}${accessLink}`;
  const calendarEvent: CalendarEvent = {
    title: session?.title || session?.name,
    description: `${session.description ? `${session.description}\n\n` : ''}${accessLink}`,
    start: session.start_date,
    end: session.end_date,
    location: session.location,
  };

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onCalendarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    stopPropagation(e);
    if (onExportClick) onExportClick();
  };
  const { processAction } = useRegAndTicketingContext();

  return (
    <Menu>
      <StyledMenuButton
        style={{ paddingRight: hideLabel ? '0.5rem' : undefined }}
        onClick={stopPropagation}
      >
        <BiCalendarPlus size={20} style={{ marginRight: hideLabel ? '0' : '0.5rem' }} />
        {!hideLabel && 'Add to Calendar'}
      </StyledMenuButton>
      <StyledMenuList>
        <MenuItem
          onClick={onCalendarClick}
          onSelect={() => processAction(() => window.open(google(calendarEvent), '_blank'))}
        >
          <CalendarIcon type="google" />{' '}
          <Text size="body1_new" fontWeight="semiBold">
            Calendar (Google)
          </Text>
        </MenuItem>
        <MenuItem
          onClick={onCalendarClick}
          onSelect={() =>
            processAction(() => {
              const link = document.createElement('a');
              link.href = ics({ ...calendarEvent, description: iCalDescription });
              link.download = `session-${session._id}.ics`;
              link.click();
            })
          }
        >
          <CalendarIcon type="default" />{' '}
          <Text size="body1_new" fontWeight="semiBold">
            iCal (Apple)
          </Text>
        </MenuItem>
        <MenuItem
          onClick={onCalendarClick}
          onSelect={() => {
            processAction(() => {
              window.open(outlook({ ...calendarEvent, description: outLookDescription }), '_blank');
            });
          }}
        >
          <CalendarIcon type="outlook" />{' '}
          <Text size="body1_new" fontWeight="semiBold">
            Outlook Calendar
          </Text>
        </MenuItem>
      </StyledMenuList>
    </Menu>
  );
};

export default CalendarExportDropdown;
