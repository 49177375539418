import { Dispatch, SetStateAction, useState } from 'react';

import {
  Button,
  Flexbox,
  PlainButton,
  SimpleText,
  Spinner,
  StyledLink,
  Text,
} from '@morressier/ts';

import { useFormContext } from 'react-hook-form';
import { FaCartPlus } from 'react-icons/fa';

import { useRegAndTicketingContext } from 'store/modules/eventTicketing';

import { applyTax, CreatedOrder, FE_Discount, FormValues } from '.';
import { ApplyCoupons, Discount } from './ApplyCoupons';
import { OrderPayload, Steps, useTickettingCart } from './hooks';
import { countSpecificTicketInOrder } from './SelectTicketStep';
import * as Styles from './styles';
import { moneyFormat } from './utils';

export const OrderSummary: React.FC<{
  activeStep: Steps;
  handleProceed: (forceLogin?: boolean, totalIsZero?: boolean) => Promise<void>;
  isGuest: boolean | undefined;
  cart: ReturnType<typeof useTickettingCart>['cart'];
  currentPayload?: OrderPayload;
  setIsGuest: Dispatch<SetStateAction<boolean | undefined>>;
  isCreatingOrder: boolean;
  createdOrder?: CreatedOrder;
  total: number;
  tax: number;
  appliedDiscount: FE_Discount | null;
  setDiscountData: Dispatch<SetStateAction<Discount | undefined>>;
  removeDiscount: () => Promise<void>;
  handleUpdate: (goToNext?: boolean) => Promise<void>;
}> = ({
  cart,
  handleProceed,
  activeStep,
  setIsGuest,
  isCreatingOrder,
  createdOrder,
  total,
  appliedDiscount,
  setDiscountData,
  removeDiscount,
  handleUpdate,
  currentPayload,
  tax,
  ...props
}) => {
  const { getValues } = useFormContext<FormValues>();
  const { currency } = useRegAndTicketingContext().state;
  const couponText = useState('');

  const hasCustomerEmail = getValues('payingCustomer').email;

  const [isDeleting, setIsDeleting] = useState(false);
  const [isCompletingOrder, setIsCompletingOrder] = useState(false);

  const handleFreeTicket = async () => {
    setIsCompletingOrder(true);
    handleProceed(false, true).finally(() => setIsCompletingOrder(false));
  };

  const showRegisterForFreeTickets =
    (total === 0 || appliedDiscount?.finalTotal === 0) &&
    cart.length > 0 &&
    activeStep === Steps.Details;

  return (
    <>
      <Styles.OrderSummaryContainer>
        <Text size="h4_new" fontWeight="semiBold" style={{ marginBottom: '3rem' }}>
          Order Summary
        </Text>

        {cart.length && currentPayload?.items.length ? (
          <Flexbox flexDirection="column" justifyContent="stretch" style={{ height: '100%' }}>
            <Styles.SummarrySection>
              {cart.map(cartItem => {
                const count = countSpecificTicketInOrder(cartItem.id, currentPayload);
                const grossPrice = applyTax(cartItem.price, cartItem.tax_info);

                return (
                  !!count && (
                    <Flexbox key={cartItem.id} className="mb1" justifyContent="space-between">
                      <Styles.CartItemText>
                        {cartItem.name} {count > 1 && `(x${count})`}
                      </Styles.CartItemText>
                      <Flexbox
                        flexDirection="column"
                        alignItems="flex-end"
                        style={{ maxWidth: '50%' }}
                      >
                        <Styles.CartItemText>
                          {currency}
                          {moneyFormat(grossPrice * count)}
                        </Styles.CartItemText>
                        {cartItem.tax_info && (
                          <Styles.CartItemText
                            color="grey"
                            style={{ textAlign: 'end' }}
                            size="caption_new"
                          >
                            (Incl. {cartItem.tax_info.rate}% {cartItem.tax_info.name})
                          </Styles.CartItemText>
                        )}
                      </Flexbox>
                    </Flexbox>
                  )
                );
              })}

              {appliedDiscount && (
                <Flexbox flexDirection="column" style={{ position: 'relative' }}>
                  <Flexbox alignItems="baseline" justifyContent="space-between">
                    <Styles.CartItemText>Coupon</Styles.CartItemText>
                    <PlainButton
                      onClick={async () => {
                        setIsDeleting(true);
                        removeDiscount().finally(() => {
                          setIsDeleting(false);
                          const [_, setCouponText] = couponText;
                          setCouponText('');
                        });
                      }}
                    >
                      <StyledLink color="primaryLink">
                        Remove {isDeleting && <Spinner size="0.75rem" />}
                      </StyledLink>
                    </PlainButton>
                  </Flexbox>
                  <Flexbox
                    justifyContent="space-between"
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Styles.CartItemText style={{ fontWeight: 400 }}>
                      {appliedDiscount?.code}{' '}
                      {appliedDiscount.type === 'percent' && (
                        <span className="ml1">{appliedDiscount.discount_text}</span>
                      )}
                    </Styles.CartItemText>
                    <Styles.CartItemText>
                      - {currency}
                      {moneyFormat(appliedDiscount.discount_amount)}
                    </Styles.CartItemText>
                  </Flexbox>

                  {appliedDiscount.hasInsufficientCoupons && (
                    <Styles.InfoBox type="message">
                      <Text size="body3">
                        Coupons have been applied to all applicable tickets. No more can be used.
                      </Text>
                    </Styles.InfoBox>
                  )}
                </Flexbox>
              )}

              <Styles.Line />
              <Flexbox justifyContent="space-between">
                <Styles.CartItemText style={{ fontWeight: 'bold' }}>Total</Styles.CartItemText>
                <Flexbox flexDirection="column" alignItems="flex-end">
                  <Styles.CartItemText style={{ fontWeight: 'bold' }}>
                    {currency}
                    {moneyFormat(total)}
                  </Styles.CartItemText>
                  <Styles.CartItemText color="grey" size="caption_new">
                    (Incl. {currency}
                    {tax.toFixed(2)} taxes)
                  </Styles.CartItemText>
                </Flexbox>
              </Flexbox>
              {total > 0 && (
                <>
                  {createdOrder ? (
                    <ApplyCoupons
                      discount={appliedDiscount}
                      handleDiscounts={_discount => setDiscountData(_discount)}
                      createdOrder={createdOrder}
                      handleUpdate={handleUpdate}
                      couponText={couponText}
                    />
                  ) : (
                    !appliedDiscount && (
                      <div className="mt4">
                        <Text color="grey" size="body2_new">
                          <span style={{ fontWeight: 'bold' }}>Note:</span> You can apply coupons in
                          the Payment step.
                        </Text>
                      </div>
                    )
                  )}
                </>
              )}
            </Styles.SummarrySection>
            <Flexbox flexDirection="column" justifyContent="flex-end" data-name="proceed-section">
              {showRegisterForFreeTickets ? (
                <Button
                  loading={isCompletingOrder}
                  fullWidth
                  color="primary"
                  onClick={handleFreeTicket}
                >
                  Register
                </Button>
              ) : (
                activeStep !== Steps.Payment && (
                  <Button
                    fullWidth
                    color="primary"
                    onClick={() => handleProceed(activeStep === Steps.Tickets && !hasCustomerEmail)}
                    loading={isCreatingOrder}
                  >
                    {activeStep === Steps.Tickets && !hasCustomerEmail
                      ? 'Sign in to Proceed'
                      : 'Proceed'}
                  </Button>
                )
              )}

              {activeStep === Steps.Tickets && !hasCustomerEmail && (
                <Flexbox justifyContent="center" className="mt1">
                  <PlainButton
                    color="grey"
                    style={{ textAlign: 'center', textDecoration: 'underline' }}
                    onClick={() => {
                      setIsGuest(true);
                      handleProceed();
                    }}
                  >
                    <Text size="body2_new" color="secondaryBrand">
                      or continue as a Guest
                    </Text>
                  </PlainButton>
                </Flexbox>
              )}
            </Flexbox>
          </Flexbox>
        ) : (
          <Flexbox
            style={{ height: '100%' }}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="0.5rem"
          >
            <Styles.IconWrapper>
              <FaCartPlus />
            </Styles.IconWrapper>
            <Text textAs="span" fontWeight="semiBold" style={{ textAlign: 'center' }}>
              Your ticket cart is empty, select a ticket.
            </Text>
          </Flexbox>
        )}
        {
          // TODO: Delete this after testing
          props.children
        }
      </Styles.OrderSummaryContainer>
    </>
  );
};
