import { GridSize } from '@material-ui/core';

export const getSessionsRenderInfo = (sessions?: MorressierSession[], isBelowSm?: boolean) => {
  const titleClamp = isBelowSm || (sessions && sessions?.length <= 2) ? 3 : 2;
  const cardMode: 'List' | 'Grid' = sessions && sessions?.length <= 2 ? 'List' : 'Grid';

  const getGridProps = (): {
    xs?: boolean | GridSize;
    sm?: boolean | GridSize;
    lg?: boolean | GridSize;
  } => {
    if (sessions && sessions?.length <= 2) return { xs: 12, sm: 12 };

    return { xs: 12, sm: 6, lg: 4 };
  };

  return {
    titleClamp,
    cardMode,
    getGridProps,
  };
};
