import { Flexbox, StyledInput, Text } from '@morressier/ts';

import { Grid } from '@material-ui/core';
import { useFormContext, useFormState } from 'react-hook-form';

import { FormValues } from '.';
import { useScrollToError } from './hooks';

export const DetailsStep: React.FC<{
  profile?: MorressierUserProfile;
  isGuest?: boolean;
  items: FormValues['items'];
}> = ({ profile, isGuest, items }) => {
  const disabled = isGuest ? false : !!profile;

  const { register, control, clearErrors, getValues } = useFormContext<FormValues>();
  const { errors } = useFormState({
    control,
  });

  const customerError = errors.payingCustomer;
  const payingCustomer = getValues('payingCustomer');
  useScrollToError(errors);

  const itemsArray = Object.values(items);
  const isSingleItemSoldOut = itemsArray.length === 1 && itemsArray[0].isAvailable === false;

  return (
    <>
      {isSingleItemSoldOut && (
        <Flexbox>
          <Text color={isSingleItemSoldOut ? 'red' : 'grey'} size="body2_new" fontWeight="bold">
            {itemsArray[0].ticketName} {isSingleItemSoldOut ? ' - Sold out' : ''}
          </Text>
        </Flexbox>
      )}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Text fontWeight="semiBold">First name*</Text>
          <StyledInput
            isInvalid={!!customerError?.firstName}
            disabled={disabled || isSingleItemSoldOut}
            {...(disabled
              ? { value: payingCustomer.firstName }
              : register('payingCustomer.firstName'))}
          />
          {!!customerError?.firstName && (
            <Text color="red" size="caption_new">
              {customerError.firstName.message}
            </Text>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Text fontWeight="semiBold">Last name*</Text>
          <StyledInput
            isInvalid={!!customerError?.lastName}
            disabled={disabled || isSingleItemSoldOut}
            {...(disabled
              ? { value: payingCustomer.lastName }
              : register('payingCustomer.lastName'))}
          />
          {!!customerError?.lastName && (
            <Text color="red" size="caption_new">
              {customerError.lastName.message}
            </Text>
          )}
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <Text fontWeight="semiBold">Email*</Text>
          <StyledInput
            disabled={disabled || isSingleItemSoldOut}
            {...(disabled ? { value: payingCustomer.email } : register('payingCustomer.email'))}
            isInvalid={!!customerError?.email}
            onChange={() => clearErrors('payingCustomer.email')}
          />
          {!!customerError?.email && (
            <Text color="red" size="caption_new">
              {customerError.email.message}
            </Text>
          )}
        </Grid>
      </Grid>

      {Object.keys(items).length > 1 &&
        Object.keys(items).map((key, i) => {
          const error =
            errors?.[`items.${key}.attendee.email`]?.message ||
            errors.items?.[key]?.attendee?.email?.message;

          const isSoldOut = items[key].isAvailable === false;

          return (
            <Grid key={key} container spacing={4} style={{ marginTop: '1rem' }}>
              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <Flexbox>
                  <Text as="span" size="h5_new" fontWeight="bold">
                    Ticket {i + 1}
                  </Text>
                </Flexbox>
                <Text color={isSoldOut ? 'red' : 'grey'} size="body2_new" fontWeight="bold">
                  {items[key].ticketName} {isSoldOut ? ' - Sold out' : ''}
                </Text>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledInput
                  value={items[key].ticketId}
                  {...register(`items.${key}.ticketId`)}
                  style={{ display: 'none' }}
                  disabled={isSoldOut}
                />

                <Text fontWeight="semiBold">First name</Text>
                <StyledInput
                  {...register(`items.${key}.attendee.firstName`)}
                  disabled={isSoldOut}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Text fontWeight="semiBold">Last name</Text>
                <StyledInput {...register(`items.${key}.attendee.lastName`)} disabled={isSoldOut} />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <Text fontWeight="semiBold">Email*</Text>
                <StyledInput
                  {...register(`items.${key}.attendee.email`)}
                  isInvalid={!!error}
                  onChange={() => clearErrors(`items.${key}`)}
                  disabled={isSoldOut}
                />
                {!!error && (
                  <Text color="red" size="caption_new">
                    {error}
                  </Text>
                )}
              </Grid>
            </Grid>
          );
        })}
    </>
  );
};
