import styled, { css } from 'styled-components';
import { Text, Button, borderRadius, color, font } from '@morressier/ts';
import { breakpoints } from 'components/ThemedPage/muiTheme';
import { DatePicker } from '@material-ui/pickers';

export const ParticipantName = styled(Text).attrs({
  size: 'h3_new',
  fontWeight: 'bold',
})``;

export const ParticipantInfo = styled(Text).attrs({
  size: 'body1_new',
})`
  margin-top: 0.5rem;
`;

export const UserCardStyle = styled.div`
  background-color: ${color('white')};
`;

export const FeaturedLabel = styled(Text)`
  background-color: #f7c105;
  ${borderRadius('small')};
  padding: 0 0.5rem 0 0.5rem;
  text-align: center;
  display: inline-table;
`;

export const WrapperModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  margin: 0;
  border-radius: 0;
  overflow: auto;
`;

export const CloseModalBanner = styled.div`
  margin-bottom: 3.8rem;
  padding: 1rem 2rem;
  background-color: ${color('sidebarGrey')};

  @media screen and (max-width: ${breakpoints.sm}px) {
    padding: 1rem;
  }

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: 1rem 2rem;
  }

  > div {
    display: flex;
    justify-content: flex-end;
  }
`;

export const CircleButton = styled(Button)`
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 100%;
`;

export const StyledDatePicker = styled(DatePicker)`
  .MuiFormLabel-root {
    padding-left: 0.5rem;
    line-height: 1.5rem;
  }
  .MuiInput-input {
    ${borderRadius('small')};
    ${font()};
    height: initial;
    color: ${color('secondaryBrand')};
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.625rem;
    border: 1px solid ${color('lightGrey')};
    &::placeholder {
      color: ${color('grey')};
      ${font()};
    }
    &:disabled {
      color: ${color('grey')};
      border: 1px solid ${color('disabledInputBorder')};
      background-color: ${color('disabledInputBackground')};
    }
    &:focus {
      outline: 0;
      border-color: ${color('secondaryBrand')};
      box-shadow: 0 0 0 0.1rem rgba(66, 77, 93, 0.25);
    }
  }
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }
`;

export const DateWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ErrorText = styled(Text).attrs(props => ({
  color: 'red',
  size: 'body2_new',
}))``;

export const Input = styled.input<{ hasError?: boolean; fitContent?: boolean }>`
  display: flex;
  border-radius: 0.1875rem;
  padding: 0.5rem;
  border: 1px solid ${color('lightGrey')};
  width: 100%;
  font-size: 16px;
  outline: none;
  margin-top: 2px;
  color: ${color('secondaryBrand')};

  &:focus {
    border-color: ${color('secondaryBrand')};
    box-shadow: 0 0 0 0.1rem rgba(66, 77, 93, 0.25);
  }

  ${props =>
    props.hasError &&
    css`
      border: 1px solid ${color('red')};
      &:focus {
        box-shadow: unset;
        border-color: ${color('red')};
      }
    `};

  ::placeholder {
    color: ${color('grey')};
  }

  :disabled {
    background-color: ${color('disabledInputBackground')};
  }

  ${({ fitContent }) =>
    fitContent &&
    css`
      width: fit-content;
      min-width: fit-content;
      width: intrinsic;
      min-width: intrinsic;
    `}
`;
