import { Button } from '@morressier/ts';

import { useLogIn } from 'hooks/useLogIn';

export const SignUpButton: React.FC<{ fullWidth?: boolean }> = props => {
  const { handleSignUp } = useLogIn();

  return (
    <Button
      // style={{ display: 'none' }}
      fullWidth={props.fullWidth}
      onClick={handleSignUp}
      color="primary"
    >
      Sign Up
    </Button>
  );
};
