const parse = (href = '') => {
  const reURLInformation = new RegExp(
    [
      '^(https?:)//', // protocol
      '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
      '(/{0,1}[^?#]*)', // pathname
      '(\\?[^#]*|)', // search
      '(#.*|)$', // hash
    ].join(''),
  );
  const match = href.match(reURLInformation);
  return { hostname: match ? match[2] : '' };
};

export const formatWebsiteShortNotation = (url: string): string => {
  const { hostname } = parse(url);
  return hostname;
};
