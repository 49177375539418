import { formatDistanceToNowStrict, formatDistanceToNow, differenceInSeconds } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

const dateInWords = (date: string, strict: boolean) =>
  strict
    ? formatDistanceToNowStrict(new Date(date), { roundingMethod: 'floor' })
    : formatDistanceToNow(new Date(date));

export const useTimeToNow = (date?: string, strict?: boolean) => {
  const [timeToNow, setTimeToNow] = useState(date ? dateInWords(date, !!strict) : null);

  useEffect(() => {
    if (!date) return undefined;

    const interval = setInterval(() => {
      setTimeToNow(dateInWords(date, !!strict));
    }, 1000);

    return () => clearInterval(interval);
  }, [date, strict]);

  return { timeToNow };
};

const ONE_DAY = 60 * 60 * 24;
const ONE_HOUR = 60 * 60;
const ONE_MINUTE = 60;

export const useCountDownTimer = (end: Date) => {
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const diffInSeconds = differenceInSeconds(end, currentTime);

  const getCoundown = () => {
    if (diffInSeconds <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(diffInSeconds / ONE_DAY);
    const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR);
    const minutes = Math.floor((diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE);
    const seconds = diffInSeconds - days * ONE_DAY - hours * ONE_HOUR - minutes * ONE_MINUTE;

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const countdown = useMemo(getCoundown, [diffInSeconds]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (diffInSeconds > 0) {
      interval = setInterval(() => {
        const now = new Date().getTime();
        setCurrentTime(now);
      }, 1000);
    }

    return () => interval && clearInterval(interval);
  }, [diffInSeconds]);

  return { countdown, diffInSeconds };
};
