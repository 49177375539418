import { addDays } from 'date-fns';
import { CreatedOrder } from '.';
import { Discount } from './ApplyCoupons';
import { Steps, TicketCart } from './hooks';

export type StoreData = {
  value: {
    cart: TicketCart[];
    activeStep?: Steps;
    isModalOpen?: boolean;
    createdOrder?: CreatedOrder;
    coupon?: Discount;
  };
  expiryTime?: Date;
};

export const storageKey = (eventId: string) => `event-ticketting-store/${eventId}`;
/**
 * A temporal storage medium for ticketing cart and modal state.
 *
 * TODO: Explore other storage mediums later (innodb | redis)
 */
export class TicketingStorage {
  private static expiryTime = addDays(new Date(), 1);

  private static defaultExpirationTime() {
    return addDays(new Date(), 1);
  }

  static async save(key: string, value: StoreData['value'], expiryTime?: Date) {
    const data: StoreData = {
      value,
      expiryTime: expiryTime || this.expiryTime || this.defaultExpirationTime(),
    };

    localStorage.setItem(key, JSON.stringify(data));
  }

  static async delete(key: string) {
    localStorage.removeItem(key);
  }

  static async get(key: string) {
    const itemInStore = localStorage.getItem(key);

    if (!itemInStore) return null;

    try {
      const data: StoreData = JSON.parse(itemInStore);

      if (data.expiryTime && new Date() > new Date(data.expiryTime)) {
        await this.delete(key);
        return null;
      }

      return data;
    } catch (error) {
      return null;
    }
  }

  static async saveCart(key: string, cart: StoreData['value']['cart'], expiryTime?: Date) {
    const itemInStore = await this.get(key);

    const data: StoreData = {
      value: {
        ...itemInStore?.value,
        cart,
      },
      expiryTime: expiryTime || this.expiryTime || this.defaultExpirationTime(),
    };

    this.save(key, data.value, data.expiryTime);
  }

  static async saveStep(key: string, activeStep: Steps, expiryTime?: Date) {
    const itemInStore = await this.get(key);

    const data: StoreData = {
      value: {
        ...itemInStore?.value,
        cart: itemInStore?.value.cart || [],
        activeStep,
      },
      expiryTime: expiryTime || this.expiryTime || this.defaultExpirationTime(),
    };

    this.save(key, data.value, data.expiryTime);
  }

  static async setModal(key: string, isModalOpen: boolean, expiryTime?: Date) {
    const itemInStore = await this.get(key);

    const data: StoreData = {
      value: {
        ...itemInStore?.value,
        cart: itemInStore?.value.cart || [],
        isModalOpen,
      },
      expiryTime: expiryTime || this.expiryTime || this.defaultExpirationTime(),
    };

    this.save(key, data.value, data.expiryTime);
  }

  static async setCreatedOrder(key: string, createdOrder: CreatedOrder, expiryTime?: Date) {
    const itemInStore = await this.get(key);

    const data: StoreData = {
      value: {
        ...itemInStore?.value,
        cart: itemInStore?.value.cart || [],
        createdOrder,
      },
      expiryTime: expiryTime || this.expiryTime || this.defaultExpirationTime(),
    };

    this.save(key, data.value, data.expiryTime);
  }

  static async getCreatedOrder(key: string) {
    const itemInStore = await this.get(key);

    return itemInStore?.value.createdOrder;
  }

  static async setCoupon(key: string, coupon: Discount, expiryTime?: Date) {
    const itemInStore = await this.get(key);

    const data: StoreData = {
      value: {
        ...itemInStore?.value,
        cart: itemInStore?.value.cart || [],
        coupon,
      },
      expiryTime: expiryTime || this.expiryTime || this.defaultExpirationTime(),
    };

    this.save(key, data.value, data.expiryTime);
  }

  static async getCoupon(key: string) {
    const itemInStore = await this.get(key);

    return itemInStore?.value.coupon;
  }

  static async deleteCoupon(key: string) {
    const itemInStore = await this.get(key);
    delete itemInStore?.value.coupon;

    const data: StoreData = {
      value: {
        ...itemInStore?.value,
        cart: itemInStore?.value.cart || [],
      },
      expiryTime: itemInStore?.expiryTime,
    };

    this.save(key, data.value, data.expiryTime);
  }

  static async init() {
    const keys = Object.keys(localStorage);

    keys.forEach(key => {
      try {
        const item = localStorage.getItem(key);
        const parsedItem = item ? (JSON.parse(item) as StoreData) : null;

        if (parsedItem?.expiryTime) {
          const isExpired = new Date() > new Date(parsedItem.expiryTime);
          if (isExpired) {
            localStorage.removeItem(key);
          }
        }
      } catch (error) {
        //
      }
    });
  }
}
