import { UserEventAccess } from 'components/EventStandardContainer/hooks';

export const setUserHasAccess = (hasAccess: boolean) =>
  ({
    type: 'setUserHasAccess',
    payload: hasAccess,
  } as const);

export const setUserRoles = (userRoles: Omit<UserEventAccess, 'hasAccess'>) =>
  ({
    type: 'setUserRoles',
    payload: userRoles,
  } as const);

export const setIsTicketingModalOpen = (isOpen: boolean) =>
  ({
    type: 'setIsTicketingModalOpen',
    payload: isOpen,
  } as const);

export const setIsRegistrationModalOpen = (isOpen: boolean) =>
  ({
    type: 'setIsRegistrationModalOpen',
    payload: isOpen,
  } as const);

export const toggleTicketingModal = () =>
  ({
    type: 'toggleTicketingModal',
  } as const);

export const promptRegister = (isOpen: boolean) =>
  ({
    type: 'promptRegister',
    payload: isOpen,
  } as const);

export const promptBuyTicket = (isOpen: boolean) =>
  ({
    type: 'promptBuyTicket',
    payload: isOpen,
  } as const);

export type TicketingActions =
  | ReturnType<typeof setUserHasAccess>
  | ReturnType<typeof setUserRoles>
  | ReturnType<typeof toggleTicketingModal>
  | ReturnType<typeof setIsRegistrationModalOpen>
  | ReturnType<typeof promptBuyTicket>
  | ReturnType<typeof promptRegister>
  | ReturnType<typeof setIsTicketingModalOpen>;
