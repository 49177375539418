import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import remarkSuperSub from 'remark-supersub';
import { ITextProps, Text } from '@morressier/ts';

interface HeadingResolverProps {
  level: number;
  children: React.ReactNode;
}

const HeadingResolver = ({ level, children }: HeadingResolverProps) => {
  const componentPropsByLevel: ITextProps[] = [
    { size: 'h3_new', textAs: 'div', fontWeight: 'bold' },
    { size: 'h4_new', textAs: 'div', fontWeight: 'bold' },
    { size: 'h5_new', textAs: 'div', fontWeight: 'bold' },
    { size: 'h4_new', textAs: 'div', fontWeight: 'bold', style: { wordBreak: 'break-word' } },
    { size: 'h5_new', textAs: 'div', fontWeight: 'bold', style: { wordBreak: 'break-word' } },
  ];

  const textProps =
    level <= componentPropsByLevel.length
      ? componentPropsByLevel[level - 1]
      : componentPropsByLevel[componentPropsByLevel.length - 1];

  return <Text {...textProps}>{children}</Text>;
};

export const renderers = {
  h1: HeadingResolver,
  h2: HeadingResolver,
  h3: HeadingResolver,
  h4: HeadingResolver,
  h5: HeadingResolver,
  p: props => (
    <Text
      size="body1_new"
      textAs="p"
      style={{ wordBreak: 'break-word', margin: '1rem 0' }}
      {...props}
    />
  ),
  ol: props => (
    <Text
      size="body1_new"
      textAs="ol"
      {...props}
      style={{ padding: 'revert', wordBreak: 'break-word' }}
    />
  ),
  ul: props => (
    <Text
      size="body1_new"
      textAs="ul"
      {...props}
      style={{ padding: 'revert', wordBreak: 'break-word' }}
    />
  ),
  li: props => (
    <Text
      size="body1_new"
      textAs="li"
      {...props}
      style={{
        display: 'list-item',
        listStyle: props.ordered ? 'decimal' : 'initial',
      }}
    />
  ),
  root: props => <Text {...props} as="div" />,
  div: props => (
    <Text size="body1_new" textAs="div" {...props} style={{ wordBreak: 'break-word' }} />
  ),
  sub: props => (
    <Text
      {...props}
      as="span"
      style={{
        verticalAlign: 'sub',
        display: 'inline',
        fontSize: '0.75rem',
      }}
    />
  ),
  sup: props => (
    <Text
      {...props}
      as="span"
      style={{
        verticalAlign: 'super',
        display: 'inline',
        fontSize: '0.75rem',
      }}
    />
  ),
  strong: props => (
    <Text
      {...props}
      as="span"
      style={{
        fontWeight: '600',
        display: 'inline',
        verticalAlign: 'baseline',
      }}
    />
  ),
  em: props => (
    <Text
      {...props}
      as="span"
      style={{
        fontWeight: 'inherit',
        fontStyle: 'italic',
        display: 'inline',
        verticalAlign: 'baseline',
      }}
    />
  ),
  br: () => <br />,
};

interface MorressierMarkdownProps {
  source: string;
  customRenderers?: Record<string, unknown>;
}

const MorressierMarkdown: React.FC<MorressierMarkdownProps> = props => {
  const { source, customRenderers = {} } = props;

  return (
    <ReactMarkdown
      components={{ ...renderers, ...customRenderers }}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkSuperSub, [remarkGfm, { singleTilde: false }], remarkBreaks]}
    >
      {source}
    </ReactMarkdown>
  );
};

export default MorressierMarkdown;
