import React, { useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  left: 100;
  top: 0;
  z-index: 2050; // needs to be above chat app
  background-color: rgba(66, 77, 93, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClientOnlyPortal: React.FC<{
  selector: string;
}> = ({ children, selector }) => {
  const ref = useRef<HTMLElement | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(selector);
    if (!ref.current) {
      throw new Error(
        `No DOM Selector with matching id found. Add a dom selector with id ${selector} to the document body`,
      );
    }
    setMounted(true);
  }, [selector]);

  return mounted && ref.current ? createPortal(children, ref.current) : null;
};

// TODO: Delete this and update component in shared-ts to take in styles
export const PageBodyPortal: React.FC<{
  selector?: string;
  onClick?: () => unknown;
  style?: React.CSSProperties;
}> = ({ children, selector = '#modal-root', style, onClick = () => null }) => (
  <ClientOnlyPortal selector={selector}>
    <Overlay style={style} onClick={onClick}>
      <div
        onClick={(e: React.SyntheticEvent) => {
          e.stopPropagation();
        }}
        role="presentation"
      >
        {children}
      </div>
    </Overlay>
  </ClientOnlyPortal>
);
