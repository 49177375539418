import { useLocation } from './useLocation';

type SessionUrlType = Pick<
  MorressierSession | MorressierNetworkingSession,
  '_id' | 'event' | 'type'
>;

export const useSesionUrl = ({ _id, event, type }: SessionUrlType, eventId?: string) => {
  const location = useLocation();
  const isNetworkingSession = type === 'NETWORKING';

  return location?.origin
    ? `${location?.origin}/o/event/${event?.id ?? eventId}/${
        isNetworkingSession ? 'networking-' : ''
      }sessions/${_id}`
    : location?.origin;
};
