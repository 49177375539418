import { fetchPosterThumnails } from 'api/poster';
import { AuthorSectionProps } from 'components/User/ContributionSection';

export const enrichPosterWithThumbnail = async (
  eventId: string,
  items: MorressierPoster[] | AuthorSectionProps[] = [],
) => {
  // Filter out loading state ids
  const itemsIds = items.map(i => i.id).filter(id => !['1', '2', '3'].includes(id));
  const thumbnailsResponse = (
    itemsIds.length > 0 ? await fetchPosterThumnails(eventId, itemsIds) : []
  )?.reduce((acc, cur) => ({ ...acc, [cur._id]: cur }), {});

  const itemsWithThumbnails = items.map(i => ({
    ...i,
    ...(thumbnailsResponse ?? {})[i.id],
  }));

  return {
    data: itemsWithThumbnails,
  };
};
