import { useState } from 'react';

import { Flexbox } from '@morressier/ts';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { CircleButton } from 'components/User/style';

interface Props {
  currentPage: number;
  totalPages: number;
  onPreviousPage: (newPage: number) => void;
  onNextPage: (newPage: number) => void;
}

export const PaginationControls: React.FC<Props> = ({
  currentPage,
  totalPages,
  onPreviousPage,
  onNextPage,
}) => {
  const goToPreviousPage = () => {
    if (currentPage <= 1) return;

    onPreviousPage(currentPage - 1);
  };

  const goToNextPage = () => {
    if (currentPage >= totalPages) return;

    onNextPage(currentPage + 1);
  };

  return (
    <Flexbox justifyContent="flex-end" alignItems="center" gap="1rem">
      <CircleButton onClick={goToPreviousPage} disabled={currentPage <= 1}>
        <IoIosArrowBack />
      </CircleButton>

      <div>
        <b style={{ fontWeight: 'bold' }}>{currentPage}</b> of {totalPages}
      </div>

      <CircleButton onClick={goToNextPage} disabled={currentPage >= totalPages}>
        <IoIosArrowForward />
      </CircleButton>
    </Flexbox>
  );
};
