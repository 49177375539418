import { createContext, Dispatch, useContext, useEffect, useReducer } from 'react';

import { BuyTicketPrompt, RegistrationPrompt } from 'components/BuyTicketModal/BuyTicketPrompt';
import { useEventAccess, UserEventAccess } from 'components/EventStandardContainer/hooks';
import { FeatureFlags } from 'config/featureFlags';
import { useLocation } from 'hooks/useLocation';

import { BuyTicketModal } from '../../../components/BuyTicketModal';
import { storageKey, TicketingStorage } from '../../../components/BuyTicketModal/TicketingStorage';
import { useAuthContext } from '../auth';
import { useEventContext } from '../events';
import { TicketingActions } from './actions';
import * as reducerMethods from './actions';

type TicketingAndRegistrationState = {
  eventId: string;
  isPrivateEvent: boolean;
  isTicketedEvent: boolean;
  isBuyTicketModalOpen: boolean;
  prompt: {
    buyTicket: boolean;
    register: boolean;
  };
  userHasAccess?: boolean;
  userRoles?: Omit<UserEventAccess, 'hasAccess'>;
  isLoadingAccess?: boolean;
  currency: string;
  ticketedProduct: TicketingProduct | undefined;
  provider?: 'morressier' | 'external' | 'open';
};

const initialState: TicketingAndRegistrationState = {
  eventId: '',
  isPrivateEvent: false,
  isTicketedEvent: false,
  isBuyTicketModalOpen: false,
  prompt: {
    buyTicket: false,
    register: false,
  },
  currency: '',
  ticketedProduct: undefined,
};

type Callback = () => unknown;

const TicketingContext = createContext<{
  state: TicketingAndRegistrationState;
  dispatch: Dispatch<TicketingActions>;
  processAction: (callBack: Callback, forceCallback?: boolean) => void;
  refetchAccess: ReturnType<typeof useEventAccess>['refetchAccess'];
}>({
  state: initialState,
  dispatch: () => null,
  processAction: () => null,
  refetchAccess: () => null,
});

const reducer = (
  state: TicketingAndRegistrationState,
  action: TicketingActions,
): TicketingAndRegistrationState => {
  switch (action.type) {
    case 'setUserHasAccess': {
      return { ...state, userHasAccess: action.payload };
    }

    case 'setUserRoles': {
      return { ...state, userRoles: action.payload };
    }

    case 'setIsTicketingModalOpen': {
      const newValue = action.payload;
      TicketingStorage.setModal(storageKey(state.eventId), newValue);
      return { ...state, isBuyTicketModalOpen: newValue };
    }

    case 'promptBuyTicket': {
      return {
        ...state,
        prompt: {
          ...state.prompt,
          buyTicket: action.payload,
        },
      };
    }

    case 'promptRegister': {
      return {
        ...state,
        prompt: {
          ...state.prompt,
          register: action.payload,
        },
      };
    }

    case 'toggleTicketingModal': {
      const newValue = !state.isBuyTicketModalOpen;
      TicketingStorage.setModal(storageKey(state.eventId), newValue);
      return { ...state, isBuyTicketModalOpen: newValue };
    }

    default:
      return initialState;
  }
};

export const TicketingAndRegistrationProvider: React.FC<
  {
    eventId: string;
    isPrivateEvent: boolean;
    isTicketedEvent: boolean;
  } & Partial<TicketingAndRegistrationState> & {
      isLoadingAccess: boolean;
      currency: string;
      refetchAccess: ReturnType<typeof useEventAccess>['refetchAccess'];
      ticketedProduct: TicketingProduct | undefined;
      provider?: 'morressier' | 'external' | 'open';
    }
> = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...props });

  const search = useLocation()?.search;

  useEffect(() => {
    if (search?.includes('?private-ticket=')) {
      dispatch(reducerMethods.setIsTicketingModalOpen(true));
    }
  }, [search]);

  const { state: event } = useEventContext();

  useEffect(() => {
    if (props.userHasAccess && !state.userHasAccess) {
      dispatch(reducerMethods.setUserHasAccess(true));
    }

    if (props.userRoles) {
      dispatch(reducerMethods.setUserRoles(props.userRoles));
    }
  }, [props.userHasAccess, props.userRoles, state.userHasAccess]);

  const isTicketingFlagEnabled = FeatureFlags.Event_Ticketing.isEnabled();

  // certain actions are now blocked, we want to enforece users to buy ticket or register on our platform
  const processAction = (callBack: Callback, forceCallback?: boolean) => {
    if (!isTicketingFlagEnabled) {
      return callBack();
    }

    if (state.provider === 'morressier' && state.userHasAccess) {
      return callBack();
    }

    if (
      state.provider !== 'morressier' &&
      ((state.isTicketedEvent && state.userHasAccess) ||
        (state.userRoles && Object.values(state.userRoles).some(role => role === true)) ||
        forceCallback)
    ) {
      return callBack();
    }

    return state.isTicketedEvent
      ? dispatch(reducerMethods.promptBuyTicket(true))
      : dispatch(reducerMethods.promptRegister(true));
  };

  const { state: authState } = useAuthContext();
  const provider = event?.ticketing?.provider;

  return (
    <TicketingContext.Provider
      value={{
        state: {
          ...state,
          isLoadingAccess: props.isLoadingAccess,
          currency: props.currency,
          provider,
        },
        dispatch,
        processAction,
        refetchAccess: props.refetchAccess,
      }}
    >
      {authState?.isLoadingProfile === false && isTicketingFlagEnabled && (
        <BuyTicketModal refetchAccess={props.refetchAccess} event={event as MorressierEvent} />
      )}
      {state.prompt.buyTicket && <BuyTicketPrompt />}
      {state.prompt.register && <RegistrationPrompt />}
      {children}
    </TicketingContext.Provider>
  );
};

export const useRegAndTicketingContext = () => {
  const context = useContext(TicketingContext);

  if (!context.state || !context.dispatch) {
    throw new Error('useEventContext must be used within EventsProvider');
  }

  return context;
};

export const useCurrency = () => useRegAndTicketingContext().state.currency;
