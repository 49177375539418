export const getPageRange = ({
  page,
  limit,
  total,
}: {
  page: number;
  limit: number;
  total: number;
}) => {
  if (total <= 4) return { start: 0, end: limit, paging: false };
  const totalPages = Math.ceil(total / limit);

  const start = page * limit;
  const end = (page + 1) * limit;

  return {
    paging: true,
    previous: page > 0,
    next: page + 1 < totalPages,
    start,
    end,
  };
};
