import { useRouter } from 'next/router';

import { SimpleText, Text, Flexbox, RectangleSkeleton, Button } from '@morressier/ts';

import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { BiCoffeeTogo } from 'react-icons/bi';

import { GridSkeleton } from 'components/Skeleton/Grid';
import { useSessionBookmarksInView } from 'containers/SessionsList/hooks';
import { SessionsListItem } from 'containers/SessionsList/SessionsListItem';
import { useRunAt } from 'hooks/useRunAt';
import { useTimeToNow } from 'hooks/useTimeNow';
import { logEvent } from 'utils/eventTracking';

import * as ContainerStyles from '../styles';
import { getSessionsRenderInfo } from './helpers';
import { useSessionsHappeningNow } from './hooks';
import * as Styles from './styles';

type Props = {
  event: MorressierEvent;
  data: ReturnType<typeof useSessionsHappeningNow>['data'];
  refetch: () => Promise<void>;
  nextSessionStartTime?: string;
  isEmbargoed: boolean;
  isLoading: boolean;
};

export const SessionsHappeningNow: React.FC<Props> = props => {
  const total = props.data?.count;
  const sessions = props.data?.sessions;
  const eventId = props.event.id;

  const { timeToNow } = useTimeToNow(props.nextSessionStartTime, true);
  const timeToNextSession = timeToNow && `Stay tuned as the next Session starts in ${timeToNow}.`;

  const { push } = useRouter();
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down('md'));
  const isBelowSm = useMediaQuery(theme.breakpoints.down('xs'));

  const { titleClamp, cardMode, getGridProps } = getSessionsRenderInfo(sessions, isBelowSm);

  const clossestEndTime =
    sessions?.length &&
    [...sessions].sort((a, b) => new Date(a.end_date).getTime() - new Date(b.end_date).getTime())[0]
      .end_date;

  // we want to refetch/update the list once any session ends
  useRunAt({
    date: clossestEndTime,
    callback: props.refetch,
  });

  const { bookmarkData, elementRef } = useSessionBookmarksInView(eventId, sessions);

  if (props.isLoading)
    return (
      <ContainerStyles.Section>
        <ContainerStyles.SubTitle>
          <RectangleSkeleton width="250px" />
        </ContainerStyles.SubTitle>
        <GridSkeleton distribution="grid" />
      </ContainerStyles.Section>
    );

  return (
    <>
      {total ? (
        <ContainerStyles.Section ref={elementRef}>
          <ContainerStyles.SubTitle>Happening Now</ContainerStyles.SubTitle>
          <Styles.SubtitleCountCaption>
            {sessions?.length} of {total} Sessions
          </Styles.SubtitleCountCaption>

          <Grid container spacing={isBelowLg ? 2 : 4} style={{ marginBottom: '32px' }}>
            {sessions?.map(session => (
              <Grid item {...getGridProps()} key={session._id}>
                <SessionsListItem
                  session={session}
                  event={props.event}
                  embargoed={props.isEmbargoed}
                  titleClamp={titleClamp}
                  isSponsored={session.is_sponsored}
                  cardMode={cardMode}
                  withBookmarkData={bookmarkData}
                />
              </Grid>
            ))}
          </Grid>

          <Button
            color="primary"
            onClick={() => {
              logEvent('STANDALONE_CLICKED_EXPLORE_SESSIONS');
              push(`/event/${eventId}/sessions?time=happening`);
            }}
          >
            Explore Sessions Happening Now
          </Button>
        </ContainerStyles.Section>
      ) : (
        timeToNextSession && (
          <ContainerStyles.Section>
            <ContainerStyles.SubTitle className="mt4">Happening now</ContainerStyles.SubTitle>
            <Flexbox justifyContent="center" style={{ marginBottom: '8rem' }}>
              <Styles.NoMoreUpcoming>
                <Styles.NoMoreUpcomingSVG>
                  <BiCoffeeTogo size="48px" color="#8E949E" />
                </Styles.NoMoreUpcomingSVG>

                <>
                  <SimpleText
                    as="span"
                    color="secondaryBrand"
                    fontWeight="semiBold"
                    style={{ fontSize: '24px' }}
                    className="mb2 mt2"
                  >
                    There are no Sessions happening at the moment.
                  </SimpleText>
                  <Text as="span" fontWeight="regular" color="grey">
                    {timeToNextSession}
                  </Text>
                </>
              </Styles.NoMoreUpcoming>
            </Flexbox>
          </ContainerStyles.Section>
        )
      )}
    </>
  );
};
