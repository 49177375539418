import React from 'react';
import Image, { ImageProps } from 'next/image';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

//
// * IMAGE URL transformation required to enable auto-upload
// folder map name can not be shorter then 8 chars
// auto upload settings have to be set/updated in cloudinary.com/console/
//

const transformGcpUrl = (full_url: string) =>
  full_url.replace(
    publicRuntimeConfig.imagesHost || publicRuntimeConfig.URL_GCP,
    publicRuntimeConfig.imagesFolderMapping || publicRuntimeConfig.FOLDER_GCP,
  );

// Only Posters and Presentations are hsoted on AWS, by default, should be GCP;
const transformAwsUrl = (full_url: string) =>
  full_url.replace(publicRuntimeConfig.URL_AWS, publicRuntimeConfig.FOLDER_AWS);

const NextImage = (props: ImageProps) => {
  const isGCP = typeof props.src === 'string' && props.src.includes('storage.googleapis.com');
  const isAWS = typeof props.src === 'string' && props.src.includes('amazonaws.com');

  const parseSrc = (full_url: string) => {
    const nonSignedUrl = full_url.split('?')[0];

    if (isGCP) return transformGcpUrl(nonSignedUrl);
    if (isAWS) return transformAwsUrl(nonSignedUrl);
    return full_url;
  };

  const src = typeof props.src === 'string' && parseSrc(props.src);

  const imgProps = {
    ...props,
    src,
  } as ImageProps;

  // Add custom loader to prevent adding the default cloudinary path
  if (!isGCP && !isAWS) {
    imgProps.loader = resolverProps => resolverProps.src;
  }

  return <Image {...imgProps} />;
};

export default NextImage;
