import { MAX_PARTICIPANTS_PER_SESSION } from 'constants/networkingSessions';
import { uniqBy } from 'lodash';

export enum Browser {
  Chrome,
  Firefox,
  Safari,
  Opera,
  Edge,
  Unkown,
}

export const getSpotsAvailability = (reservedSpots, sessionParticipants, userId) => {
  const hasReservedSpot = reservedSpots.some(id => id === userId);
  const usedSpots = (sessionParticipants || []).reduce(
    (acc, curr) => {
      if (!acc.isConnected) acc.isConnected = curr._id === userId;

      if (reservedSpots.find(id => id === curr._id)) {
        acc.reserved.push(curr);
      } else {
        acc.general.push(curr);
      }
      return acc;
    },
    {
      isConnected: false as boolean,
      general: [] as MorressierUser[],
      reserved: [] as MorressierUser[],
    },
  );
  const availableSpots = hasReservedSpot
    ? reservedSpots.length - usedSpots.reserved.length
    : MAX_PARTICIPANTS_PER_SESSION - reservedSpots.length - usedSpots.general.length;
  const isFull = availableSpots === 0;

  return {
    hasReservedSpot,
    usedSpots,
    availableSpots,
    isFull,
  };
};

export const detectBrowser = () => {
  const { userAgent } = navigator;
  let browser: Browser;

  switch (userAgent) {
    case userAgent.match(/chrome|chromium|crios/i)?.input:
      browser = Browser.Chrome;
      break;
    case userAgent.match(/firefox|fxios/i)?.input:
      browser = Browser.Firefox;
      break;
    case userAgent.match(/safari/i)?.input:
      browser = Browser.Safari;
      break;
    case userAgent.match(/edg/i)?.input:
      browser = Browser.Edge;
      break;
    default:
      browser = Browser.Unkown;
      break;
  }

  return browser;
};

export const isElementInViewport = (el: HTMLElement, topOffset = 0) => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= topOffset &&
    rect.left >= 0 &&
    rect.right <=
      (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
};

export const isVideoPlaying = (video: HTMLVideoElement) =>
  !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);

export const getSpeakersFromPoster = (poster?: SessionPoster) => {
  const presenters = poster?.presentations?.presenters || [];

  return uniqBy([...presenters], 'id');
};
