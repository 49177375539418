import { EmptyLogoWrapper } from 'components/AvatarBanner';
import { PageBodyPortal } from 'components/EventStandardContainer/Portal';
import { useRegAndTicketingContext } from 'store/modules/eventTicketing';
import * as regAndTicketingActions from 'store/modules/eventTicketing/actions';
import { faTicketAlt } from '@fortawesome/pro-solid-svg-icons';
import { IconHolder } from 'containers/SingleSession/AdRenderer';
import { Text } from '@morressier/ts';
import { useAuthContext } from 'store';
import { SignUpButton } from 'components/SingUpButton.tsx';
import * as Styles from './styles';
import { BuyATicketButton } from './BuyATicketButton';
import { AlreadyHaveAnAccount } from './AlreadyHaveAnAccount';
import { RegisterButton } from './RegisterButton';

export const BuyTicketPrompt: React.FC = () => {
  const { dispatch } = useRegAndTicketingContext();

  return (
    <PageBodyPortal style={{ top: 0, backgroundColor: 'rgba(66,77,93, 0.5)' }}>
      <Styles.RegAndTicketingPrompts>
        <Styles.CloseModal
          onClick={() => {
            dispatch(regAndTicketingActions.promptBuyTicket(false));
          }}
        />

        <EmptyLogoWrapper>
          <IconHolder icon={faTicketAlt} color="grey" />
        </EmptyLogoWrapper>
        <div>
          <Text size="h4_new" fontWeight="semiBold">
            This is a Ticketed Event
          </Text>
          <Text>
            Purchase to access Live Sessions, Networking Sessions, and connect with your peers using
            instant messaging and 1-1 meetings.{' '}
          </Text>
          <BuyATicketButton />
        </div>
        <AlreadyHaveAnAccount />
      </Styles.RegAndTicketingPrompts>
    </PageBodyPortal>
  );
};

export const RegistrationPrompt: React.FC = () => {
  const { dispatch } = useRegAndTicketingContext();
  const { state: authState } = useAuthContext();
  const isAuthed = !!authState?.profile?.id;

  return (
    <PageBodyPortal style={{ top: 0, backgroundColor: 'rgba(66,77,93, 0.5)' }}>
      <Styles.RegAndTicketingPrompts>
        <Styles.CloseModal
          onClick={() => {
            dispatch(regAndTicketingActions.promptRegister(false));
          }}
        />

        {isAuthed ? (
          <div>
            <Text size="h4_new" fontWeight="semiBold">
              Enjoy This Free Event
            </Text>
            <Text>
              Join to access Live Sessions, Networking Sessions, and connect with your peers using
              instant messaging and 1-1 meetings.
            </Text>
            <RegisterButton />
          </div>
        ) : (
          <div>
            <Text size="h4_new" fontWeight="semiBold">
              Create a Morressier Account
            </Text>
            <Text>
              Your free account will allow you to network, explore early-stage research, attend
              Events and connect with your peers using instant messaging and 1-1 meetings.
            </Text>
            <SignUpButton />
          </div>
        )}
        <AlreadyHaveAnAccount />
      </Styles.RegAndTicketingPrompts>
    </PageBodyPortal>
  );
};
