import { color, Flexbox, zIndex } from '@morressier/ts';
import { breakpoints } from 'components/ThemedPage/muiTheme';
import styled, { css } from 'styled-components';

export const BlurryBackground = styled.div<{
  isBlocked: boolean;
  isLoading?: boolean;
  enableFadeoutBehaviour?: boolean;
}>`
  ${props =>
    props.isBlocked &&
    css`
      position: fixed;
      width: 100%;
      z-index: 10;
      filter: blur(0.65rem);
      -webkit-filter: blur(0.65rem);
      cursor: not-allowed;
      background-color: white;
      pointer-events: none;
    `}

  ${({ enableFadeoutBehaviour, isBlocked }) =>
    enableFadeoutBehaviour &&
    isBlocked &&
    css`
      filter: unset;
      -webkit-filter: unset;
      height: 100vh;

      &:after {
        position: absolute;
        content: ' ';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        z-index: ${zIndex('modal')};
      }
    `}
    
    ${({ isLoading, enableFadeoutBehaviour }) =>
    enableFadeoutBehaviour &&
    isLoading &&
    css`
      &:after {
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.9) 45.5%,
          rgba(255, 255, 255, 1) 70%
        );
      }
    `}
`;

export const ModalContent = styled.div`
  text-align: center;
  padding: 5.5rem 2.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);

  z-index: ${zIndex('modal')};
  position: relative;

  @media (max-width: ${breakpoints.md}px) {
    height: 100vh;
    width: 100vw;
    margin-top: 2px;
    border-radius: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1.5rem;
  }
`;

export const UnpublishedBox = styled(Flexbox)`
  text-align: center;
  width: 100%;
  height: 56px;
  align-items: center;
  justify-content: center;
  background-color: ${color('grey')};
  color: white;
  position: sticky;
  top: 64px;
  z-index: 2;

  svg {
    margin-right: 0.5rem;
  }
`;

export const TicketingBox = styled(Flexbox)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 200px;
  height: inherit;
  overflow-y: auto;
  padding-top: 5rem;

  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.75) 15.5%,
    rgba(255, 255, 255, 0.88) 20%,
    rgba(255, 255, 255, 1) 65%
  );

  @media (min-width: ${breakpoints.xs}px) {
    top: 240px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    top: 280px;
  }

  @media (min-width: ${breakpoints.md}px) {
    top: 330px;
  }

  @media (min-width: ${breakpoints.lg}px) {
    top: 320px;
  }

  // for very small height [e.g when screen is rotated]
  @media (max-height: ${breakpoints.xs}px) {
    top: unset !important;
    height: 68.5%;
  }

  // let's have the fadeout at fixed postions when the screen height is getting too long
  @media (min-height: ${breakpoints.lg}px) {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.75) 165px,
      rgba(255, 255, 255, 0.88) 200px,
      rgba(255, 255, 255, 1) 460px
    );
  }

  ${Flexbox} {
    &:first-child {
      max-width: 60%;
      padding: 1rem;
      text-align: center;

      @media (max-width: ${breakpoints.sm}px) {
        max-width: 90%;
      }
    }
  }
`;
