import {
  color,
  borderRadius,
  media,
  zIndex,
  SimpleText,
  UnderlineLink,
  Text,
  Theme,
  BlockText,
  Flexbox,
} from '@morressier/ts';

import {
  FaCalendar,
  FaExpand,
  FaMapMarkerAlt,
  FaUserAlt,
  FaUsers,
  FaVideoSlash,
} from 'react-icons/fa';
import { HiStar } from 'react-icons/hi';
import styled, { css } from 'styled-components';

import { HeroSection } from 'components/AvatarBanner';
import { breakpoints } from 'components/ThemedPage/muiTheme';
import { SimpleCard } from 'containers/SessionsList/styles';
import { PageTitle } from 'containers/styles';

const { sm, md } = breakpoints;

export const ContentWrapper = styled.div`
  background: ${color('sidebarGrey')};
`;

export const Container = styled.div`
  padding: 1.5rem 1rem;

  ${media.large`
    padding: 1.5rem 2.5rem;
  `}
`;

export const HeroContainer = styled.div`
  max-width: 50rem;
  margin-top: 0.5rem;
`;

export const HeroTop = styled.div<{
  hasScrollStarted: boolean;
  hasBanner?: boolean;
  isRow?: boolean;
}>`
  align-items: center;
  display: flex;
  line-height: 2.5rem;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  transition-duration: 200ms;
  transition-property: background-color, box-shadow;
  min-height: 4.5rem;
  width: 100%;
  z-index: 3;
  cursor: pointer;

  ${media.xxsmall`
    flex-direction: ${({ isRow }) => (isRow ? 'row' : 'column')};
    padding: 1rem;
  `}

  ${media.medium`
    flex-direction: row;
    padding: 0 2.5rem;
  `}

  ${props =>
    props.hasBanner &&
    !props.hasScrollStarted &&
    css`
      background-color: rgba(0, 0, 0, 0.4);
    `}

  ${props =>
    props.hasScrollStarted &&
    css`
      background-color: ${color('white')};
      box-shadow: 0 0.25rem 0.6rem rgba(0, 0, 0, 0.2);
    `}
`;

export const HeroInfoLine = styled.div`
  margin-left: 1rem;
  /* margin-bottom: 0.5rem; */
  display: inline-block;
`;

export const VideoLayout = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${media.large`
    flex-direction: row;
  `}
`;

export const TitleRow = styled.div`
  ${media.medium`
  display: flex;
  justify-content: space-between;
  `}
  ${media.large`
  margin-bottom: 1.5rem;
  `}
`;

export const VideoLayoutLeft = styled.div`
  margin-bottom: 1.5rem;

  ${media.large`
  flex: 2 1 32rem;
  margin-right: 1.5rem;
  margin-bottom: 0;
  `}
`;

export const VideoLayoutRight = styled.div`
  position: relative;

  ${media.large`
    flex: 1 1 16rem;
    max-width: 26rem;
  `};
`;

export const VideoAuthor = styled.div`
  display: flex;
  align-items: center;
`;
export const VideoAuthorFrom = styled(SimpleText).attrs(() => ({
  color: 'grey',
  size: 'body2_new',
  as: 'div',
}))`
  font-size: 12px;
  /* add dot when both are non-empty */
  span:not(:empty) + span:not(:empty)::before {
    content: ' · ';
  }
`;

export const PlaylistStyle = styled(SimpleCard)<{
  length: number;
  blocked: boolean;
}>`
  && {
    ${borderRadius('small')};
    width: 100%;
    min-height: inherit;
    position: relative;
    padding: 0;

    ${props =>
      props.blocked &&
      css`
        opacity: 0.9;
        cursor: not-allowed !important;

        * {
          cursor: not-allowed !important;
        }
      `}

    @media (max-width: ${Theme.breakpoints.small}px) {
      height: 100%;
      position: relative;
    }
  }
`;

export const PlaylistHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 1.5rem;
`;

export const PlaylistBody = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100% - 3rem);
  width: 100%;
  padding-bottom: 0.5rem;
`;

export const PlaylistItem = styled.div<{ active: boolean; showVideoPreview?: boolean }>`
  cursor: pointer;
  display: flex;
  padding: 0.625rem 1rem 0.625rem 0.5rem;
  max-height: 5.5rem;
  align-items: center;
  background: ${p => (p.active ? color('sidebarGrey') : 'transparent')};
  transition: background 0.3s ease-in-out;

  ${props =>
    !props.showVideoPreview &&
    css`
      margin-top: 0.5rem;
      margin-bottom: 0.625rem;
    `};
`;

export const PlaylistItemPreview = styled.div`
  height: 75px;
  background-color: ${color('secondaryBrand')};
  color: white;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  align-items: center;
  width: 133px;
  margin-right: 0.5rem;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  border-radius: 4px;

  svg {
    z-index: 1;
  }

  img {
    width: 100%;
    position: absolute;
    object-fit: cover;
    opacity: 0.35;
  }

  video {
    z-index: 5;
    width: 106px;
  }
`;

export const PlaylistItemNumber = styled.div`
  display: flex;
  color: ${color('secondaryBrand')};
  min-width: 1.5rem;
  text-align: center;
  justify-content: center;
`;

export const EmptyVideo = styled.div`
  width: 100%;
  height: 100%;
  background-color: #3a3a3a;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const TopBarLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  width: fill-available;
  overflow: hidden;
`;

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-left: 1rem;
  position: relative;
  overflow: hidden;

  ${media.xxsmall`
    margin-right: 1rem;
  `}

  &::after {
    content: '';
    position: absolute;
    border-left: 1px solid ${color('lightGrey')};
    height: 3rem;
    left: 0;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const FlexRow = styled(FlexContainer)<{
  justifyContent?: string;
  makeColumn?: boolean;
}>`
  flex-direction: row;

  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}

  ${props =>
    props.makeColumn &&
    css`
      flex-direction: column;
    `}
`;

export const FlexColumn = styled(FlexContainer)`
  flex-direction: column;
`;

export const CommentInput = styled.input`
  display: flex;
  border-radius: 0.1875rem;
  padding: 0.5rem;
  border: 1px solid ${color('lightGrey')};
  width: 100%;
  font-size: 16px;
  outline: none;
  margin-bottom: 0.5rem;
  height: auto;
  resize: vertical;

  ::placeholder {
    color: ${color('grey')};
  }
`;

export const PresenterChip = styled.span`
  ${borderRadius('small')};
  background-color: ${color('grey')};
  padding: 1px 4px;
  font-size: 12px;
  color: ${color('white')};
`;

export const PlaylistTitle = styled.div<{ active?: boolean }>`
  h4 {
    position: relative;
    line-height: 1rem;
    max-height: calc(1rem * 2);
    overflow: hidden;
    padding-right: 0.5rem;

    &::before {
      position: absolute;
      content: '...';
      bottom: 0;
      right: 0;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 1rem;
      height: 1rem;
      background: ${color('white')};

      ${props =>
        props.active &&
        css`
          background-color: ${color('sidebarGrey')};
        `}
    }
  }
`;

export const StyledUnderlineLink = styled(UnderlineLink)`
  && {
    text-decoration-line: underline;
    text-decoration-color: ${color('secondaryBrand')}40;
    color: ${color('secondaryBrand')};

    &:hover {
      text-decoration-line: underline;
      text-decoration-color: ${color('secondaryBrand')};
    }
  }
`;

export const VideoWrap = styled.div`
  ${borderRadius('small')}
  /* couldn't find any similar color */
  background: #3a3a3a;
  position: relative;
  display: flex;
  padding: 1rem;
  height: 100%;
`;

export const FullscreenIcon = styled(FaExpand)`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  color: white;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const FullscreenWrap = styled.div`
  position: relative;
  height: 100%;
`;

export const FillAvailable = styled.div<{ showVideoPreview?: boolean }>`
  width: ${({ showVideoPreview }) => (showVideoPreview ? '80%' : 'auto')};
  overflow: hidden;
`;

export const BackDrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(66, 77, 93, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndex('modal')};
  top: 0;
  left: 0;
`;

const bannerHeight = '280px';
export const HeroBanner = styled.div<{ backgroundImage: string }>`
  background-color: ${color('lightGrey')};
  background-size: cover;
  background-position: center;
  height: ${bannerHeight};
  width: 100%;
  position: absolute;

  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${backgroundImage});
    `}
`;

export const HeroMargin = styled.div<{ subtractHeight: number }>`
  height: ${props => `calc(${bannerHeight} - ${props.subtractHeight}px)`};
  background: ${color('sidebarGrey')};
`;

export const FloatVideoContainer = styled.div<{ float?: boolean; top: number }>`
  z-index: 2;
  position: relative;

  &:hover {
    small {
      display: flex;
    }
  }

  small {
    position: absolute;
    right: 0;
    color: ${color('white')};
    z-index: 10;
    background: rgba(0, 0, 0, 0.4);
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: none;

    svg {
      cursor: pointer;
    }

    @media screen and (max-width: ${sm}px) {
      display: flex;
    }
  }

  ${({ float, top }) =>
    float
      ? css`
          position: fixed;
          bottom: 0.5rem;
          right: 0.5rem;
          width: 439px;
          z-index: 2041;

          @media screen and (max-width: ${sm}px) {
            top: ${top + 64}px;
            width: 100%;
            right: 0;
            left: 0;
            bottom: unset;
          }

          @media screen and (min-width: ${sm + 0.1}px) {
            width: 376px;
            bottom: calc(68px + 0.5rem);
          }

          @media screen and (min-width: ${md + 0.1}px) {
            bottom: 1rem;
            right: 1rem;
          }
        `
      : null}
`;

export const SessionLabel = styled(Text).attrs({ backgroundColor: '#DADCE0' })`
  background-color: ${props => props.backgroundColor};
  ${borderRadius('small')};
  padding: 0 0.5rem 0 0.5rem;
  text-align: center;
  display: inline-table;
`;

export const FeaturedLabel = styled(SessionLabel).attrs({
  backgroundColor: '#f7c105',
})``;

export const SponsoredLabel = styled(SessionLabel).attrs({
  backgroundColor: '#DADCE0',
})``;

export const SponsorLink = styled.a.attrs<{ href?: string }>(props => ({
  as: props.href ? 'a' : 'div',
}))`
  ${borderRadius('small')};
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  border: 1px solid ${color('grey')};
  max-width: 100%;

  &:hover {
    ${props =>
      props.href
        ? css`
            border: 1px solid ${color('secondaryBrand')};
          `
        : ''}
  }

  ${media.medium`
    width: 296px;
  `}
`;

export const SessionTitle = styled(Text).attrs({
  size: 'h3_new',
  color: 'secondaryBrand',
  fontWeight: 'bold',
})`
  font-size: 32px;
  line-height: 1.25;
  margin-bottom: 0.5rem;
  white-space: pre-wrap;
`;

export const SessionDetails = styled(Text).attrs({
  size: 'h4_new',
  color: 'secondaryBrand',
  fontWeight: 'bold',
})`
  font-size: 24px;
  line-height: 1.25;
  margin-bottom: 0.5rem;
`;

export const ProductLabel = styled(BlockText).attrs(() => ({
  size: 'body1_new',
  color: 'grey',
}))`
  text-transform: uppercase;
  line-height: 1.5rem;
`;

export const StandardContainer = styled.div`
  padding: 0 1rem;
  margin-left: auto;
  margin-right: auto;
  // 80rem + 2*2rem padding
  max-width: 84rem;

  ${media.small`
    padding: 0 2rem;
  `}
`;

export const BannerContainer = styled(HeroSection)`
  && {
    height: 118px;
    border-radius: 8px;
    background-color: unset;

    @media screen and (max-width: 540px) {
      margin-left: -1rem;
      width: calc(100% + 2rem);
      border-radius: unset;
    }

    @media screen and (min-width: 540px) {
      height: 158px;
    }

    @media screen and (min-width: 720px) {
      height: 192px;
    }

    @media screen and (min-width: 1024px) {
      height: 282px;
    }

    @media screen and (min-width: 1280px) {
      height: 236px;
    }
  }
`;

export const StartTimeBox = styled(Flexbox)<{ hasBackground: boolean }>`
  margin-top: 1rem;
  padding: 0;
  border-radius: 3px;

  ${props =>
    props.hasBackground &&
    css`
      background-color: ${color('sidebarGrey')};
      padding: 1rem;
    `}

  svg {
    margin-right: 0.5rem;
    margin-top: 4px;
  }

  button {
    display: flex;
    align-items: center;

    svg {
      margin-top: unset;
    }
  }
`;

export interface IStackProps {
  position?: string;
  top?: number | string;
  left?: number | string;
  right?: number | string;
  bottom?: number | string;
}

const Stack = styled.div.attrs({})<IStackProps>`
  position: relative;
`;

const StackChild = styled.div.attrs({})<IStackProps>`
  position: absolute;
  content: '';
`;

export const Title = styled(PageTitle)`
  @media (min-width: 1024.1px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;

export const DropDownStack = styled(Stack)`
  width: fit-content;
  cursor: pointer;
`;

export const DropDownContainer = styled(StackChild)`
  position: absolute;
  top: calc(100% + 0.5rem);
  padding: 0.25rem 0;
  z-index: 10;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #d4d5d6;
  box-sizing: border-box;
  box-shadow: 0px 5px 20px rgba(219, 219, 219, 0.25);
  border-radius: 3px;
  max-height: 200px;
  overflow: auto;
  min-width: 100%;
`;

export const DropDownItem = styled(Stack)`
  cursor: pointer;
  padding: 1rem;
  cursor: pointer;
  display: block;
  text-decoration: none;

  &:hover {
    background: ${color('sidebarGrey')};
    border-radius: 4px;
  }
`;

export const EmptyVideoIcon = styled(FaVideoSlash)`
  margin-bottom: 1rem;
`;

export const LiveChip = styled.div`
  background-color: ${color('red')};
  padding: 0.25rem 0.5rem;
  color: ${color('white')};
  margin: 1rem;
  position: absolute;
  z-index: 10;
  border-radius: 3px;
  font-weight: 400;
`;

export const AvatarParticipant = styled.div<{
  index?: number;
  onlyOne?: boolean;
  transparent?: boolean;
}>`
  margin-left: -2px;
  border-radius: 100%;
  border: 2px solid ${color('white')};

  ${({ index }) =>
    index
      ? css`
          margin-left: -6px;
          z-index: ${-1 * index};
        `
      : null}

  ${({ onlyOne }) =>
    onlyOne &&
    `
    margin-right: 6px;
  `}

  ${({ transparent }) =>
    transparent
      ? css`
          div div {
            div:first-child {
              border: 1px solid ${color('lightGrey')};
              background-color: ${color('transparent')};
            }

            div:last-child {
              color: ${color('lightGrey')};
              font-weight: 600px;
            }
          }
        `
      : null}
`;

export const NameParticipant = styled(Text)`
  display: inline-block;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const CalendarFa = styled(FaCalendar)`
  color: ${color('grey')};
`;

export const MapMarkerAltFa = styled(FaMapMarkerAlt)`
  color: ${color('grey')};
`;

export const UserAltFa = styled(FaUserAlt)`
  color: ${color('grey')};
`;
export const UsersFa = styled(FaUsers)`
  color: ${color('grey')};
`;

export const ReservedSpotIcon = styled(HiStar)`
  margin-top: 0;
  padding: 0.05rem;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 100%;
  color: ${color('sidebarGrey')} !important;
  background-color: ${color('secondaryBrand')};
`;

// we need this to to disable text highlighting during a drag and also serve as the parent dragging constrainsts
export const DragOverlay = styled.div<{ isDragging: boolean }>`
  -webkit-touch-callout: none; /* iOS Safari */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  touch-action: none; // disable panning and mouse related actions

  ${props =>
    props.isDragging &&
    css`
      position: fixed;
      top: 64px;
      left: 0;
      right: 0;
      bottom: 68px;
      z-index: ${zIndex('modal')}0;
    `}
`;

// A cover to restrict playing or pausing videos during a potential drag
export const DragCover = styled.div`
  z-index: 10;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 30px; // leave enough space to interact with the player controls
  cursor: move;
`;

export const SignUpBannerWrapper = styled.div`
  width: 100%;
  min-height: 5rem;
  margin: 2rem 0 1rem;
  padding: 1.25rem;
  border: 1px solid #d9dbdf;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 720px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const SignUpBannerText = styled(Text).attrs(() => ({
  color: 'grey',
}))`
  @media (max-width: 720px) {
    text-align: center;
    margin-bottom: 1rem;
  }
`;

export const SchedulerSection = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid ${color('lightGrey')};
  border-bottom: 1px solid ${color('lightGrey')};
  background: ${color('white')};
`;

export const SessionTag = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${color('lightGrey')};
  color: ${color('grey')};
  background-color: ${color('backgroundGrey')};
  padding: 0.5rem;
`;
