import { OrderPayload, BillingAddress } from 'components/BuyTicketModal/hooks';
import { CreateOrderResponse } from 'pages/api/product/order/create';
import httpService from 'services/httpService';

export const createOrder = async (orderPayload: OrderPayload, productId: string) => {
  const { data } = await httpService.post<CreateOrderResponse>({
    url: `/api/v4/pas/orders?productId=${productId}`,
    data: orderPayload,
  });

  return data;
};

export const updateOrder = async (
  orderPayload: OrderPayload,
  orderId: string,
  productId: string,
) => {
  const { data } = await httpService.patch<CreateOrderResponse>({
    url: `/api/v4/pas/orders/${orderId}?productId=${productId}`,
    data: orderPayload,
  });

  return data;
};

export const updateOrderBillingAddress = async (
  orderBillingAddressPayload: BillingAddress,
  orderId: string,
  productId: string,
) => {
  const { data } = await httpService.patch<CreateOrderResponse>({
    url: `/api/v4/pas/orders/${orderId}/billing-address?productId=${productId}`,
    data: orderBillingAddressPayload,
  });

  return data;
};

export const finalizeFreeOrder = async (orderId: string, productId: string) =>
  httpService.patch({ url: `/api/v4/pas/orders/${orderId}/complete?productId=${productId}` });

export const deleteDiscount = (orderId: string, productId: string, couponCode: string) =>
  httpService.delete({
    url: `/api/v4/pas/orders/${orderId}/vouchers/${couponCode}?productId=${productId}`,
  });
