import qs from 'qs';
import httpService from 'services/httpService';
import { getOffset } from 'utils/pagingHelper';
import { defaultPageSize } from 'containers/PeopleList';
import { UserProfileProps } from 'components/User/UserProfile';
import { AuthorSectionProps, SpeakerChairSectionProps } from 'components/User/ContributionSection';

export enum PeopleType {
  authors = 'authors',
  delegates = 'delegates',
  presenters = 'presenters',
  speakers = 'speakers',
  chairs = 'chairs',
  users = 'users',
}

export type EventPerson = Pick<
  MorressierUser,
  | '_id'
  | 'full_name'
  | 'department'
  | 'organization'
  | 'picture_url'
  | 'featured'
  | 'public_profile'
>;

type PeopleResponse = {
  [key in PeopleType]?: EventPerson[];
} & { total: number; totalUsers: number };

const deaultPage = 1;

export const fetchEventPeople = (args: {
  eventId: string;
  peopleType: PeopleType;
  page?: number;
  pageSize?: number;
  q?: string;
}) => {
  const { eventId, page = deaultPage, pageSize = defaultPageSize, peopleType, q } = args;
  const limit = pageSize;
  const offset = getOffset(page, pageSize);

  return httpService
    .get<PeopleResponse>({
      url: `api/v3/discovery/events/${eventId}/${peopleType}${qs.stringify(
        { limit, offset, q },
        {
          addQueryPrefix: true,
        },
      )}`,
    })
    .then(response => ({
      people: response.data[peopleType],
      total: response.data.total,
      totalUsers: response.data.totalUsers,
      filter: peopleType,
    }));
};

type ProfileInfoResponse = UserProfileProps & {
  author: AuthorSectionProps[];
  speaker: SpeakerChairSectionProps[];
  chair: SpeakerChairSectionProps[];
  is_attending_event: boolean;
};

export const fetchProfileInfo = (eventId: string, profileId: string) =>
  httpService
    .get<ProfileInfoResponse>({
      url: `api/v3/standalone/events/${eventId}/people/${profileId}`,
    })
    .then(response => response.data)
    .catch(() => null);
