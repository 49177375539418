export const commaFormat = (num: number) => Number(num).toLocaleString();

export const asSummaryCount = (num = 0) => {
  if (!num) return '-';
  if (num < 1e4) return commaFormat(num);
  if (num >= 1e4 && num < 1e6) return `${+(num / 1e3).toFixed(1)}k`;
  if (num >= 1e6 && num < 1e9) return `${+(num / 1e6).toFixed(1)}m`;
  if (num >= 1e9 && num < 1e12) return `${+(num / 1e9).toFixed(1)}b`;
  if (num >= 1e12) return `${+(num / 1e12).toFixed(1)}T`;

  return 'unknown';
};
