import { SimpleText, Spinner, Text } from '@morressier/ts';
import { FaCheck } from 'react-icons/fa';
import { EmptyLogoWrapper } from 'components/AvatarBanner';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { format } from 'date-fns-tz';
import { saveAs } from 'file-saver';
import { parseISO } from 'date-fns';
import * as Styles from './styles';
import { useValidPDF } from './hooks';

type Response = {
  customer: { email: string };
  date: Date;
  download_url: string;
};

export const ConfirmationModal = () => {
  const { query } = useRouter();
  const { orderId } = query;
  const url = orderId ? `/api/v4/pas/orders/${orderId}/summary` : null;
  const { data } = useSWR<Response>(url);

  const date = data?.date
    ? format(parseISO(new Date(data.date).toISOString()), 'E, MMM d, YYY, HH:mmaaa z')
    : null;

  const { isLoading, pdfUrl } = useValidPDF(data?.download_url || null);

  return (
    <Styles.ConfirmationModal>
      <EmptyLogoWrapper>
        <FaCheck />
      </EmptyLogoWrapper>
      <div>
        <Text size="h3_new" fontWeight="bold">
          Thank you for your order!
        </Text>
      </div>

      <div>
        <Text fontWeight="bold">Confirmation order </Text>
        <Text fontWeight="bold">#{orderId}</Text>
      </div>

      {data && (
        <>
          {date && (
            <div>
              <Text fontWeight="bold">Date</Text>
              <Text fontWeight="bold">{date}</Text>
            </div>
          )}

          <div>
            <Text>Your ticket has been sent to {data.customer.email}</Text>
            <>
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  {pdfUrl && (
                    <SimpleText
                      as="a"
                      style={{ cursor: 'pointer' }}
                      download={`${orderId}.pdf`}
                      href={data.download_url}
                      onClick={e => {
                        e.preventDefault();
                        saveAs(data.download_url, `${orderId}.pdf`);
                      }}
                      color="primaryLink"
                    >
                      Download
                    </SimpleText>
                  )}

                  {/* {isInValid && (
                    <Text>
                      Please check your email for your ticket info or contact our support team
                    </Text>
                  )} */}
                </>
              )}
            </>
          </div>
        </>
      )}
    </Styles.ConfirmationModal>
  );
};
