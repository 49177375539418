import {
  zIndex,
  PlainButton,
  color,
  SimpleText,
  Flexbox,
  InformationBanner,
  Text,
} from '@morressier/ts';

import { FaTimes } from 'react-icons/fa';
import styled, { css } from 'styled-components';

import { EmptyLogoWrapper, HeroSection } from 'components/AvatarBanner';
import { ModalContent } from 'components/EventStandardContainer/styles';
import { breakpoints } from 'components/ThemedPage/muiTheme';
import { SimpleCard } from 'containers/SessionsList/styles';

export const CloseModal = styled(FaTimes).attrs({ size: '1.25rem' })`
  color: ${color('secondaryBrand')};
`;

export const StepsSelectionContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-flow: column;

  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */

  &::-webkit-scrollbar {
    width: 0 !important;
  }

  ${Flexbox} {
    &[data-name='scroll-body'] {
      padding: 1.5rem 2rem;

      @media (max-width: ${breakpoints.sm}px) {
        padding: 1rem;
      }
    }
  }
`;

export const SelectionHeader = styled.div`
  padding: 1.5rem 2rem 0.5rem;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: ${zIndex('modal')};
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.05);

  @media (max-width: ${breakpoints.sm}px) {
    padding-left: 1rem;
  }
`;

export const StepTitle = styled(PlainButton)<{ isActive?: boolean; shouldDisable?: boolean }>`
  padding: 0.25rem 1rem 0 0;
  color: ${({ isActive }) => (isActive ? color('secondaryBrand') : color('grey'))};
  font-weight: 600;

  ${({ shouldDisable }) =>
    shouldDisable &&
    css`
      cursor: not-allowed;
    `}
`;

export const OrderSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${color('backgroundGrey')};
  height: 100%;
  border-left: 0.5px solid ${color('lightGrey')};
  padding: 2rem;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 1rem;
  }
`;

export const PrivatTicketBar = styled(Flexbox)`
  background-color: ${color('grey')};
  min-height: 56px;
  width: 100%;
  color: #fff;
  text-align: center;
`;

export const EmptyTicketState = styled(Flexbox)`
  height: fill-available;
  min-height: 300px;

  div {
    background-color: ${color('sidebarGrey')};
    height: 128px;
    width: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 1rem;
    color: ${color('grey')};
  }
`;

export const StepsBannerContainer = styled(HeroSection)`
  && {
    max-height: 143px;
    height: auto;
  }
`;

export const TicketCard = styled(SimpleCard)`
  padding-left: 1.5rem;
  padding-right: 2.5rem;

  && {
    cursor: unset;
    margin-top: 1rem;
    flex-direction: row;
  }
`;

export const CartItemText = styled(Text).attrs({
  fontWeight: 'semiBold',
})`
  color: ${props => color(props.color || 'secondaryBrand')};
`;

export const IconWrapper = styled.div`
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: ${color('sidebarGrey')};
  overflow: hidden;
  display: flex;

  svg {
    color: ${color('grey')};
    width: 2rem;
    height: 2rem;
    margin: auto;
    z-index: 1;
  }
`;

export const Line = styled.hr`
  width: 100%;
  border: 0.1px solid ${color('lightGrey')};
`;

export const SummarrySection = styled.div`
  flex: 1 1 auto;
  overflow-y: scroll;

  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */

  &::-webkit-scrollbar {
    width: 0 !important;
  }
`;

export const TicketingModal = styled(ModalContent)<{ isPaymentStep?: boolean }>`
  height: 85vh;
  display: flex;
  padding: unset;
  text-align: left;
  position: relative;
  flex-direction: column;
  justify-content: space-between;

  ${({ isPaymentStep }) =>
    isPaymentStep &&
    css`
      [data-name='scroll-body'] {
        @media (min-width: 624px) and (max-width: ${breakpoints.lg}px) {
          margin: auto;
          max-width: 620px;
          width: 100%;
        }
      }
    `}

  ${CloseModal} {
    position: absolute;
    top: 1.25rem;
    right: 1rem;
    cursor: pointer;
    z-index: 10000;
  }

  ${StepsSelectionContainer} {
    height: auto;
  }

  ${OrderSummaryContainer} {
    height: auto;
  }

  @media (max-width: ${breakpoints.md}px) {
    height: 100vh;
    overflow-y: scroll;

    ${CloseModal} {
      position: fixed !important;
    }

    ${StepsSelectionContainer} {
      width: 100%;
      height: auto;
      overflow-y: unset;
    }

    ${OrderSummaryContainer} {
      width: 100%;
      border-top: 0.25px solid ${color('lightGrey')};
      height: auto;
      overflow-y: unset;
      padding-bottom: 140px; // size of data-name='proceed-section'

      ${SummarrySection} {
        height: auto;
        overflow-y: unset;
      }

      ${({ isPaymentStep }) =>
        !isPaymentStep &&
        css`
          ${Flexbox} {
            &[data-name='proceed-section'] {
              position: fixed;
              bottom: 0;
              width: 100%;
              left: 0;
              padding: 1.5rem 2rem;
              background-color: ${color('backgroundGrey')};
              border-top: 1px solid ${color('lightGrey')};

              @media (max-width: ${breakpoints.sm}px) {
                padding: 1.5rem 1rem;
              }
            }
          }
        `}
    }
  }

  @media (min-width: ${breakpoints.md + 0.1}px) {
    width: 95vw;
    flex-direction: row;

    ${StepsSelectionContainer} {
      width: 60%;

      [data-name='scroll-body'] {
        margin: 0 auto;
        width: 100%;

        @media (min-width: ${breakpoints.lg}px) {
          max-width: 650px;
        }
      }
    }

    ${OrderSummaryContainer} {
      width: 40%;
    }
  }

  @media (min-width: ${breakpoints.xl}px) {
    width: 65vw;
  }
`;

export const ConfirmationModal = styled.div`
  padding: 4rem 5rem;
  width: 100%;

  div:not(:first-child) {
    margin-bottom: 2rem;
  }

  && {
    ${EmptyLogoWrapper} {
      background-color: ${color('green')};
      margin-top: -1rem;
      width: 128px;
      height: 128px;
      margin-bottom: 0.5rem;

      svg {
        color: ${color('white')};
        width: 2rem;
        height: 2rem;
        margin: auto;
        z-index: 1;
      }
    }
  }

  ${SimpleText} {
    text-decoration: underline;
  }
`;

export const RegAndTicketingPrompts = styled(TicketingModal)`
  && {
    display: flex;
    text-align: center;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 4rem 2rem 3rem;

    div {
      * {
        margin-bottom: 1rem;
      }
      button {
        margin-top: 0 !important;
        margin-bottom: 3rem;
      }
    }

    @media (min-width: ${breakpoints.md}px) {
      max-width: 685px;
      max-height: 472px;
      height: unset;
      min-height: 336px;
    }

    ${EmptyLogoWrapper} {
      margin-top: -1rem;
      width: 128px;
      height: 128px;
      margin-bottom: 1.5rem;
      background-color: ${color('sidebarGrey')};

      svg {
        width: 3.5rem;
        height: 3.5rem;
        margin: auto;
        z-index: 1;
      }
    }
  }
`;

export const InfoBox = styled(InformationBanner).attrs<{
  styel?: React.CSSProperties;
}>(props => ({
  style: {
    margin: '0.75rem 0',
    border: 'none',
    background: color('sidebarGrey')(props),
    ...props.style,
  },
}))``;

export const TimeupLogoWrapper = styled(EmptyLogoWrapper)`
  margin-top: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color('sidebarGrey')};
  height: 116px;
  width: 116px;

  svg {
    height: 46px;
  }
`;

export const FetchingTicketsWrapper = styled(Flexbox)`
  position: fixed;
  left: 0;
  right: 0;
  top: 15vh;
  height: 80vh;
  background: radial-gradient(circle at 50%, rgba(256, 256, 256, 0.85) 30%, transparent 60%);
  z-index: ${zIndex('modal')};
`;

export const Banner = styled.div<{ src?: string }>`
  background-image: ${props => `url(${props.src})`};
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: cover;
  width: 100%;
  height: 143px;
  min-height: 143px;
`;

export const InputContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  border-radius: 0.1875rem;
  padding: 0.5rem;
  border: 1px solid ${color('lightGrey')};
  width: 100%;
  font-size: 16px;
  outline: none;
  margin-top: 2px;
`;

export const DropDownContainer = styled.div<{ hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${InputContainer} {
    ${({ hasError }) =>
      hasError &&
      css`
        border: 1px solid ${color('red')};
      `}
  }
`;

export const DropdownItem = styled.div<{ selected?: boolean; preSelected?: boolean }>`
  &:hover {
    background-color: ${color('sidebarGrey')};
  }

  ${({ preSelected }) =>
    preSelected &&
    css`
      background-color: ${color('sidebarGrey')};
    `}

  ${({ selected }) =>
    selected &&
    css`
      /* background-color: ${color('primaryBrandLight')}; */
    `}
`;

export const StyledCheckBoxContainer = styled.div<{ isInvalid?: boolean }>`
  ${props =>
    props.isInvalid &&
    css`
      > div {
        margin-bottom: 0;
      }

      padding: 1rem;
      border: 1px solid ${color('red')};
    `}
`;
