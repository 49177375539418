import { useEffect, useState } from 'react';

import getConfig from 'next/config';

import { Button, Text, Toast } from '@morressier/ts';

import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AxiosError } from 'axios';
import { isEqual } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { updateOrderBillingAddress } from 'api/ticketing';

import { CreatedOrder, FE_Discount } from '.';
import { BillingAddressForm, FormValues } from './BillingAddressForm';
import { OrderPayload } from './hooks';
import { useYupValidationResolver, validationSchemaForBillingAddress } from './validationSchema';

// TODO: replace with correct keys
const stripePubKey = getConfig().publicRuntimeConfig?.STRIPE_PUB_KEY;
const stripePromise = loadStripe(stripePubKey);

const CheckoutForm: React.FC<{
  eventId: string;
  orderId: string;
  hasOrderChanged: boolean;
  handlePaymentSuccess: () => void;
}> = ({ eventId, orderId, hasOrderChanged, handlePaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [iframe, setIframe] = useState<HTMLIFrameElement>();

  const resolver = useYupValidationResolver(validationSchemaForBillingAddress());

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver,
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      searchCountry: '',
      billing_address: {
        address_line_one: '',
        country: '',
        zip_code: '',
        city: '',
        is_gdpr_accepted: false,
      },
    },
  });

  const onSubmit = async (_values, event) => {
    // TODO: validate attached voucher?

    event.preventDefault();
    await updateOrderBillingAddress(_values.billing_address, orderId, eventId);

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    if (hasOrderChanged) {
      await elements.fetchUpdates();
    }

    const data = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    setIsLoading(false);

    if (data.error) {
      setErrorMessage(data.error.message || '');
    } else {
      setErrorMessage('');
      handlePaymentSuccess();

      toast(() => (
        <Toast title="Ticket purchase completed, a summary email has been sent to the provided email" />
      ));
    }
  };

  return (
    // TODO: render something else if is free tickets or total is 0
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <PaymentElement
        onReady={e => {
          setIsReady(true);

          // @ts-ignore incorrect typing
          const element = e?._component as HTMLElement;
          const iframeEl = element?.children?.[0] as HTMLIFrameElement;

          if (iframeEl) {
            setIframe(iframeEl);
          }
        }}
        onChange={() => {
          if (errorMessage) {
            setErrorMessage('');
          }
        }}
        onBlur={() => {
          if (iframe) {
            // TODO: do something to iframe styling
          }
        }}
      />

      <FormProvider {...methods}>
        <BillingAddressForm />
      </FormProvider>

      {stripe && elements && isReady && (
        <div style={{ marginTop: errorMessage ? '0.5rem' : 0 }}>
          <Button loading={isLoading} fullWidth color="primary" type="submit">
            Pay now
          </Button>
        </div>
      )}

      <Text color="red">{errorMessage}</Text>
    </form>
  );
};

export const PaymentStep: React.FC<{
  eventId: string;
  clientSecret: string;
  createdOrder?: CreatedOrder;
  currentPayload: OrderPayload;
  handlePaymentSuccess: () => void;
  discount: FE_Discount | null;
  handleUpdate: (goToNext?: boolean) => Promise<void>;
  goToPrevStep: (step?: number | undefined) => void;
  isTotalZero: boolean;
}> = ({
  eventId,
  clientSecret,
  createdOrder,
  handlePaymentSuccess,
  currentPayload,
  discount,
  handleUpdate,
  isTotalZero,
  goToPrevStep,
}) => {
  useEffect(() => {
    if (isTotalZero) {
      goToPrevStep();
    }
  }, [goToPrevStep, isTotalZero]);

  useEffect(() => {
    handleUpdate().catch(error => {
      const message = (error as AxiosError)?.response?.data?.errorData?.error;

      toast(() => (
        <Toast
          title={
            message ||
            'An error occurred while updating your order. Please try again or contact our support team'
          }
        />
      ));
    });
  }, [handleUpdate]);

  const hasOrderChanged = !isEqual(currentPayload, createdOrder?.payload);

  // TODO show something else when total is zero?

  return clientSecret && currentPayload.items.length > 0 ? (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance: {},
      }}
    >
      <CheckoutForm
        eventId={eventId}
        orderId={createdOrder?.orderId as string}
        hasOrderChanged={hasOrderChanged || !!discount}
        handlePaymentSuccess={handlePaymentSuccess}
      />
    </Elements>
  ) : null;
};
