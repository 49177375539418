import { useRef } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { Text, HTMLText, SimpleText, Flexbox, Theme, DarkTooltip } from '@morressier/ts';

import { useTheme, useMediaQuery } from '@material-ui/core';
import { renderToString } from 'react-dom/server';
import Dotdotdot from 'react-dotdotdot';
import Ellipsis from 'react-ellipsis-component';
import { FaLock as EmbargoedIcon, FaPlus, FaCheck } from 'react-icons/fa';
import { ThemeProvider } from 'styled-components';

import HTMLTextWithLink from 'components/Utilities/HTMLTextWithLink';
import { FeatureFlags } from 'config/featureFlags';
import CalendarExportDropdown from 'containers/SingleSession/CalendarExportDropdown';
import { ThemeOverides } from 'containers/styles';
import { useDidElementComeIntoView } from 'hooks/useDidElementComeIntoView';
import { useLocation } from 'hooks/useLocation';
import { useAuthContext } from 'store';
import { useRegAndTicketingContext } from 'store/modules/eventTicketing';
import { arrayCommaJoiner } from 'utils/array';
import { logEvent } from 'utils/eventTracking';
import { getTimeText, getTimezoneName } from 'utils/timeAndDateHelper';

import { useSessionsBookmarks } from './hooks';
import * as Styles from './styles';

type Props = {
  session: MorressierSession;
  sessionType?: string;
  onClick?: (session?: MorressierSession) => void;
  embargoed?: boolean;
  titleClamp?: number;
  descriptionClamp?: number;
  isSponsored?: boolean;
  cardMode?: 'Grid' | 'List';
  sessionToCalendarExport?: MorressierSession;
  withBookmarkData?: ReturnType<typeof useSessionsBookmarks>;
  deleteCallBack?: () => void;
  showFullTime?: boolean;
  passQueryParam?: boolean;
  event: MorressierEvent;
  eventId?: string;
};

export const BookmarkButton: React.FC<{
  isBookmarked: boolean;
  toggleBookmark: ReturnType<typeof useSessionsBookmarks>['toggleBookmark'];
  session: MorressierSession;
  isToggling: boolean;
  showbookmarkButton: boolean;
  fullWidth?: boolean;
  deleteCallBack?: () => void;
}> = ({
  isBookmarked,
  toggleBookmark,
  isToggling,
  session,
  showbookmarkButton,
  fullWidth = false,
  deleteCallBack,
}) => {
  const isSessionBookmarkEnabled = FeatureFlags.Enable_Session_Bookmarking.isEnabled();
  const { processAction } = useRegAndTicketingContext();

  return isSessionBookmarkEnabled && showbookmarkButton ? (
    <ThemeOverides color={{ orcid: '#0AC57E' }}>
      <Styles.CenterButton
        gap="0.5rem"
        color={isBookmarked ? 'orcid' : undefined}
        loading={!!isToggling}
        fullWidth={fullWidth}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          processAction(() =>
            toggleBookmark(isBookmarked ? 'DELETE' : 'SAVE', session._id, session, deleteCallBack),
          );
        }}
      >
        {isBookmarked ? <FaCheck /> : <FaPlus />}
        {isBookmarked ? 'Saved' : 'Save'}
      </Styles.CenterButton>
    </ThemeOverides>
  ) : null;
};

// fixes a bug where rich text could not be truncated
const renderClampedRichText = (text: string, lines?: number) => {
  const htmlString = renderToString(
    <ThemeProvider theme={Theme}>
      <HTMLTextWithLink>{text}</HTMLTextWithLink>
    </ThemeProvider>,
  );

  return (
    <Ellipsis text={htmlString} maxLine={lines} dangerouslyUseInnerHTML reflowOnResize ellipsis />
  );
};

export const SessionsListItem: React.FC<Props> = ({
  session,
  sessionType,
  event,
  titleClamp = 3,
  descriptionClamp = 3,
  onClick,
  embargoed,
  isSponsored = session.is_sponsored,
  cardMode = 'Grid',
  sessionToCalendarExport,
  withBookmarkData,
  deleteCallBack,
  passQueryParam = false,
  showFullTime = true,
  eventId = event?.id,
}) => {
  const theme = useTheme();
  const { query } = useRouter();
  const maxItemsBeforeAnd =
    !useMediaQuery(theme.breakpoints.up('xs')) || cardMode === 'Grid' ? 1 : 3;
  const { contentLibrary, contentLibraryTitle, fromSearch, from, libraryType } = query as {
    eventId: string;
    contentLibrary?: string;
    contentLibraryTitle?: string;
    fromSearch?: string;
    from?: string;
    libraryType?: string;
  };
  const linkParams = {
    eventId,
    ...(fromSearch ? { fromSearch } : {}),
    ...(contentLibrary ? { contentLibrary, contentLibraryTitle } : {}),
    ...(from ? { from } : {}),
    ...(libraryType ? { libraryType } : {}),
  };
  const sessionTypeLabel = ['Physical', 'Onsite'].includes(event?.type)
    ? 'SESSION'
    : session.type ?? 'SESSION';
  const chairNames = (session.chair_name ?? []).filter(name => name.length > 0);
  const chairs = session.chairs?.map(chair => chair.full_name) || [];
  const chairsToRender =
    arrayCommaJoiner(chairNames, maxItemsBeforeAnd) || arrayCommaJoiner(chairs, maxItemsBeforeAnd);

  const roomAndLocation = [session.room, session.location].filter(Boolean).join(' · ');
  const { end_date, start_date } = session;

  const origin = useLocation()?.origin;
  const type = session.type === 'NETWORKING' ? 'networking-session' : 'session';

  // TODO: remove this when session view is fully developed
  const isSessionViewReady = FeatureFlags.Show_Session_View.isEnabled();
  const isNetworkingSessionReady = FeatureFlags.NetworkingSession_View.isEnabled();
  const sessionLink = isSessionViewReady
    ? `/event/${eventId}/${isNetworkingSessionReady ? type : 'session'}s/${session._id}`
    : `${origin}/${type}/${session._id}`;
  const hrefObj = passQueryParam ? { pathname: sessionLink, query: linkParams } : sessionLink;

  const isPastAndListSession = () => {
    const currentTime = Date.now();
    const endTime = new Date(end_date).getTime();

    return currentTime > endTime && cardMode === 'List';
  };

  const isHappeningNow = () => {
    const currentTime = Date.now();
    const startTime = new Date(start_date).getTime();
    const endTime = new Date(end_date).getTime();

    return currentTime >= startTime && currentTime < endTime;
  };

  const sessionTimeUserTimezone = getTimeText({
    start_date,
    end_date,
    mode: cardMode,
    showFullTime,
    showTimezoneAbbr: false,
  });
  const userTimeZoneName = getTimezoneName(start_date);

  const cardRef = useRef<HTMLDivElement>(null);
  const didElementGetIntoView = useDidElementComeIntoView(cardRef.current, { top: 50 });

  const { state } = useAuthContext();
  const userId = state?.profile?.id;

  const isTicketingFlagEnabled = FeatureFlags.Event_Ticketing.isEnabled();

  // previously we only wanted to show buttons when user is logged in. With Reg&Ticketing, we don't care cause the action is blocked at a higher level
  const canShowBookmarkButton = () => {
    if (isTicketingFlagEnabled) return true;

    return withBookmarkData ? !!userId : !!userId && didElementGetIntoView;
  };

  const bookmarkData = useSessionsBookmarks(
    withBookmarkData ? false : canShowBookmarkButton(),
    eventId,
    session._id,
    userId,
  );

  const {
    bookmarks = {},
    toggleBookmark,
    isLoading,
    isToggling,
  } = withBookmarkData || bookmarkData;

  const isBookmarked = bookmarks[session._id]?.isBookmarked;

  return (
    <Link href={hrefObj} passHref>
      <a style={{ textDecoration: 'none' }} target={isSessionViewReady ? '_self' : '_blank'}>
        <Styles.SessionListCard
          ref={cardRef}
          onClick={() => {
            if (onClick) {
              onClick(session);
            } else {
              if (session.type === 'PRESENTATIONS') {
                logEvent('CLICKED_SESSION', {
                  id: session._id,
                  event_id: session.event,
                });
              }
              if (session.type === 'NETWORKING') {
                logEvent('CLICKED_NETWORKING_SESSION', {
                  id: session._id,
                  event_id: session.event,
                });
              }
            }
          }}
          style={{ minHeight: '13rem', height: '100%' }}
        >
          <div>
            <Flexbox justifyContent="space-between" alignItems="flex-start">
              <Styles.DivMargin style={{ display: 'flex', alignItems: 'center' }}>
                <Text color="grey">{sessionTypeLabel}</Text>
                {embargoed && <EmbargoedIcon color="grey" className="ml1" />}
              </Styles.DivMargin>
              {cardMode === 'List' &&
                FeatureFlags.EventSessionSchedulerExportToCalendar.isEnabled() && (
                  <Flexbox gap="1rem" justifyContent="flex-end">
                    <CalendarExportDropdown
                      session={sessionToCalendarExport ?? session}
                      type={session.type}
                      hideLabel
                      eventId={eventId}
                    />
                    <BookmarkButton
                      session={session}
                      toggleBookmark={toggleBookmark}
                      isToggling={isToggling[session._id]}
                      isBookmarked={isBookmarked}
                      showbookmarkButton={
                        canShowBookmarkButton() && (!!Object.keys(bookmarks).length || !isLoading)
                      }
                      deleteCallBack={deleteCallBack}
                    />
                  </Flexbox>
                )}
            </Flexbox>
            <Styles.DivMargin>
              <Text
                fontWeight="bold"
                style={{ fontWeight: 800 }}
                color={isPastAndListSession() ? 'grey' : 'secondaryBrand'}
                as="div"
              >
                <Dotdotdot clamp={titleClamp} tagName="div">
                  <HTMLText>{session.name ?? session.title}</HTMLText>
                </Dotdotdot>
              </Text>
            </Styles.DivMargin>
            {session.description && (
              <Styles.DivMargin>
                {renderClampedRichText(session.description, descriptionClamp)}
              </Styles.DivMargin>
            )}
            {session.start_date && session.end_date && (
              <Styles.DivMargin>
                <Text color="secondaryBrand" fontWeight="semiBold">
                  {['Hybrid', 'Onsite', 'Physical'].includes(event?.type) ? (
                    <DarkTooltip
                      text={getTimeText({
                        start_date,
                        end_date,
                        mode: cardMode,
                        showFullTime: true,
                      })}
                      placement="top"
                    >
                      <Styles.OnsiteSessionDate>
                        {getTimeText({
                          start_date,
                          end_date,
                          mode: cardMode,
                          showFullTime,
                          timezone: event?.timezone,
                        })}
                      </Styles.OnsiteSessionDate>
                    </DarkTooltip>
                  ) : (
                    getTimeText({ start_date, end_date, mode: cardMode, showFullTime })
                  )}
                  {isHappeningNow() && (
                    <SimpleText
                      style={{ marginLeft: '8px' }}
                      as="span"
                      color="red"
                      fontWeight="bold"
                    >
                      Now
                    </SimpleText>
                  )}
                </Text>
              </Styles.DivMargin>
            )}
            {cardMode === 'List' && roomAndLocation && (
              <Styles.DivMargin>
                <Text color="grey">{roomAndLocation}</Text>
              </Styles.DivMargin>
            )}
            {chairsToRender && (
              <Styles.DivMargin>
                <Text color="grey">Chaired by {chairsToRender}</Text>
              </Styles.DivMargin>
            )}
          </div>
          {(!!session.sponsors?.length || isSponsored || session.featured) && (
            <Styles.DivMargin style={{ display: 'flex', marginTop: '0.5rem' }}>
              {session.featured && <Styles.FeaturedLabel>Featured</Styles.FeaturedLabel>}
              {(!!session.sponsors?.length || isSponsored) && (
                <div className={session.featured ? 'ml1' : ''}>
                  <Styles.SponsoredLabel>Sponsored</Styles.SponsoredLabel>
                </div>
              )}
            </Styles.DivMargin>
          )}
          {cardMode === 'Grid' && FeatureFlags.EventSessionSchedulerExportToCalendar.isEnabled() && (
            <Flexbox gap="1rem" justifyContent="flex-end">
              <BookmarkButton
                session={session}
                toggleBookmark={toggleBookmark}
                isToggling={isToggling[session._id]}
                isBookmarked={isBookmarked}
                showbookmarkButton={canShowBookmarkButton() && !isLoading}
                fullWidth
              />

              <CalendarExportDropdown
                session={sessionToCalendarExport ?? session}
                type={session.type}
                hideLabel
                eventId={eventId}
              />
            </Flexbox>
          )}
        </Styles.SessionListCard>
      </a>
    </Link>
  );
};
