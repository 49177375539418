import { FC } from 'react';
import MorressierMarkdown from 'components/MorressierMarkdown';
import OutsideLink from 'components/OutsideLink';

// TODO: Remove this component and update the right places to use the new OutsideLink prepperly
const HTMLTextWithLink: FC<{ children: string }> = props => (
  <MorressierMarkdown source={props.children || ''} customRenderers={{ a: OutsideLink }} />
);

export default HTMLTextWithLink;
