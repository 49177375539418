export const eventMock = (id: string): { event: MorressierEvent; stats: MorressierEventStats } => ({
  event: {
    id,
    _id: id,
    banner_url:
      'https://storage.googleapis.com/morressier_exhibitor_staging/ADDITIONAL_FILES/d1d71743-7253-4bf3-bc99-1f1ae6974e1d.png',
    description: `Giving chemistry professionals a platform to present, publish, discuss and exhibit the most exciting research discoveries and technologies in chemistry and its related disciplines.
      The meeting will facilitate networking opportunities, career development and placement, and provide companies an opportunity to exhibit products and services to a targeted audience.`,
    logo_url:
      'https://storage.googleapis.com/morressier_event_backoffice_logo_staging/d4a476ad-7cc4-46fc-a1b0-f7334bd6de69.png',
    brand_theme: { primary_color: '#59d3ce', secondary_color: '#424d5d' },
    name: 'Eventify',
    organization_id: {
      id: '5eba80059652df329a983aeb',
      _id: '5eba80059652df329a983aeb',
      logo_url:
        'https://storage.googleapis.com/morressier_exhibitor_staging/ADDITIONAL_FILES/d1d71743-7253-4bf3-bc99-1f1ae6974e1d.png',
      name: 'International Chemistry & Technology Society',
    },
    embargoed: false,
    short_name: 'EVENTIFY',
    start_date: '2021-02-01T09:56:28.000Z',
    end_date: '2021-02-28T09:56:00.000Z',
    _created_at: '2021-02-01T09:56:28.073Z',
    _modified_at: '2021-05-31T14:55:12.186Z',
    city: 'New York',
    country: 'United States',
    location: '',
    timezone: 'Europe/Berlin',
    type: 'Online',
    venue: '',
    publish_date: '2021-02-01T09:59:00.000Z',
    publish_date_str: '2021-02-01 10:59:00',
    website_url: 'https://morressier.com',
  },
  stats: {
    sessions: 2256,
    submissions: 188200,
    people: 280420,
    exhibitions: 4125,
  },
});
