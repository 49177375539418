import { TIMEOUT_MAX } from 'containers/SingleSession/hooks';
import { useEffect } from 'react';

/**
 *  Calls the callback on the provided future date
 */
export const useRunAt = ({
  date,
  callback,
  options,
}: {
  date?: string | number | Date;
  callback: () => void;
  options?: { runPastDates: boolean };
}) => {
  useEffect(() => {
    if (!date) return undefined;

    const milisecondsUntilDate = new Date(date).getTime() - Date.now();

    if (milisecondsUntilDate < 0) {
      if (options?.runPastDates) {
        return callback();
      }

      return undefined;
    }

    let handleTimeout: NodeJS.Timeout;
    if (milisecondsUntilDate < TIMEOUT_MAX) {
      handleTimeout = setTimeout(() => {
        callback();
      }, milisecondsUntilDate);
    }

    return () => {
      clearTimeout(handleTimeout);
    };
  }, [date, callback, options]);
};
