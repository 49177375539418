/**
 * Helps to calculate the offest given a page and page size
 * @param page - the current page
 * @param pageSize - the page size or limit
 * @returns - the calculated offset
 */
export const getOffset = (page: number, pageSize: number) => {
  if (page <= 1 || Number.isNaN(page)) return 0;
  return (page - 1) * pageSize;
};

export const getPage = (offset: number, pageSize: number) => offset / pageSize + 1;
