import {
  OverlayPortal,
  Modal,
  ModalFooter,
  ModalBody,
  Text,
  StyledInput,
  Button,
  Flexbox,
  Toast,
  borderRadius,
  color,
  font,
  InputIcon,
} from '@morressier/ts';
import { DatePicker } from '@material-ui/pickers';
import { FaCalendarAlt } from 'react-icons/fa';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { logEvent } from 'utils/eventTracking';
import { format, utcToZonedTime } from 'date-fns-tz';
import { toast } from 'react-toastify';
import { scheduleMeeting } from 'api/personalAgenda';
import { useAuthContext } from 'store';
import { useRouter } from 'next/router';
import { ScheduledMeeting } from 'api/events';
import { DateWrapper, Input } from './style';

interface Props {
  toggleScheduleModal: () => void;
  fullName?: string;
  userId: string;
  eventId: string;
}

const StyledDatePicker = styled(DatePicker)`
  .MuiFormLabel-root {
    padding-left: 0.5rem;
    line-height: 1.5rem;
  }
  .MuiInput-input {
    ${borderRadius('small')};
    ${font()};
    height: initial;
    color: ${color('secondaryBrand')};
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.625rem;
    border: 1px solid ${color('lightGrey')};
    cursor: pointer;

    &::placeholder {
      color: ${color('grey')};
      ${font()};
    }
    &:disabled {
      color: ${color('grey')};
      border: 1px solid ${color('disabledInputBorder')};
      background-color: ${color('disabledInputBackground')};
    }
    &:focus {
      outline: 0;
      border-color: ${color('secondaryBrand')};
      box-shadow: 0 0 0 0.1rem rgba(66, 77, 93, 0.25);
    }
  }
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }
`;

const generateTimeInUTC = (date: string, time: string) => {
  const [startHour, startMinutes] = time.split(':');
  const updatedHours = new Date(date).setHours(parseInt(startHour, 10), parseInt(startMinutes, 10));
  const dateInUTC = new Date(new Date(updatedHours).toUTCString());
  const [hours, minutes] = [dateInUTC.getUTCHours(), dateInUTC.getUTCMinutes()];
  const newTime = `${hours}:${minutes}`;

  return { newTime, newDate: dateInUTC.toISOString() };
};

const MAX_DESCRIPTION_LENGTH = 240;

const getTimezone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const zonedDate = utcToZonedTime(new Date(), timeZone);
  const pattern = "'GMT' XXX";

  return `${timeZone} (${format(zonedDate, pattern, { timeZone })})`;
};

const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const ScheduleMeetingModal: React.FC<Props> = ({
  toggleScheduleModal,
  fullName,
  userId,
  eventId,
}) => {
  const [selectedDate, handleDateChange] = useState<string>(new Date().toString());
  const [selectedTime, handleTimeChange] = useState<string>(getCurrentTime());
  const [description, setDescription] = useState('');
  const hasDescriptionError = description.length > MAX_DESCRIPTION_LENGTH;
  const { state } = useAuthContext();
  const { query } = useRouter();

  const onDescriptionChange = e => {
    setDescription(e.currentTarget.value);
  };

  useEffect(() => {
    logEvent('SCHEDULE_MEETING_FROM_PROFILE_PAGE', {
      event_id: eventId,
      target_user: userId,
    });
  }, []);

  const onSubmit = () => {
    if (hasDescriptionError) {
      return;
    }

    const { newDate, newTime } = generateTimeInUTC(selectedDate, selectedTime);

    toggleScheduleModal();
    scheduleMeeting({
      inviter: state?.profile?.id as string,
      invitee: userId,
      inviter_action: 'SENT',
      inviteeAction: 'PENDING',
      date: newDate,
      time: newTime,
      description,
      session: { event_id: query.eventId as string },
    }).then((res: ScheduledMeeting) => {
      toast(() => (
        <Toast
          title={`Scheduled meeting ${
            fullName ? `with ${fullName} ` : ''
          }has been added to your Agenda`}
        />
      ));

      logEvent('SCHEDULE_MEETING_SEND_MEETING_REQUEST', {
        event_id: eventId,
        meeting_id: res._id,
        target_user: userId,
      });
    });
  };

  return (
    <OverlayPortal>
      <Modal
        style={{ width: '45rem' }}
        close={toggleScheduleModal}
        copies={{ title: 'Schedule a Meeting' }}
      >
        <ModalBody style={{ paddingTop: '3rem' }}>
          <Text style={{ marginBottom: '1.75rem' }}>Your timezone: {getTimezone()}</Text>
          <Text fontWeight="semiBold">Select date</Text>
          <Flexbox gap="1.5rem" className="mb2">
            <div style={{ position: 'relative' }}>
              <DateWrapper key="date">
                <InputIcon as={FaCalendarAlt} />
                <StyledDatePicker
                  name="date"
                  format="MMM d, yyyy"
                  value={selectedDate}
                  minDate={new Date()}
                  DialogProps={{ style: { zIndex: 2001 } }}
                  onChange={date => {
                    if (date) {
                      handleDateChange(date.toString());
                    }
                  }}
                />
              </DateWrapper>
            </div>
            <DateWrapper>
              <StyledInput
                name="time"
                type="time"
                step={60}
                value={selectedTime}
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleTimeChange(e.target.value);
                }}
                style={{ width: '8.5rem' }}
              />
            </DateWrapper>
          </Flexbox>

          <Flexbox flexDirection="column">
            <Text fontWeight="semiBold">Description</Text>
            <Input
              as="textarea"
              onChange={onDescriptionChange}
              name="description"
              placeholder="Add a description for the Meeting."
              hasError={hasDescriptionError}
            />

            <Text
              color={hasDescriptionError ? 'red' : 'grey'}
              style={{
                fontSize: '14px',
              }}
            >
              You have {MAX_DESCRIPTION_LENGTH - description.length} characters remaining.
            </Text>
          </Flexbox>
        </ModalBody>

        <ModalFooter>
          <Flexbox gap="1rem" justifyContent="flex-end" style={{ width: '100%' }}>
            <Button onClick={toggleScheduleModal}>Cancel</Button>
            <Button color="primary" onClick={onSubmit}>
              Schedule
            </Button>
          </Flexbox>
        </ModalFooter>
      </Modal>
    </OverlayPortal>
  );
};
