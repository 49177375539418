import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { color } from '@morressier/ts';
import NextImage from 'components/NextImage';
import * as ContainerStyles from '../../containers/styles';

export const HeroSection = styled.div<{ noBanner?: boolean }>`
  width: 100%;
  max-height: 320px;

  ${({ noBanner }) =>
    noBanner &&
    css`
      height: 320px;
    `}

  object-fit: cover;
  background-color: rgb(217, 219, 223);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;

  @media screen and (max-width: 1024px) {
    height: 160px;
  }
`;

const BannerBackground = styled.img`
  position: absolute;
  width: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  margin: auto;
  justify-self: center;
  z-index: -1;
  filter: blur(4rem);
  -webkit-filter: blur(4rem);
  color: rgba(0, 0, 0, 0);
`;

const Banner = styled.img<{ centralize?: boolean; inheritFullHeight: boolean }>`
  background-repeat: no-repeat;
  color: rgba(0, 0, 0, 0);
  width: 100%;

  // make the banner full height, to inherit the parent height
  ${props =>
    props.inheritFullHeight &&
    css`
      height: inherit;
      object-fit: cover;
    `}

  ${props =>
    props.centralize &&
    css`
      height: 100%;
      width: unset;
      margin: auto;
      object-fit: cover;
      justify-self: center;
    `}
`;

export const LogoImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  object-fit: cover;
  align-self: center;
  border-radius: 50%;
  margin-top: -50px;
  border: none;
  z-index: 1;
  position: relative;
  border: 4px solid white;
  overflow: hidden;
`;

export const EmptyLogo = styled(FaRegCalendarAlt)`
  color: ${color('grey')};
  width: 2rem;
  height: 2rem;
  background-color: ${color('lightGrey')};
  margin: auto;
  z-index: 1;
`;

export const EmptyLogoWrapper = styled.div`
  color: ${color('grey')};
  border: 4px solid white;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: -50px;
  border: 4px solid white;
  background-color: ${color('lightGrey')};
  overflow: hidden;
  display: flex;
`;

const AvatarBanner: FC<{
  avatarUrl: string;
  bannerUrl: string;
  bannerName?: string;
  blurryBackground?: boolean;
  CustomEmptyLogo?: React.FC;
  inheritFullHeight?: boolean;
  wider?: boolean;
  noBanner?: boolean;
  eventName?: string;
}> = ({
  blurryBackground,
  bannerUrl,
  bannerName,
  avatarUrl,
  children,
  CustomEmptyLogo,
  inheritFullHeight,
  wider,
  noBanner,
  eventName,
}) => {
  const logo = avatarUrl ? (
    <LogoImageWrapper>
      <NextImage
        src={avatarUrl}
        width={100}
        height={100}
        objectFit="cover"
        alt={`The logo of ${eventName}`}
      />
    </LogoImageWrapper>
  ) : (
    <EmptyLogoWrapper>{CustomEmptyLogo ? <CustomEmptyLogo /> : <EmptyLogo />}</EmptyLogoWrapper>
  );

  return (
    <>
      <HeroSection noBanner={noBanner}>
        {blurryBackground && <BannerBackground src={bannerUrl} alt={`${bannerName} banner`} />}
        {bannerUrl && (
          <Banner
            src={bannerUrl}
            alt={`The banner to welcome you to ${eventName}`}
            inheritFullHeight={!!inheritFullHeight}
          />
        )}
        {children}
      </HeroSection>

      {wider ? (
        <ContainerStyles.LongPageContainer style={{ width: '100%' }}>
          {logo}
        </ContainerStyles.LongPageContainer>
      ) : (
        <ContainerStyles.PageContainer style={{ width: '100%' }}>
          {logo}
        </ContainerStyles.PageContainer>
      )}
    </>
  );
};

export default AvatarBanner;
