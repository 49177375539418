import { useRouter } from 'next/router';

import { color, Button, UserCard, Flexbox } from '@morressier/ts';

import { Grid, GridSize, useTheme, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';

import { useParticipantProfile } from 'components/User/hooks';
import { PaginationControls } from 'containers/SingleSession/PaginationControls';
import { useAuthedUser } from 'hooks/useIsAuthedUser';
import { logEvent } from 'utils/eventTracking';
import { commaFormat } from 'utils/numberFormatter';

import * as Styles from './styles';

const UserCardStyle = styled.div`
  background-color: ${color('white')};
`;

export type GridProps = {
  xs?: boolean | GridSize;
  sm?: boolean | GridSize;
  md?: boolean | GridSize;
  lg?: boolean | GridSize;
};

const Speakers: React.FC<{
  data: { count: number; speakers: EventSpeaker[] };
  title?: string;
  hideTotalSpeakersMessage?: boolean;
  hideShowAll?: boolean;
  event: MorressierEvent;
  customGridProps?: GridProps;
  alwaysHorizontal?: boolean;
  onNewPage?: (newPage: number) => void;
  currentPage?: number;
  showPagination?: boolean;
  speakersPerPage?: number;
}> = ({
  data: { speakers, count: total },
  title = 'Speakers',
  hideTotalSpeakersMessage,
  hideShowAll,
  event,
  customGridProps,
  alwaysHorizontal,
  currentPage = 1,
  onNewPage = () => {
    /* placeholder */
  },
  showPagination = false,
  speakersPerPage = 10,
}) => {
  const eventId = event.id;
  const isAuthed = useAuthedUser();
  const getGridProps = (): GridProps => {
    if (speakers && speakers?.length <= 2) return { xs: 12, sm: 12 };
    if (customGridProps) return customGridProps;

    return { xs: 12, sm: 6, lg: 4 };
  };

  const { renderProfileInfoModal, setCurrentParticipant } = useParticipantProfile(event, speakers);

  const { push } = useRouter();

  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down('md'));
  const totalPages = total ? Math.ceil(total / speakersPerPage) : 1;

  const totalSpeakersMessage = total
    ? `${speakers.length} of ${commaFormat(total)} ${total === 1 ? 'Speaker' : 'Speakers'}`
    : `${commaFormat(speakers.length)} ${speakers.length === 1 ? 'Speaker' : 'Speakers'}`;

  return speakers.length ? (
    <>
      <Flexbox
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: '0.5rem' }}
      >
        <div>
          <Styles.SubTitle>{title}</Styles.SubTitle>
          {!hideTotalSpeakersMessage && (
            <Styles.SubtitleCountCaption>{totalSpeakersMessage}</Styles.SubtitleCountCaption>
          )}
        </div>

        {showPagination ? (
          <div style={{ marginBottom: '0.5rem' }}>
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              onNextPage={onNewPage}
              onPreviousPage={onNewPage}
            />
          </div>
        ) : null}
      </Flexbox>

      <Grid container spacing={isBelowLg ? 2 : 4} style={{ marginBottom: '2rem' }}>
        {speakers?.map(speaker => (
          <Grid item {...getGridProps()} key={`popular-${speaker._id}`}>
            <UserCardStyle>
              <UserCard
                key={speaker._id}
                user={speaker}
                onClick={() => {
                  logEvent('STANDALONE_CLICKED_USER_CARD', {
                    user_id: speaker._id,
                  });
                  setCurrentParticipant(speaker._id);
                }}
                alwaysHorizontal={alwaysHorizontal}
                isPrivate={
                  !isAuthed && speaker.public_profile !== undefined && !speaker.public_profile
                }
              />
            </UserCardStyle>
          </Grid>
        ))}
      </Grid>

      {!hideShowAll && (
        <Button
          color="primary"
          onClick={() => {
            logEvent('STANDALONE_CLICKED_EXPLORE_SPEAKERS');
            push(`/event/${eventId}/people?filter=presenters`);
          }}
        >
          Explore Speakers
        </Button>
      )}
      {renderProfileInfoModal()}
    </>
  ) : null;
};

export default Speakers;
