import {
  borderRadius,
  Button,
  color,
  Flexbox,
  media,
  RadioButton,
  SearchBar,
  Text,
} from '@morressier/ts';

import styled, { css } from 'styled-components';

export const DayFiltersWrapper = styled.div`
  display: flex;
  overflow-x: auto;
`;

export const StyledSearchBar = styled(SearchBar)`
  ${borderRadius('small')}
  min-width: 10rem;
  margin-right: 1rem;
  max-width: 100%;
  width: 100%;
  display: inline-block;
  flex-grow: 1;

  svg {
    position: absolute;
    margin-right: 0;
    cursor: pointer;
  }

  & input {
    height: 40px;
  }

  ${media.large`
    max-width: 25.25rem;
  `};
`;

export const EmptyMessage = styled(Text)`
  i {
    font-style: italic;
  }
`;

export const SessionLabel = styled.div<{ status: 'PAST' | 'LIVE' | 'FUTURE' }>`
  margin-left: 0.5rem;
  padding: 0.25rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  ${borderRadius('small')};

  ${props =>
    props.status === 'PAST' &&
    css`
      color: ${color('red')};
      border: 1px solid ${color('red')};
    `}

  ${props =>
    props.status === 'LIVE' &&
    css`
      color: ${color('white')};
      background-color: ${color('red')};
    `}

  ${props =>
    props.status === 'FUTURE' &&
    css`
      border: 1px solid ${color('lightGrey')};
      color: ${color('secondaryBrand')};
    `}
`;

export const Label = styled(Text).attrs({ backgroundColor: '#DADCE0' })`
  background-color: ${props => props.backgroundColor};
  ${borderRadius('small')};
  padding: 0 0.5rem 0 0.5rem;
  text-align: center;
  display: inline-table;
`;

export const Description = styled.div`
  a {
    color: unset;
    text-decoration: none;
  }
`;

export const SponsoredLabel = styled(Label).attrs({
  backgroundColor: '#DADCE0',
})``;

export const FeaturedLabel = styled(Label).attrs({
  backgroundColor: '#f7c105',
})``;

export const SimpleCard = styled(Flexbox)<{
  color?: string;
  height?: string;
  cursor?: string;
  enableHoverState?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justifyContent || 'space-between'};
  box-sizing: border-box;
  cursor: ${props => props.cursor || 'pointer'};
  border-radius: 3px;
  border: 1px solid ${color('lightGrey')};
  position: relative;
  width: 100%;
  padding: 1rem;

  ${props =>
    props.color &&
    css`
      background-color: ${props.color};
    `}

  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  
  ${props =>
    props.enableHoverState &&
    css`
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

      &:hover {
        border: 1px solid #8e949e;
        box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.08), 0 10px 10px rgba(0, 0, 0, 0.1);
      }
    `}
`;

export const BlackVideoCard = styled(SimpleCard).attrs(props => ({
  color: '#1F222C',
  style: {
    height: '100%',
    borderBottom: 'unset',
    zIndex: 1,
    cursor: 'unset',
    textAlign: 'center',
    justifyContent: 'center',
  },
  ...props,
}))``;

export const SessionListCard = styled(SimpleCard)`
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    border: 1px solid ${color('grey')};
    box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.08), 0 10px 10px rgba(0, 0, 0, 0.1);
  }
`;

export const DivMargin = styled.div`
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const RadioBox = styled.span<{ isChecked?: boolean }>`
  border: 1px solid ${color('secondaryBrand')};
  border-radius: 50%;
  height: 18px;
  width: 18px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  ${props =>
    props.isChecked &&
    css`
      &:after {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        background-color: ${color('secondaryBrand')};
        border-radius: 50%;
      }
    `}
`;

export const SidebarCard = styled(SimpleCard)<{ isBelowLg?: boolean }>`
  justify-content: unset;
  overflow-y: auto;
  cursor: unset;

  ${props =>
    props.isBelowLg &&
    css`
      border: none;
      padding: 2rem 1rem;
      height: calc(100vh - 2 * 73px);
    `}
`;

export const DrawerHeader = styled(Flexbox)<{ position?: 'top' | 'bottom' }>`
  height: 73px;
  padding: 1rem;

  ${props =>
    props.position === 'bottom'
      ? css`
          border-top: 1px solid ${color('lightGrey')};
        `
      : css`
          border-bottom: 1px solid ${color('lightGrey')};
        `}
`;

export const CenterButton = styled(Button)<{ gap: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.gap &&
    css`
      & > * {
        margin-right: ${props.gap};
      }
    `}

  &:hover {
    border: 1px solid ${color('secondaryBrand')} !important;
  }
`;

export const CustomRadioButton = styled(RadioButton)`
  && {
    padding: 0.5rem 1rem;
  }
`;

export const OnsiteSessionDate = styled.div`
  text-decoration: underline;
  font-weight: 600;
  color: ${color('grey')};

  &:hover {
    color: ${color('secondaryBrand')};
  }
`;
