import { format } from 'date-fns';

interface ILocationDate {
  startDate: string;
  endDate: string;
  className?: string;
  style?: React.CSSProperties;
  location?: string;
  longFormat?: boolean;
}

const getDateRange = (params: { startDate: Date; endDate: Date; longFormat?: boolean }): string => {
  let startDateMonth: string;
  let endDateMonth: string;

  const { startDate, endDate, longFormat } = params;

  const startDay = startDate.getUTCDate();
  const endDay = endDate.getUTCDate();

  if (longFormat) {
    startDateMonth = startDate.toLocaleString('default', { month: 'long', timeZone: 'utc' });
    endDateMonth = endDate.toLocaleString('default', { month: 'long', timeZone: 'utc' });
  } else {
    startDateMonth = startDate.toLocaleString('default', { month: 'short', timeZone: 'utc' });
    endDateMonth = endDate.toLocaleString('default', { month: 'short', timeZone: 'utc' });
  }

  const startDateYear = startDate.getUTCFullYear();
  const endDateYear = endDate.getUTCFullYear();

  if (startDateYear === endDateYear) {
    if (startDateMonth === endDateMonth) {
      if (startDay === endDay) {
        return `${endDateMonth} ${startDay}, ${endDateYear}`;
      }

      return `${endDateMonth} ${startDay}-${endDay}, ${endDateYear}`;
    }

    return `${startDateMonth} ${startDay}-${endDateMonth} ${endDay}, ${endDateYear}`;
  }
  return `${startDateMonth} ${startDay}, ${startDateYear}-${endDateMonth} ${endDay}, ${endDateYear}`;
};

const LocationSpan = ({ location, className, style }) => (
  <>
    <span className={className} style={style}>
      {location}
    </span>
    <span> &middot; </span>
  </>
);

export const LocationDateFormat: React.FC<ILocationDate> = ({
  startDate: startDateString,
  endDate: endDateString,
  className,
  style,
  location,
  longFormat,
}) => {
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  function getTimeTag({ singleDate }: { singleDate?: boolean } = {}) {
    const range = getDateRange({ startDate, endDate, longFormat });
    const rangeSplit = range.split('-');
    const start = rangeSplit[0];
    const end = rangeSplit[1];
    if (singleDate) {
      return (
        <>
          {location && <LocationSpan className={className} style={style} location={location} />}
          <time className={className} dateTime={startDateString}>
            {range}
          </time>
        </>
      );
    }
    return (
      <>
        {location && <LocationSpan className={className} style={style} location={location} />}
        <time className={className} style={style} dateTime={startDateString}>
          {start}
        </time>
        <span className={className} style={style}>
          -
        </span>
        <time className={className} style={style} dateTime={endDateString}>
          {end}
        </time>
      </>
    );
  }

  if (startDate.getFullYear() === endDate.getFullYear()) {
    if (startDate.getMonth() === endDate.getMonth()) {
      if (startDate.getDay() === endDate.getDay()) {
        return getTimeTag({ singleDate: true });
      }
      return getTimeTag();
    }
    return getTimeTag();
  }
  return getTimeTag();
};
