import addDays from 'date-fns/addDays';
import qs from 'qs';

import { MyAgendaFilters } from 'containers/MyAgenda';
import httpService from 'services/httpService';
import { logger } from 'utils/logger';

import { getQuery, parseQuery, QueryParamType } from './utils';

export type DayOfSessions = {
  _id: string;
  sessions: MorressierSession[];
};

export const fetchEventSessionsV2 = ({
  eventId,
  limit,
  page,
  timezone,
  filterDay,
  search,
}: {
  eventId: string;
  page: number;
  limit: number;
  timezone?: string;
  filterDay?: string;
  search?: string;
}) => {
  let filterDate: { startsAfter: string; startsBefore: string } | null = null;

  if (filterDay) {
    const date = new Date(`${filterDay}T00:00:00`);
    filterDate = {
      startsAfter: date.toISOString(),
      startsBefore: addDays(date, 1).toISOString(),
    };
  }

  return httpService
    .get<{
      sessionDates: { _id: string }[];
      sessions: DayOfSessions[];
      total: number;
      timezone: string;
    }>({
      url: `api/v3/discovery/events/${eventId}/sessions`,
      config: {
        params: {
          limit,
          offset: (Math.max(page, 1) - 1) * limit,
          version: 2,
          timezone,
          groupBy: 'day',
          ...(filterDate ?? {}),
          search,
        },
      },
    })
    .then(response => ({
      ...response.data,
      sessionDates: response.data.sessionDates.map(dateObj => dateObj._id),
      filterDay: filterDay || '',
    }));
};

/**
 * This endpoint should take the eventId into consideration
 * sessionId must belong to the event in question
 * @deprecated
 */
export const fetchSession = (sessionId: string) =>
  httpService
    .get<{
      session: MorressierSession;
    }>({ url: `api/v3/discovery/sessions/${sessionId}` })
    .then(response => response.data.session);

export const fetchSessionInfo = (sessionId: string) =>
  httpService
    .get<{
      session: { event: string };
    }>({ url: `api/v3/discovery/sessions/${sessionId}/info` })
    .then(response => response.data.session);

export const fetchEventSession = (eventId: string, sessionId: string, params?: QueryParamType) =>
  httpService
    .get<{
      session: MorressierSession;
    }>({
      url: `api/v3/standalone/events/${eventId}/sessions/${sessionId}${
        params ? getQuery(params) : ''
      }`,
    })
    .then(response => response.data.session);

export const fetchSessionSpeakers = (
  eventId: string,
  sessionId: string,
  options?: {
    paginate: boolean;
    page: number;
    itemsPerPage: number;
  },
) => {
  const page = options?.page ? options?.page : 1;

  return httpService
    .get<{ presenters: EventSpeaker[] }>({
      url: `api/v3/standalone/events/${eventId}/sessions/${sessionId}/speakers${
        options?.paginate ? `?page=${page}&page_size=${options.itemsPerPage}` : ''
      }`,
    })
    .then(response => response.data.presenters);
};

export const fetchSessionSpeakersCount = (eventId: string, sessionId: string) =>
  httpService
    .get<{ count: number }>({
      url: `api/v3/standalone/events/${eventId}/sessions/${sessionId}/speakers/count`,
    })
    .then(response => response.data);

export const fetchSessionPresentations = (
  eventId: string,
  sessionId: string,
  options?: {
    paginate: boolean;
    page: number;
    itemsPerPage: number;
  },
) => {
  const offset = options?.paginate ? (options.page - 1) * options.itemsPerPage : 0;

  return httpService
    .get<MorressierPresentation[]>({
      url: `api/v4/standalone/events/${eventId}/sessions/${sessionId}/presentations${
        options?.paginate ? `?offset=${offset}&limit=${options.itemsPerPage}` : ''
      }`,
    })
    .then(response => response.data);
};

export const fetchPresentationsCount = (eventId: string, sessionId: string) =>
  httpService
    .get<{ count: number }>({
      url: `api/v4/standalone/events/${eventId}/sessions/${sessionId}/presentations/count`,
    })
    .then(response => response.data);

export const fetchUpcomingSessions = (eventId: string) =>
  httpService
    .get<{
      count: number;
      sessions: MorressierSession;
    }>({ url: `/api/v3/standalone/events/${eventId}/upcoming-sessions` })
    .then(response => response.data);

export const fetchNetworkingSessionInfo = (sessionId: string) =>
  httpService
    .get<{ networkingSession: { event: string } }>({
      url: `api/v3/discovery/networking-sessions/${sessionId}/info`,
    })
    .then(response => response.data);

export const fetchNetworkingSession = (eventId: string, sessionId: string) =>
  httpService
    .get<MorressierNetworkingSession>({
      url: `api/v3/standalone/events/${eventId}/networking-sessions/${sessionId}`,
    })
    .then(response => response.data);

export enum TimeFilterStrings {
  Upcoming = 'upcoming',
  Past = 'past',
  ShowAll = 'all',
  HappeningNow = 'happening',
}

export enum SessionTypeFilters {
  ShowAll = 'all',
  Presentations = 'presentations',
  Networking = 'networking',
}

export type TimeFilterTypes = TimeFilterStrings | Date;

export type SessionQueryParamsV3<T extends SessionTypeFilters | MyAgendaFilters> = {
  eventId?: string;
  page?: number;
  page_size?: number;
  q?: string;
  time?: TimeFilterTypes;
  type?: T;
  tags?: string;
  featured?: 'all' | 'true';
  search?: never;
  with_agenda?: true | null;
};

export const fetchEventSessionsV3 = (
  {
    eventId,
    time,
    type,
    featured,
    q,
    tags,
    page = 1,
    page_size = 10,
  }: Omit<SessionQueryParamsV3<SessionTypeFilters>, 'search' | 'with_agenda'> & {
    eventId: string;
  },
  user_id?: string,
) =>
  httpService
    .get<{
      sessions: {
        [date: string]: MorressierSession[];
      };
      total: number;
      filter_total: number;
      total_pages: number;
      page: number;
    }>({
      url: `api/v3/standalone/events/${eventId}/sessions?${qs.stringify(
        parseQuery({
          q,
          time,
          featured,
          type,
          tags,
          page,
          page_size,
          user_id,
          ...(user_id && { bookmarked: true }),
        }),
      )}`,
    })
    .then(r => r.data);

export const fetchEventSessionsDates = (eventId: string) =>
  httpService
    .get<string[]>({
      url: `api/v3/standalone/events/${eventId}/sessions/dates`,
    })
    .then(r => r.data);

export const fetchVideoSession = (id: string, videoSessionType: string) =>
  httpService
    .get<MorressierVideoSession>({
      url: `api/v3/discovery/video-sessions/${id}?videoSessionType=${videoSessionType}`,
    })
    .then(response => response.data)
    .catch(() => null);

export const fetchArchivedVideo = (id: string) =>
  httpService
    .get<string>({
      url: `api/v3/live-backoffice/video-cutter/signed-url?session=${id}`,
    })
    .then(response => response.data)
    .catch(e => {
      logger.log(e);
    });

export const updateToJoinStageRequest = (
  eventId: string,
  sessionId: string,
  delegateId: string,
  status: string,
) =>
  httpService
    .patch<MorressierSession>({
      url: `api/v3/standalone/events/${eventId}/sessions/${sessionId}/delegates/${delegateId}/stage`,
      data: { status },
    })
    .then(r => r.data);

type _ReturnType<T> = T extends string
  ? { isBookmarked: boolean }
  : T extends string[]
  ? { isBookmarked: boolean }[]
  : never;

// sessionIds can be one or more comma separated strings
export const checkSessionsBookmarkStatus = (sessionIds: string, eventId: string) =>
  httpService
    .get<{ isBookmarked: boolean; session_id: string }[]>({
      url: `api/session-bookmark?session_id=${sessionIds}&event_id=${eventId}`,
      config: { baseURL: '/o' },
    })
    .then(r => {
      const result = r.data.reduce((acc, curr) => {
        const prev = acc;
        prev[curr.session_id] = { isBookmarked: curr.isBookmarked };
        return prev;
      }, {} as { [session_id: string]: { isBookmarked: boolean } });

      return result;
    });

export const saveBookmark = (session: MorressierSession, event_id: string, user_id: string) =>
  httpService
    .post<{ [session_id: string]: { isBookmarked: boolean } }>({
      url: `api/session-bookmark?data=${JSON.stringify({
        session_type: session.type,
        start_date: session.start_date,
        end_date: session.end_date,
        event_id,
        user_id,
        session_id: session._id,
      })}`,
      config: { baseURL: '/o', headers: { 'Content-Type': 'application/json' } },
    })
    .then(r => r.data);

export const deleteBookmark = (sessionId: string, eventId: string, userId: string) =>
  httpService
    .delete<{ [session_id: string]: { isBookmarked: boolean } }>({
      url: `api/session-bookmark?session_id=${sessionId}&event_id=${eventId}&user_id=${userId}`,
      config: { baseURL: '/o' },
    })
    .then(r => r.data);
