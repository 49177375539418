import { getParamByParam } from 'iso-country-currency';

import { FE_Discount } from '.';
import { Discount } from './ApplyCoupons';
import { OrderPayload } from './hooks';

const currency = '€';

export const getCurrency = (currencyCode = 'EUR') =>
  getParamByParam('currency', currencyCode, 'symbol') || currency;

// TODO: revisit this
export const moneyFormat = (amount: number | string) =>
  parseFloat((amount || 0) as string).toFixed(2);

// here we are taking more scenarios into consideration
// see https://morressier.atlassian.net/browse/EVT-3119
// We might need to update this again when we have updates from Eri
export const getAppliedDiscount = (
  discountData: Discount | undefined,
  payload: OrderPayload,
): FE_Discount | null => {
  if (!discountData) return null;

  const possibleOrderItems = payload.items;
  let discount_amount = 0;
  let hasInsufficientCoupons = false;
  let availableQuantity = discountData.available_quantity;

  let applicableQuantity = possibleOrderItems.reduce(
    // we shouldn't apply deductions to free tickets
    (acc, cur) => acc + (Number(cur.price) > 0 ? 1 : 0),
    0,
  );

  const items =
    discountData.supported_tickets === 'all'
      ? possibleOrderItems
      : possibleOrderItems.filter(item =>
          (discountData.supported_tickets as []).map(String).includes(String(item.ticketId)),
        );

  for (let i = 0; applicableQuantity > 0 && availableQuantity > 0 && items[i]; i += 1) {
    hasInsufficientCoupons = applicableQuantity > availableQuantity;
    const item = items[i];

    availableQuantity -= 1;
    applicableQuantity -= 1;

    if (discountData.type === 'subtract') {
      discount_amount += Number(discountData.value);

      item.reducedPrice = Number(items[i].price) - discountData.value;
    } else {
      const ticketDiscount = items[i].price * (discountData.value / 100);
      discount_amount += ticketDiscount;
      item.reducedPrice = Number(items[i].price) - ticketDiscount;
    }
  }

  if (discount_amount < 0) {
    discount_amount = 0;
  }

  const discount_text =
    discountData.type === 'subtract' ? `${discount_amount}` : `${discountData.value}%`;

  return {
    ...discountData,
    discount_amount,
    discount_text,
    hasInsufficientCoupons,
  };
};

export const canBuyTickets = (salesSetup?: SalesSetup) => {
  const start_date = salesSetup?.start_date;
  const end_date = salesSetup?.end_date;
  const max_registration = salesSetup?.max_registration;
  const purchased_tickets = salesSetup?.purchased_tickets;
  const today = new Date();

  const hasReachedMaxRegs =
    purchased_tickets && max_registration && purchased_tickets >= max_registration;
  const isSalesOpen =
    start_date && today >= new Date(start_date) && end_date && today <= new Date(end_date);
  const allowBuyTickets = isSalesOpen && !hasReachedMaxRegs;

  return !!allowBuyTickets;
};
