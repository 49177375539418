import { Text, Flexbox } from '@morressier/ts';

import { AlreadyHaveAnAccount } from 'components/BuyTicketModal/AlreadyHaveAnAccount';
import { BuyATicketButton } from 'components/BuyTicketModal/BuyATicketButton';
import { useAuthedUser } from 'hooks/useIsAuthedUser';

import * as Styles from './styles';

export const TicketingBox: React.FC<{
  ticketingProduct?: TicketingProduct;
  event: MorressierEvent;
}> = ({ children, ticketingProduct, event }) => {
  const isAuthed = useAuthedUser();

  return (
    <>
      <Styles.TicketingBox justifyContent="center">
        <Flexbox flexDirection="column" alignItems="center" justifyContent="center">
          <div>{children}</div>
          {event.ticketing?.provider ? (
            <>
              {event.ticketing?.provider === 'open' ? (
                <Text>
                  Join to access Live Sessions, Networking Sessions, and connect with your peers
                  using instant messaging and 1-1 meetings.
                </Text>
              ) : (
                <Text>
                  Purchase to access restricted content including Live and Networking Sessions.
                  Connect with your peers using messaging and 1-1 meetings.
                </Text>
              )}
              <BuyATicketButton />
            </>
          ) : (
            <Text>Connect with organizers to access this restricted content.</Text>
          )}
          {!isAuthed && (
            <div style={{ marginTop: '2.5rem' }}>
              <AlreadyHaveAnAccount />
            </div>
          )}
        </Flexbox>
      </Styles.TicketingBox>
    </>
  );
};
