import { color, SimpleText, Text, ColorNames } from '@morressier/ts';

import styled, { css } from 'styled-components';

import { TopImage } from 'components/TopNavigation/styles';
import { SimpleCard } from 'containers/SessionsList/styles';

export const HeroSection = styled.div`
  width: 100%;
  height: 320px;
  object-fit: cover;
  background-color: rgb(217, 219, 223);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;

  @media screen and (max-width: 1024px) {
    height: 160px;
  }
`;

export const BannerBackground = styled.img<{ hasImage?: string }>`
  position: absolute;
  width: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  margin: auto;
  justify-self: center;
  z-index: -1;
  filter: blur(1.25rem);
  -webkit-filter: blur(1.25rem);
  color: rgba(0, 0, 0, 0);
  background-color: ${props => (props.src ? 'unset' : 'rgba(0, 0, 0, 0.1)')};
`;

export const Banner = styled.img<{ centralize?: boolean }>`
  background-repeat: no-repeat;
  color: rgba(0, 0, 0, 0);
  width: 100%;

  ${props =>
    props.centralize &&
    css`
      height: 100%;
      width: unset;
      margin: auto;
      object-fit: cover;
      justify-self: center;
    `}
`;

export const LogoImage = styled.img`
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  object-fit: cover;
  background-color: rgb(217, 219, 223);
  align-self: center;
  border-radius: 50%;
  margin-top: -50px;
  border: none;
  z-index: 1;
  position: relative;
  border: 4px solid white;
`;

export const OrganizerCardWrapper = styled.div`
  padding: 1rem;
  border-radius: 3px;
  border: 1px solid #d4d5d6;
  display: flex;
  margin: 0.5rem 0;
  align-items: center;
  min-height: 74px;

  a {
    text-decoration: underline;
    color: unset;
  }

  &:hover {
    border-color: #8e949e;
  }
`;

export const OrganiserImage = styled(TopImage)`
  border-radius: 50%;
  margin: 0 16px 0 0;
`;

export const EventTitle = styled(SimpleText).attrs(() => ({
  as: 'h1',
  size: 'h4_new',
  color: 'secondaryBrand',
  fontWeight: 'bold',
}))`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 800;

  @media (min-width: 1024.1px) {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 800;
  }
`;

// copied from discovery
export const OutsideLinkEl = styled(SimpleText).attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  color: ${color('primaryBrand')};
  text-decoration: none;
  padding-right: 1.25rem;

  svg {
    margin-left: 0.25rem;
    position: relative;
    top: 1px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const NoMoreUpcoming = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 840px;
  margin-top: 64px;
`;

export const NoMoreUpcomingSVG = styled.div<{ color?: ColorNames }>`
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: ${props => color(props.color || 'sidebarHover')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubtitleCountCaption = styled(Text)`
  font-size: 14px;
  color: ${color('grey')};
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const SvgWrapper = styled.div`
  position: relative;
  display: inline;
  margin-right: 0.5rem;

  &:before {
    position: absolute;
    content: ' ';
    width: 16px;
    height: 16px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: ${color('lightGrey')};
    border-radius: 50%;
    z-index: -1;
  }
`;

export const BuyTicketCard = styled(SimpleCard)`
  height: 100%;
  min-height: 220px;
  max-height: 250px;

  && {
    box-shadow: 0px 0px 2px 0px rgba(66, 77, 93, 0.24), 0px 2px 2px 0px rgba(66, 77, 93, 0.24);
    padding: 1.5rem;
    cursor: unset;
  }
`;
