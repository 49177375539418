import throttle from 'lodash/throttle';
import { useCallback, useEffect, useState } from 'react';

// TODO: reimplement with intersectionObserver??
export const useDidElementComeIntoView = (
  el?: HTMLElement | HTMLDivElement | null,
  offset = { top: 0 },
) => {
  const [hasBeenInView, setHasBeenInView] = useState(false);

  const elementChecker = useCallback(() => {
    if (!el) return;

    const { clientHeight, clientWidth } = document.documentElement;
    const windowHeight = window.innerHeight || clientHeight;
    const windowWidth = window.innerWidth || clientWidth;
    const rect = el.getBoundingClientRect();

    const isVertInView = rect.top - offset.top <= windowHeight && rect.top + rect.height >= 0;
    const isHorInView = rect.left <= windowWidth && rect.left + rect.width >= 0;
    const isInView = isVertInView && isHorInView;

    if (isInView) {
      setHasBeenInView(isInView);
    }
  }, [el, offset]);

  const handleScroll = throttle(elementChecker, 600, { trailing: true, leading: false });

  useEffect(() => {
    if (el && !hasBeenInView) {
      handleScroll();
      window.addEventListener('scroll', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, [el, handleScroll, hasBeenInView]);

  return hasBeenInView;
};
