import { useState, FormEvent } from 'react';

import { useRouter } from 'next/router';

import { Text, Button, StyledInput, Flexbox, StyledLink } from '@morressier/ts';

import { AxiosError } from 'axios';

import { simpleToast } from 'helpers/simpleToast';
import httpService from 'services/httpService';

import { CreatedOrder } from '.';

export type Discount = {
  code: string;
  type: 'percent' | 'subtract';
  discount: number; // this is the discount amount, i.e, the amount that will be deducted from the total price
  discounted_price: number; // the new total price of the items in cart after the discount is applied
  value: number; // this value reflects the percentage discount or the value discount
  applicable_quantity: number; // the total number of tickets that this coupon/voucher can be applied on from top - down (ticketid must be contained in supported_tickets if supported_tickets !== 'all')
  available_quantity: number; // the total available coupon/vouchers
  supported_tickets: (string | number)[] | 'all'; // TODO: BE should add supported tickets.
  // if we have supported tickets, it means we only apply the discounts on those tickets otherwise the discount is applied on the order total
};

export type ErrorFormat<T = unknown> = AxiosError<{
  errors: {
    details: T | Record<string, string>;
    message: string;
  };
}>;

const applyVoucher = async (orderId: string, voucher: string, productId: string) =>
  // TODO: add proper type
  httpService.patch<Record<string, unknown> & { discount: Discount }>({
    url: `/api/v4/pas/orders/${orderId}/vouchers/${voucher}?productId=${productId}`,
  });

export const ApplyCoupons: React.FC<{
  createdOrder: CreatedOrder;
  discount: Discount | null | undefined;
  handleDiscounts: (discount: Discount) => void;
  handleUpdate: (goToNext?: boolean) => Promise<void>;
  couponText: [string, React.Dispatch<React.SetStateAction<string>>];
}> = ({ createdOrder, ...props }) => {
  const [showCoupon, setShowCoupon] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = props.couponText;
  const [error, setError] = useState('');
  const { query } = useRouter();

  const handleApplyVoucher = async (ev: FormEvent) => {
    ev.preventDefault();
    if (!value) return;
    setIsLoading(true);
    setError('');

    try {
      // we need to handle cart updates/changes(if any) before applying the voucher to make sure FE and BE are in sync
      await props.handleUpdate();

      await applyVoucher(createdOrder.orderId, value, query.eventId as string)
        .then(({ data }) => {
          props.handleDiscounts({ ...data.discount });
        })
        .catch((e: ErrorFormat) => {
          const message = e.response?.data?.errors.message;
          setError(typeof message === 'string' ? message : 'Coupon not found');
        });
    } catch (axError) {
      const message = (axError as AxiosError)?.response?.data?.errorData?.error;
      simpleToast(
        message ||
          'An error occurred while processing this request. Please try again or contact our support team',
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (props.discount) {
    return null;
  }

  return showCoupon ? (
    <form className="mt3" onSubmit={handleApplyVoucher}>
      <Text fontWeight="semiBold">Coupon/Voucher</Text>
      <Flexbox>
        <StyledInput
          value={value}
          onChange={({ target }) => setValue(target.value)}
          className="mr1"
          isInvalid={!!error}
        />
        <Button type="submit" loading={isLoading} color="primary">
          Apply
        </Button>
      </Flexbox>
      {error && (
        <Text size="body3" color="red">
          {error.replace(' !', '!')}
        </Text>
      )}
    </form>
  ) : (
    <div className="mt3">
      <StyledLink
        onClick={() => setShowCoupon(true)}
        style={{ cursor: 'pointer', fontSize: '14px' }}
        color="primaryLink"
      >
        Redeem a Coupon/Voucher
      </StyledLink>
    </div>
  );
};
