import { PlainButton, Text } from '@morressier/ts';

import { useAuthedUser } from 'hooks/useIsAuthedUser';
import { useLogIn } from 'hooks/useLogIn';

export const AlreadyHaveAnAccount = () => {
  const { handleLogIn } = useLogIn();
  const isAuthed = useAuthedUser();

  return isAuthed ? null : (
    <Text color="grey">
      Already have an account?{' '}
      <PlainButton onClick={handleLogIn}>
        <Text color="primaryLink" style={{ textDecoration: 'underline' }}>
          Sign in
        </Text>
      </PlainButton>
    </Text>
  );
};
