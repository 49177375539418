import React from 'react';
import { RectangleSkeleton } from '@morressier/ts';
import { Grid } from '@material-ui/core';
import { RowsSkeleton } from 'components/Skeleton/Rows';
import { PageContainer } from 'containers/styles';
import { BannerContainer } from 'containers/SingleSession/styles';

export default () => (
  <>
    <RectangleSkeleton width="100%" height="320px" />
    <PageContainer withTopMargin>
      <RowsSkeleton />
    </PageContainer>
  </>
);

export const SessionHeaderLoading = () => (
  <>
    <PageContainer style={{ marginTop: '2.25rem' }}>
      <Grid container spacing={4}>
        <Grid xs={12} lg={8} item>
          <BannerContainer>
            <RectangleSkeleton width="100%" height="100%" />
          </BannerContainer>
          <div style={{ marginTop: '2.25rem' }}>
            <RowsSkeleton rows={8} />
          </div>
          <div style={{ marginTop: '2.25rem', width: '50%' }}>
            <RowsSkeleton rows={6} />
          </div>
        </Grid>
        <Grid xs={12} lg={4} item>
          <RectangleSkeleton width="100%" height="166px" />
        </Grid>
      </Grid>
    </PageContainer>
  </>
);
