import { useRouter } from 'next/router';

import { SimpleText, Text, Flexbox, Button } from '@morressier/ts';


import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { BiCalendarStar } from 'react-icons/bi';

import { useSessionBookmarksInView } from 'containers/SessionsList/hooks';
import { SessionsListItem } from 'containers/SessionsList/SessionsListItem';
import { logEvent } from 'utils/eventTracking';

import * as ContainerStyles from '../styles';
import { getSessionsRenderInfo } from './helpers';
import * as Styles from './styles';

type Props = {
  event: MorressierEvent;
  data: { count: number; sessions: MorressierSession[] };
  isEmbargoed: boolean;
};

export const FeaturedSessions: React.FC<Props> = ({ event, ...props }) => {
  const { sessions, count: total } = props.data;
  const eventId = event.id;
  const { push } = useRouter();
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down('md'));
  const isBelowSm = useMediaQuery(theme.breakpoints.down('xs'));
  const { titleClamp, cardMode, getGridProps } = getSessionsRenderInfo(sessions, isBelowSm);
  const { bookmarkData, elementRef } = useSessionBookmarksInView(eventId, sessions);

  return (
    <ContainerStyles.Section ref={elementRef}>
      <ContainerStyles.SubTitle>Featured Sessions</ContainerStyles.SubTitle>

      {total ? (
        <>
          <Styles.SubtitleCountCaption>
            {sessions?.length} of {total} Featured Sessions
          </Styles.SubtitleCountCaption>

          <Grid container spacing={isBelowLg ? 2 : 4} style={{ marginBottom: '2rem' }}>
            {sessions?.map(({ description, featured, ...session }) => (
              <Grid item {...getGridProps()} key={session._id}>
                <SessionsListItem
                  session={{ ...session, type: 'PRESENTATIONS' }}
                  event={event}
                  embargoed={props.isEmbargoed}
                  titleClamp={titleClamp}
                  isSponsored={session.is_sponsored}
                  sessionToCalendarExport={{ ...session, description }}
                  cardMode={cardMode}
                  withBookmarkData={bookmarkData}
                />
              </Grid>
            ))}
          </Grid>

          <Button
            color="primary"
            onClick={() => {
              logEvent('STANDALONE_CLICKED_EXPLORE_SESSIONS');
              push(`/event/${eventId}/sessions?featured=true`);
            }}
          >
            Explore Featured Sessions
          </Button>
        </>
      ) : (
        <Flexbox justifyContent="center">
          <Styles.NoMoreUpcoming>
            <Styles.NoMoreUpcomingSVG>
              <BiCalendarStar size="48px" color="#8E949E" />
            </Styles.NoMoreUpcomingSVG>
            <SimpleText
              as="span"
              color="secondaryBrand"
              fontWeight="semiBold"
              style={{ fontSize: '24px' }}
              className="mb2 mt2"
            >
              There are no further Upcoming Sessions.
            </SimpleText>
            <Text as="span" fontWeight="regular" color="grey">
              As soon as the Event has finished, we will post highlights for you to explore.
            </Text>
          </Styles.NoMoreUpcoming>
        </Flexbox>
      )}
    </ContainerStyles.Section>
  );
};
