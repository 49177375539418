import useSWR, { mutate } from 'swr';

type UpcomingSession = {
  count: number;
  sessions: MorressierSession[];
};

type HappeningNowSessions = UpcomingSession;

export const useUpcomingSessions = (eventId: string | null) => {
  const url = eventId ? `/api/v3/standalone/events/${eventId}/upcoming-sessions` : null;

  const { data, error } = useSWR<UpcomingSession>(url);

  return {
    data,
    refetch: () => mutate(url),
    loading: !data && !error && !!url,
  };
};

export const useSessionsHappeningNow = (eventId: string | null) => {
  const url = eventId ? `/api/v3/standalone/events/${eventId}/happening-now` : null;

  const { data, error } = useSWR<HappeningNowSessions>(url);

  return {
    data,
    refetch: () => mutate(url),
    loading: !data && !error && !!url,
  };
};
