/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useRef, useState } from 'react';

import useSWR, { mutate, SWRHook } from 'swr';

import { getTaggableUsers } from 'api/discussions';
// TODO: REMOVE AFTER CHAT DEPRECATION
import { fetchEventSession } from 'api/session';
import { Mentions } from 'components/Comment/MentionsEditor';
import { getMinsDifferenceWithCurrentDate } from 'utils/dates';
import { logEvent } from 'utils/eventTracking';
import { logger } from 'utils/logger';
import { useFeatureFlagContext } from 'utils/unleashFeatureFlags/FeatureFlagProvider';
import { FEATURE_FLAGS } from 'utils/unleashFeatureFlags/flags';

import AdRenderer from './AdRenderer';
import { Browser, detectBrowser } from './helpers';

/**
 * setTimeout() usually "breaks" for large millisecond delay values
 * https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
 */
export const TIMEOUT_MAX = 2147483647;

export const useTaggableUsers = (poster_id: string, session_id?: string) => {
  const { data, error } = useSWR<ResolvedPromise<ReturnType<typeof getTaggableUsers>>>(
    poster_id && session_id ? [poster_id, session_id] : null,
    getTaggableUsers,
    { revalidateOnFocus: false },
  );

  const mentions: Mentions[] =
    data?.map(user => ({
      ...user,
      name: user.full_name || 'No name',
      avatar: user.picture_url,
      id: user.id,
    })) || [];

  return {
    data: mentions,
    loading: !data && !error,
  };
};

export const useSessionFetch = (
  eventId: string,
  sessionId: string,
  fallbackData: MorressierSession,
  useDummyData?: boolean,
  params?: any,
) => {
  const {
    data,
    error,
    mutate: mutateSession,
  } = useSWR<MorressierSession>(
    useDummyData ? null : [eventId, sessionId, params, 'refetch-live-session'],
    fetchEventSession,
    {
      fallbackData,
      revalidateOnFocus: false,
    },
  );

  return {
    data: data!,
    loading: !data && !error,
    refetch: () => mutateSession(),
  };
};

export const useSessionTime = ({
  startDate,
  endDate,
  options,
}: {
  startDate?: string | number | Date;
  endDate?: string | number | Date;
  options?: { runPastDates: boolean };
}) => {
  const [isPreSession, setIsPreSession] = useState(
    startDate ? new Date() < new Date(startDate) : false,
  );
  const [isPostSession, setIsPostSession] = useState(
    endDate ? new Date() > new Date(endDate) : false,
  );

  useEffect(() => {
    if (!startDate || !endDate) {
      return () => {
        //
      };
    }

    setIsPreSession(new Date() < new Date(startDate));
    setIsPostSession(new Date() > new Date(endDate));

    const milisecondsUntilStartDate = new Date(startDate).getTime() - Date.now();
    const milisecondsUntilEndDate = new Date(endDate).getTime() - Date.now();

    if (options?.runPastDates) {
      if (milisecondsUntilStartDate <= 0) {
        setIsPreSession(false);
      }

      if (milisecondsUntilEndDate <= 0) {
        setIsPostSession(true);
      }
    }

    let handleEndDate: NodeJS.Timeout;
    let handleStartDate: NodeJS.Timeout;
    if (milisecondsUntilStartDate < TIMEOUT_MAX) {
      handleStartDate = setTimeout(() => {
        setIsPreSession(false);
      }, milisecondsUntilStartDate);
    }

    if (milisecondsUntilEndDate < TIMEOUT_MAX) {
      handleEndDate = setTimeout(() => {
        setIsPostSession(true);
      }, milisecondsUntilEndDate);
    }

    return () => {
      clearTimeout(handleEndDate);
      clearTimeout(handleStartDate);
    };
  }, [endDate, options?.runPastDates, startDate]);

  return {
    isPreSession,
    isPostSession,
    isHappeningNow: !isPreSession && !isPostSession,
  };
};

export const useLiveWatchTimeTracking = (trackingAttrs: Record<string, unknown>) => {
  const trackingAttrsRef = useRef<Record<string, unknown>>(trackingAttrs);
  const lastStartTime = useRef<null | number>(null);

  const startTracking = useCallback(() => {
    lastStartTime.current = Date.now();
  }, []);

  const stopTracking = useCallback(() => {
    if (lastStartTime.current === null) return;

    logEvent('WATCHED_VIDEO_LIVESTREAM', {
      ...trackingAttrsRef.current,
      watched_for: (Date.now() - lastStartTime.current) / 1000,
    });

    lastStartTime.current = null;
  }, []);

  useEffect(() => {
    trackingAttrsRef.current = trackingAttrs;
  });

  useEffect(() => {
    window.addEventListener('beforeunload', stopTracking);

    return () => {
      // Log on unmount, otherwise the state would be lost
      stopTracking();
      window.removeEventListener('beforeunload', stopTracking);
    };
  }, [stopTracking]);

  return { startTracking, stopTracking };
};

export const useNetworkingSessionParticipants = (sessionId: string | null) => {
  const url = `api/v3/discovery/networking-sessions/${sessionId}/participants`;
  const { data, error } = useSWR<MorressierUser[]>(url);

  return {
    data: error ? [] : data,
    refetch: () => mutate(url),
    loading: !data && !error,
  };
};

export const useGetUserMedia = () => {
  const [devicesPermissionState, setDevicesPermissionState] = useState<PermissionState>('prompt');

  const handleRequestPermissions = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(mediaStream => {
        logger.log('Media stream', mediaStream);
        setDevicesPermissionState('granted');
      })
      .catch(err => {
        logger.error('Get user media failed, reason:', err.name);
        if (err.name === 'NotAllowedError') {
          setDevicesPermissionState('denied');
        }
      });
  };

  const checkIfPermissionsGranted = async () => {
    try {
      const cameraPermissions = await navigator.permissions
        // @ts-ignore
        .query({ name: 'camera' });
      const microphonePermissions = await navigator.permissions
        // @ts-ignore
        .query({ name: 'microphone' });

      switch (cameraPermissions.state && microphonePermissions.state) {
        case 'granted':
          setDevicesPermissionState('granted');
          break;
        case 'prompt':
          setDevicesPermissionState('prompt');
          break;
        case 'denied':
          setDevicesPermissionState('denied');
          break;
        default:
          setDevicesPermissionState('prompt');
      }
    } catch (err) {
      logger.error('Permissions query failed:', err);
    }
  };
  useEffect(() => {
    if (detectBrowser() !== Browser.Firefox) {
      checkIfPermissionsGranted();
    }
  }, []);

  return {
    devicesPermissionState,
    handleRequestPermissions,
    checkIfPermissionsGranted,
  };
};

export const useSessionAds = ({
  session,
  liveStatus,
  isEnded,
}: {
  session: MorressierSession;
  liveStatus: LiveStatusType;
  isEnded: boolean;
}) => {
  const { features } = useFeatureFlagContext();
  const enabledAds = features[FEATURE_FLAGS.LIVE_ADS_SECTIONS];
  const [minsToStart, setMinsToStart] = useState(
    getMinsDifferenceWithCurrentDate(session.start_date),
  );

  const showPreSessionAd = liveStatus === 'EARLY_START' && !!session.ads?.pre?.url;
  const showPauseSessionAd = liveStatus === 'PAUSED' && !!session.ads?.pause?.url;
  const showPostSessionAd = (isEnded || liveStatus === 'ENDED') && !!session.ads?.end?.url;
  const showAd = showPreSessionAd || showPauseSessionAd || showPostSessionAd;

  useEffect(() => {
    if (!session.start_date) return undefined;

    let interval;
    if (minsToStart >= 0) {
      interval = setInterval(() => {
        setMinsToStart(getMinsDifferenceWithCurrentDate(session.start_date));
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [minsToStart, session.start_date]);

  if (!enabledAds) {
    return {
      showAd: false,
      RenderAd: () => null,
      showPreSessionAd: false,
      showPauseSessionAd: false,
      showPostSessionAd: false,
    };
  }

  const RenderAd = () => {
    let adToShow;
    if (showPreSessionAd) adToShow = session.ads?.pre;
    if (showPauseSessionAd) adToShow = session.ads?.pause;
    if (showPostSessionAd) adToShow = session.ads?.end;
    if (!showAd || !adToShow) return null;

    return <AdRenderer ad={adToShow} isPaused={showPauseSessionAd} />;
  };

  return {
    showAd,
    RenderAd,
    showPreSessionAd,
    showPauseSessionAd,
    showPostSessionAd,
  };
};

// This is a SWR middleware for keeping the data even if key changes.
// https://swr.vercel.app/docs/middleware#keep-previous-result
export const laggy = (useSWRNext: SWRHook) => (key, fetcher, config) => {
  // Use a ref to store previous returned data.
  const laggyDataRef = useRef();

  // Actual SWR hook.
  const swr = useSWRNext(key, fetcher, config);

  useEffect(() => {
    // Update ref if data is not undefined.
    if (swr.data !== undefined) {
      laggyDataRef.current = swr.data;
    }
  }, [swr.data]);

  // Expose a method to clear the laggy data, if any.
  const resetLaggy = useCallback(() => {
    laggyDataRef.current = undefined;
  }, []);

  // Fallback to previous data if the current data is undefined.
  const dataOrLaggyData = swr.data === undefined ? laggyDataRef.current : swr.data;

  // Is it showing previous data?
  const isLagging = swr.data === undefined && laggyDataRef.current !== undefined;

  // Also add a `isLagging` field to SWR.
  return { ...swr, data: dataOrLaggyData, isLagging, resetLaggy };
};
