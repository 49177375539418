import React from 'react';
import styled from 'styled-components';
import { asSummaryCount } from 'utils/numberFormatter';
import { IconType } from 'icons';
import Link from 'next/link';
import { color, media, Text } from '@morressier/ts';

type IStats = {
  icon: React.FC<IconType>;
  label: string;
  stat?: number;
  link?: string;
  linkParams?: {
    eventId: string;
    contentLibrary?: string;
    contentLibraryTitle?: string;
    fromSearch?: string;
    from?: string;
  };
  passQueryParam?: boolean;
};

const StatsCardEl = styled.div`
  width: 100%;
  padding: 1rem;
  border-radius: 3px;
  border: 1px solid #d4d5d6;
  display: flex;
  flex-direction: column;
  text-decoration: none;

  &:hover {
    border-color: ${color('grey')};
  }

  span {
    font-size: 56px;
    line-height: 56px;

    @media screen and (max-width: 1024px) {
      font-size: 48px;
    }
  }
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  color: #8e949e;

  svg {
    margin-right: 4px;
    height: 14px;
    width: 14px;
  }
`;

export const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  ${media.small`
    grid-template-columns: 1fr 1fr;
  `}

  ${media.medium`
    grid-template-columns: 296px 296px;
  `}
`;

export const LongStatsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  ${media.small`
    grid-template-columns: repeat(4, 1fr);
  `}

  ${media.medium`
    grid-template-columns: repeat(5, 296px);
  `}
`;

const StatsCard: React.FC<IStats> = props => {
  const card = (
    <StatsCardEl as="a">
      <IconWrap>
        <props.icon />
        <Text color="grey">{props.label}</Text>
      </IconWrap>
      <Text as="span" style={{ paddingTop: '0.5rem' }}>
        {asSummaryCount(props.stat)}
      </Text>
    </StatsCardEl>
  );

  if (props.link) {
    const hrefObj = props.passQueryParam
      ? { pathname: props.link, query: props.linkParams ? props.linkParams : '' }
      : props.link;

    return (
      <Link href={hrefObj} passHref>
        {card}
      </Link>
    );
  }

  return card;
};

export default StatsCard;
