import React from 'react';

import { Text } from '@morressier/ts';

import { Grid, GridSize, useTheme, useMediaQuery } from '@material-ui/core';

import PosterGrid, { SubmissionType } from 'components/PosterGrid';
import { GridSkeleton } from 'components/Skeleton/Grid';
import { useSessionsBookmarks } from 'containers/SessionsList/hooks';
import { SessionsListItem } from 'containers/SessionsList/SessionsListItem';

interface CommonProps {
  _id: string;
  id: string;
}

export interface AuthorSectionProps extends CommonProps {
  authors: { _id: string; full_name: string; id: string }[];
  title: string;
  stats: MorressierPosterStats; // Use fetchPosterStats to get them
  keywords: string[]; // use fetchPosterKeywords to get them
  thumbnails: MorressierPosterThumbnails; // use fetchPosterThumnails to get them
}

export interface SpeakerChairSectionProps extends CommonProps {
  mode: SessionMode;
  chair_name: string[];
  chairs: string[];
  featured: boolean;
  name: string;
  start_date: string;
  end_date: string;
  location: string;
}

export type ContributionSectionProps = {
  sectionType: 'author' | 'speaker' | 'chair';
  sectionName: string;
  event: MorressierEvent;
  submissionType?: SubmissionType;
  showThumbnail?: boolean;
  isLoading?: boolean;
  section?: AuthorSectionProps[] | SpeakerChairSectionProps[];
  withBookmarkData?: ReturnType<typeof useSessionsBookmarks>;
};

export const ContributionSection: React.FC<ContributionSectionProps> = ({
  sectionType,
  sectionName,
  event,
  submissionType,
  showThumbnail,
  isLoading,
  section,
  withBookmarkData,
}) => {
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down('md'));
  const eventId = event.id;

  const getGridProps = (): {
    xs?: boolean | GridSize;
    sm?: boolean | GridSize;
    lg?: boolean | GridSize;
  } => {
    if (section && section?.length <= 2) return { xs: 12, sm: 12 };

    return { xs: 12, sm: 6, lg: 4 };
  };

  const renderSection = () => {
    if (isLoading) {
      return <GridSkeleton distribution="grid" />;
    }

    if (sectionType === 'author') {
      return (
        <PosterGrid
          event={eventId}
          distribution={(section?.length ?? 0) <= 2 ? 'list' : 'grid'}
          items={section?.map(s => ({ ...s, ...s.thumbnails })) ?? []}
          submissionType={submissionType as SubmissionType}
          showThumbnail={Boolean(showThumbnail) || showThumbnail === undefined}
        />
      );
    }

    if (sectionType === 'speaker' || sectionType === 'chair') {
      return (
        <Grid
          container
          spacing={isBelowLg ? 2 : 4}
          style={{ marginTop: '0.5rem', marginBottom: '2rem' }}
        >
          {section?.map(session => (
            <Grid item {...getGridProps()} key={`speaker-session-${session._id}`}>
              <SessionsListItem
                session={{ ...session, type: 'PRESENTATIONS' }}
                sessionType={submissionType}
                event={event}
                isSponsored={session.is_sponsored}
                withBookmarkData={withBookmarkData}
              />
            </Grid>
          ))}
        </Grid>
      );
    }

    return null;
  };

  if (!section?.length) return null;

  return (
    <div style={{ marginTop: '2rem' }}>
      <Text size="h5_new" fontWeight="bold" color="secondaryBrand">
        {sectionName}
      </Text>
      <div className="mt1" style={{ paddingTop: sectionType === 'author' ? '1rem' : '' }}>
        {renderSection()}
      </div>
    </div>
  );
};
