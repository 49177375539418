import React, { useEffect } from 'react';

import { Flexbox, Text } from '@morressier/ts';

import { useCountDownTimer } from 'hooks/useTimeNow';

import { CreatedOrder } from '.';

export const ReservedOrder: React.FC<{
  createdOrder: CreatedOrder;
  setShowTimeUp: React.Dispatch<boolean>;
}> = props => {
  const { countdown, diffInSeconds } = useCountDownTimer(new Date(props.createdOrder.expiresAt));

  const getTenthValue = (num: number) => (num < 10 ? `0${num}` : num);

  useEffect(() => {
    if (props.createdOrder && diffInSeconds <= 0) {
      props.setShowTimeUp(true);
    }
  }, [diffInSeconds, props]);

  return countdown ? (
    <Flexbox
      style={{ padding: '8px', position: 'sticky', top: 86, zIndex: 2, background: '#F5F6F7' }}
    >
      <Text style={{ margin: 'auto' }} color={diffInSeconds <= 0 ? 'red' : 'secondaryBrand'}>
        Remaining reservation time {!!countdown.hours && countdown.hours}{' '}
        {getTenthValue(countdown.minutes)}:{getTenthValue(countdown.seconds)}
      </Text>
    </Flexbox>
  ) : null;
};
