import { StyledButton, Text } from '@morressier/ts';

import { SubTitle } from 'containers/styles';
import { useLogIn } from 'hooks/useLogIn';
import { logOut, useAuthContext } from 'store';

import { ApsStatus } from './hooks';

// This component gets rendered when user doesn't have access to the APS content
export const APSBlockedContent: React.FC<{
  event: MorressierEvent;
  isAuthenticated: boolean;
  apsEventId: string;
  apsStatus: ApsStatus;
}> = ({ event, isAuthenticated, apsEventId }) => {
  const { handleLogIn } = useLogIn();
  const { state: authState, dispatch } = useAuthContext();

  const handleLoginClick = async () => {
    if (authState?.profile?.id) {
      await dispatch(logOut());
    }

    handleLogIn({
      eventId: event._id,
      eventName: event.name,
      shortName: event.short_name,
    });
  };

  const sso = authState?.authInfo?.sso;

  const apsToken =
    sso?.provider === 'aps' ? sso.access_token : authState?.authInfo?.aps_token?.access_token;

  const handleAPSRegistration = () => {
    window.open(`https://my.aps.org/nc__event?id=${apsEventId}`, '__blank');
  };

  return (
    <>
      <SubTitle as="span" style={{ fontWeight: 600 }}>
        {event.name} is a private APS event
      </SubTitle>

      {isAuthenticated ? (
        <>
          <Text color="grey" as="span">
            {apsToken
              ? 'You must register for this meeting to gain access to this event.'
              : 'You are not signed in with your APS ID. Please signin with APS to continue'}
          </Text>
          <StyledButton
            as="a"
            style={{ marginTop: 10 }}
            onClick={apsToken ? handleAPSRegistration : handleLoginClick}
            color="primary"
          >
            {apsToken ? `Register for ${event.name}` : `Login with APS`}
          </StyledButton>
        </>
      ) : (
        <>
          <Text color="grey" as="span">
            You must login with APS to access this event.
          </Text>
          <StyledButton as="a" style={{ marginTop: 10 }} onClick={handleLoginClick} color="primary">
            Login with APS
          </StyledButton>
        </>
      )}
    </>
  );
};
