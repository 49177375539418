import { useEffect, useState } from 'react';
import { AuthorSectionProps } from 'components/User/ContributionSection';
import { enrichPosterWithThumbnail } from './helpers';

const loadingState: AuthorSectionProps[] = [
  /* @ts-ignore */
  { id: '1', title: '' },
  /* @ts-ignore */
  { id: '2', title: '' },
  /* @ts-ignore */
  { id: '3', title: '' },
];

export const usePosterWithThumbnail = (
  eventId: string,
  items: MorressierPoster[] | AuthorSectionProps[] = loadingState,
) => {
  const [itemsList, setItemsList] = useState(items);

  useEffect(() => {
    const fetchData = async () => {
      const { data: itemsWithThumbnails } = await enrichPosterWithThumbnail(eventId, items);
      setItemsList(itemsWithThumbnails);
    };

    fetchData();
  }, [items, eventId]);

  return {
    data: itemsList,
  };
};
