import { FC } from 'react';
import { ColorNames, Theme, StyledLink } from '@morressier/ts';
import { FaExternalLinkAlt } from 'react-icons/fa';
import styled, { css } from 'styled-components';

const OutsideLinkEl = styled(StyledLink).attrs((props: { href: string }) => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  href: props.href,
}))<{ eventThemed?: boolean; hideUnderline?: boolean; href: string }>`
  svg {
    margin-left: 0.25rem;
    position: relative;
    top: 1px;
  }

  ${props =>
    props.eventThemed &&
    css`
      text-decoration: none;
      padding-right: 1.25rem;

      &:hover {
        text-decoration: underline;
      }
    `}

  ${props =>
    props.hideUnderline &&
    css`
      text-decoration: none;
    `}
`;

interface OutsideLinkProps {
  href: string;
  color?: ColorNames;
  eventThemed?: boolean;
  hideUnderline?: boolean;
  fontWeight?: keyof typeof Theme['fontWeights'];
  onClick?: () => void;
}

const OutsideLink: FC<OutsideLinkProps> = ({
  href,
  color,
  fontWeight,
  eventThemed,
  hideUnderline = false,
  onClick,
  children,
}) => {
  const isLocal = href.includes('morressier.com/');
  const target = isLocal ? '' : '_blank';
  const rel = target === '_blank' ? 'noopener' : '';
  const themeColor = eventThemed ? 'primaryBrand' : '';

  return (
    <OutsideLinkEl
      href={href}
      target={target}
      rel={rel}
      size="body1_new"
      color={themeColor || color}
      eventThemed={eventThemed}
      fontWeight={fontWeight}
      hideUnderline={hideUnderline}
      onClick={onClick}
    >
      {children}
      {!isLocal && <FaExternalLinkAlt />}
    </OutsideLinkEl>
  );
};

export default OutsideLink;
