import { useState } from 'react';

import { EmptyDropdown, StyledInput, Text, Flexbox, Checkbox } from '@morressier/ts';

import { Grid } from '@material-ui/core';
import countryTimezones from 'countries-and-timezones';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { FaSortDown } from 'react-icons/fa';

import { BillingAddress } from './hooks';
import * as Styles from './styles';

export type FormValues = {
  searchCountry: string;
  billing_address: BillingAddress;
};

const countryList = Object.values(countryTimezones.getAllCountries());

export const BillingAddressForm = () => {
  const { register, control, clearErrors, setValue, watch } = useFormContext<FormValues>();
  const { errors } = useFormState({
    control,
  });
  const { searchCountry, billing_address } = watch();
  const [preselectedCountry, setPreselectedCountry] = useState(0);
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);

  const getCountries = () =>
    searchCountry
      ? countryList.filter(_country =>
          _country.name.toLowerCase().includes(searchCountry?.toLowerCase()),
        )
      : countryList;
  const countriesList = getCountries();

  return (
    <form style={{ marginTop: '2rem' }}>
      <Text size="h5_new" color="secondaryBrand" fontWeight="bold">
        Billing Address
      </Text>
      <Flexbox style={{ margin: '1rem 0' }}>
        <Grid container spacing={4} style={{ marginTop: 0 }}>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <Text size="body1_new" fontWeight="semiBold">
              Address*
            </Text>
            <StyledInput
              placeholder="Street and number"
              {...register('billing_address.address_line_one')}
              isInvalid={!!errors.billing_address?.address_line_one}
              onChange={e => {
                clearErrors('billing_address.address_line_one');
                setValue('billing_address.address_line_one', e.target.value);
              }}
            />
            {!!errors.billing_address?.address_line_one?.message && (
              <Text color="red" size="caption_new">
                {errors.billing_address?.address_line_one?.message}
              </Text>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Text size="body1_new" fontWeight="semiBold">
              City*
            </Text>
            <StyledInput
              placeholder="Berlin"
              {...register('billing_address.city')}
              isInvalid={!!errors.billing_address?.city}
              onChange={e => {
                clearErrors('billing_address.city');
                setValue('billing_address.city', e.target.value);
              }}
            />
            {!!errors.billing_address?.city?.message && (
              <Text color="red" size="caption_new">
                {errors.billing_address?.city?.message}
              </Text>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Text size="body1_new" fontWeight="semiBold">
              Postal Code*
            </Text>
            <StyledInput
              placeholder="10247"
              {...register('billing_address.zip_code')}
              isInvalid={!!errors.billing_address?.zip_code}
              onChange={e => {
                clearErrors('billing_address.zip_code');
                setValue('billing_address.zip_code', e.target.value);
              }}
            />
            {!!errors.billing_address?.zip_code?.message && (
              <Text color="red" size="caption_new">
                {errors.billing_address?.zip_code?.message}
              </Text>
            )}
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <Text size="body1_new" fontWeight="semiBold">
              Country*
            </Text>
            <Styles.DropDownContainer hasError={!!errors.billing_address?.country}>
              <EmptyDropdown
                reference={
                  <Styles.InputContainer
                    style={{
                      width: '100%',
                      cursor: 'pointer',
                    }}
                    onClick={() => setIsCountryDropdownOpen(prev => !prev)}
                  >
                    <StyledInput
                      value={billing_address.country}
                      readOnly
                      placeholder="Select..."
                      style={{
                        border: 'unset',
                        padding: 'unset',
                        cursor: 'pointer',
                        width: '90%',
                      }}
                      {...register('billing_address.country')}
                    />
                    <FaSortDown />
                  </Styles.InputContainer>
                }
                isOpen={isCountryDropdownOpen}
                placement="bottom"
                onClose={() => setIsCountryDropdownOpen(false)}
                content={
                  <div>
                    <div style={{ padding: '10px 10px 0' }}>
                      <StyledInput
                        placeholder="Search"
                        {...register('searchCountry')}
                        autoFocus
                        onKeyDown={e => {
                          switch (e.key) {
                            case 'Enter': {
                              const selectedCountry = countriesList[preselectedCountry];
                              setValue('billing_address.country', selectedCountry.name);
                              clearErrors('billing_address.country');
                              setIsCountryDropdownOpen(false);
                              break;
                            }
                            case 'ArrowDown':
                            case 'Down': {
                              setPreselectedCountry(
                                preselectedCountry < countriesList.length - 1
                                  ? preselectedCountry + 1
                                  : preselectedCountry,
                              );
                              break;
                            }
                            case 'ArrowUp':
                            case 'Up': {
                              setPreselectedCountry(
                                preselectedCountry > 0
                                  ? preselectedCountry - 1
                                  : preselectedCountry,
                              );
                              break;
                            }
                            default:
                              setPreselectedCountry(0);
                          }
                        }}
                      />
                    </div>
                    <Flexbox
                      flexDirection="column"
                      role="listbox"
                      style={{ maxHeight: '300px', overflowY: 'auto' }}
                    >
                      {countriesList
                        .sort((a, b) => (a.name < b.name ? -1 : 1))
                        .map((option, index) => (
                          <Styles.DropdownItem
                            key={option.name}
                            selected={billing_address.country === option.name}
                            preSelected={preselectedCountry === index}
                          >
                            <Text
                              style={{
                                padding: '10px',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setValue('billing_address.country', option.name);
                                clearErrors('billing_address.country');
                                setIsCountryDropdownOpen(false);
                              }}
                            >
                              {option.name}
                            </Text>
                          </Styles.DropdownItem>
                        ))}
                    </Flexbox>
                  </div>
                }
              />
            </Styles.DropDownContainer>
            {!!errors.billing_address?.country && (
              <Text color="red" size="caption_new">
                {errors.billing_address?.country.message}
              </Text>
            )}
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <Controller
              control={control}
              name="billing_address.is_gdpr_accepted"
              render={props => (
                <>
                  <Styles.StyledCheckBoxContainer
                    style={{ fontWeight: 'bold' }}
                    isInvalid={!!errors.billing_address?.is_gdpr_accepted?.message}
                  >
                    <Checkbox
                      label={<Text fontWeight="semiBold">I authorize Morressier</Text>}
                      tooltip="I am authorizing Morressier to share my data with my conference organizer to facilitate access to events, ticket history and communications related to those events."
                      inputProps={{
                        name: 'is_gdpr_accepted',
                        value: String(props.field.value),
                        checked: !!props.field.value,
                        onChange: () => {
                          clearErrors('billing_address.is_gdpr_accepted');
                          setValue('billing_address.is_gdpr_accepted', !props.field.value);
                        },
                      }}
                    />
                  </Styles.StyledCheckBoxContainer>
                  {!!errors.billing_address?.is_gdpr_accepted?.message && (
                    <Text color="red" size="caption_new">
                      {errors.billing_address?.is_gdpr_accepted?.message}
                    </Text>
                  )}
                </>
              )}
            />
          </Grid>
        </Grid>
      </Flexbox>
    </form>
  );
};
