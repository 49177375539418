import { ComponentType, useEffect } from 'react';

import { useRouter } from 'next/router';

import { Text, Flexbox, StyledButton } from '@morressier/ts';

import { format } from 'date-fns';
import { omit } from 'lodash';
import { FaEyeSlash, FaRegCalendarTimes } from 'react-icons/fa';
import useSWR from 'swr';

import { fetchEventInfo, fetchEventTabsConfig } from 'api/events';
import { EmptyLogo, EmptyLogoWrapper, LogoImageWrapper } from 'components/AvatarBanner';
import { getCurrency } from 'components/BuyTicketModal/utils';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { MetaTags, Props as MetaTagsProps } from 'components/MetaTags';
import NextImage from 'components/NextImage';
import SpinnerIcon from 'components/Spinner';
import { FeatureFlags } from 'config/featureFlags';
import { EventHome } from 'containers/EventHome';
import { NoMoreUpcomingSVG } from 'containers/EventHome/styles';
import { SubTitle } from 'containers/styles';
import { useLocation } from 'hooks/useLocation';
import { eventMock } from 'services/mock/mockData';
import { setEvent, useEventContext, setEventTabsConfig, useAuthContext } from 'store';
import { TicketingAndRegistrationProvider } from 'store/modules/eventTicketing';
import { IError } from 'utils/handleAxiosError';

import DefaultLoading from '../Skeleton/DefaultLoading';
import { APSBlockedContent } from './ApsBlockedContent';
import { ErrorPage } from './ErrorPage';
import {
  ApsStatus,
  useAPSEventAccess,
  useEventAccess,
  useEventProductAccess,
  useFetchPageData,
} from './hooks';
import { PageBodyPortal } from './Portal';
import * as Styles from './styles';
import { TicketingBox } from './TicketingBox';

export type EventStandardContainerData<T = { [pageData: string]: unknown }> = {
  tabsConfig: MorressierEventTabsConfiguration;
  event: ResolvedPromise<ReturnType<typeof fetchEventInfo>>['eventInfo']['event'];
  stats: ResolvedPromise<ReturnType<typeof fetchEventInfo>>['eventInfo']['stats'];
  ticketingProduct: TicketingProduct;
  useDummyData?: boolean;
  hasAccess?: boolean;
  isLoadingAccess?: boolean;
} & T;

export type BlockedComponentType = {
  disableEmbargoedPageBlock?: boolean;
};

interface EventStandardContainerProps {
  error?: IError;
  data?: EventStandardContainerData;
  pageName?: string;
  metaTags?: MetaTagsProps;
  Component: ComponentType<Partial<EventStandardContainerData>> & BlockedComponentType;
  LoadingComponent?: React.FC<{ [x: string]: unknown }>;
  showIfEmbargoed?: boolean;
  useFadeoutBehaviour?: boolean;
  fetchEmbargoedPageData?: () => { [pageData: string]: any };
}

export const EventStandardContainer: React.FC<EventStandardContainerProps> = props => {
  const {
    error,
    data,
    Component,
    LoadingComponent = DefaultLoading,
    pageName,
    metaTags = {},
    fetchEmbargoedPageData,
    useFadeoutBehaviour = false,
  } = props;

  const event = data?.event;

  const { data: tabsConfig } = useSWR(
    event?.id ? [event?.id, 'tabs-config'] : null,
    fetchEventTabsConfig,
    {
      fallbackData: data?.tabsConfig,
      revalidateOnFocus: false,
    },
  );
  const banner = event?.banner_url || event?.brand_theme?.header_banner_url;
  const logo = event?.logo_url || event?.brand_theme?.header_logo_url;
  const title = pageName ? `${event?.name} | ${pageName}` : event?.name;
  const isTicketingFlagEnabled = FeatureFlags.Event_Ticketing.isEnabled();
  const needsTicketToAccess = isTicketingFlagEnabled && data?.ticketingProduct?.ticketEnabled;

  const { isFallback, query } = useRouter();
  const { dispatch } = useEventContext();
  const { state } = useAuthContext();
  const origin = useLocation()?.origin;

  const isAuthenticated = !!state?.profile?.email;
  const isEmbargoed = !!event?.embargoed;
  const isArchived = !!event?.archived;
  const isPublished = !!event?.publish_date && new Date() > new Date(event?.publish_date);

  const {
    eventAccess,
    isLoadingAccess: isLoadingEventAccess,
    loadingState,
    refetchAccess,
  } = useEventAccess(event?.id as string, {
    isEmbargoed,
    isPublished,
  });

  const { APSUserStatus, isAPSEvent, apsEventId, isLoadingApsAccess } = useAPSEventAccess(
    event?.id || null,
  );

  // TODO: Merge all hasAccess functionality into one hook,
  // Store data in context so it can be accessed from anywhere
  const { productAccess, isLoadingProductAccess } = useEventProductAccess(
    event?.id,
    data?.ticketingProduct,
  );
  const hasProductAccess = productAccess?.hasAccess;
  const provider = event?.ticketing?.provider;

  const isLoadingAccess =
    provider === 'morressier' ? isLoadingProductAccess : isLoadingEventAccess || isLoadingApsAccess;

  const hasAPSEventAccess = APSUserStatus === ApsStatus.isRegistered && isPublished && !isArchived;

  const hasAccess =
    provider === 'morressier'
      ? hasProductAccess || !!eventAccess?.isOrganizer
      : !!eventAccess?.hasAccess || hasAPSEventAccess;

  const getIsBlocked = () => {
    if (isLoadingAccess && isEmbargoed) return true;

    // for morressier provider we only grant access to organizers or people with tickets(productAccess)
    if (provider === 'morressier') {
      if (eventAccess?.isOrganizer) return false;
      return !hasProductAccess;
    }

    if (isAPSEvent) {
      if (hasAPSEventAccess) return false;

      if (
        //  ALLOW ACCESS FOR APS ORGANIZERS
        eventAccess?.hasAccess &&
        eventAccess.isOrganizer
      ) {
        return false;
      }

      // if it get's here we need to block access
      return true;
    }

    if (isEmbargoed && (!isAuthenticated || !eventAccess?.hasAccess) && !props.showIfEmbargoed)
      return true;
    if (!isPublished && !eventAccess?.isOrganizer) return true;

    return false;
  };

  const isBlocked = getIsBlocked();
  const enableEventBlocking = FeatureFlags.Enable_Event_Blocking.isEnabled();

  const shouldFetchEmbargoedPageData =
    isEmbargoed &&
    (provider === 'morressier' ? hasProductAccess : eventAccess?.hasAccess || hasAPSEventAccess) &&
    // isTicketingFlagEnabled &&
    !!fetchEmbargoedPageData;

  // Todo return mock/fake data and fetchEmbargoedPageData func as prop to EventStandardContainer whenever event is embargoed
  // we will only fetch page data when event is embargoed and user has access to event
  const { embargoedPageData, isLoadingEmbargoedData } = useFetchPageData(
    shouldFetchEmbargoedPageData,
    fetchEmbargoedPageData,
  );

  useEffect(() => {
    if (event) {
      dispatch(setEvent(event));
    }
  }, [event, dispatch]);

  useEffect(() => {
    if (tabsConfig) {
      dispatch(setEventTabsConfig(tabsConfig));
    }
  }, [tabsConfig, dispatch]);

  const metaTagProps = {
    title,
    description: event?.description,
    thumbnail: banner || logo,
    favicon: logo,
    ...metaTags,
  };

  const mockData = eventMock(event?.id || '');
  const mockedEvent: MorressierEvent = {
    ...mockData.event,
    banner_url: banner,
    logo_url: logo,
    brand_theme: event?.brand_theme,
    name: event?.name || mockData.event.name,
  };

  const canDisablePageBlocking = () => {
    if (isArchived || !isPublished) return false;
    if (provider === undefined) return false;

    return isTicketingFlagEnabled ? Component.disableEmbargoedPageBlock === true : false;
  };

  const shouldDisablePageBlock = canDisablePageBlocking();

  const enableFadeoutBehaviour =
    isTicketingFlagEnabled &&
    (!!useFadeoutBehaviour || needsTicketToAccess || !!provider) &&
    isPublished &&
    !isArchived;

  const renderBlockedContent = (hideCaption = false) => {
    if (isArchived) {
      return (
        <>
          <SubTitle as="span" style={{ fontWeight: 600 }}>
            {event?.name} has successfully concluded
          </SubTitle>
          {!hideCaption && (
            <Text color="grey" as="span">
              This content is no longer available.
            </Text>
          )}
        </>
      );
    }

    if (isAPSEvent) {
      if (!isPublished && APSUserStatus === ApsStatus.isRegistered) {
        return (
          <>
            <SubTitle as="span" style={{ fontWeight: 600 }}>
              {event?.name || event?.short_name} is not available yet
            </SubTitle>
            {event?.publish_date && (
              <Text color="grey" as="span">
                Stay tuned! {event?.short_name} starts on{' '}
                {format(new Date(event.publish_date), 'LLL d, yyy')}.
              </Text>
            )}
          </>
        );
      }

      return (
        <APSBlockedContent
          event={data!.event}
          isAuthenticated={isAuthenticated}
          apsEventId={apsEventId!}
          apsStatus={APSUserStatus}
        />
      );
    }

    if (isEmbargoed && !hasAccess) {
      return (
        <>
          {event?.ticketing?.provider === 'open' ? (
            <SubTitle as="span" style={{ fontWeight: 600 }}>
              Enjoy This Free Event
            </SubTitle>
          ) : (
            <SubTitle as="span" style={{ fontWeight: 600 }}>
              {event?.name} is a private event
            </SubTitle>
          )}
          {!hideCaption && (
            <Text color="grey" as="span">
              {isAuthenticated
                ? 'Get in touch with the Organizers to request access.'
                : 'Sign in if you are registered or get in touch with the Organizers to request access.'}
            </Text>
          )}
        </>
      );
    }

    return (
      <>
        <SubTitle as="span" style={{ fontWeight: 600 }}>
          {event?.name || event?.short_name} is not available yet
        </SubTitle>
        {event?.publish_date && !hideCaption && (
          <Text color="grey" as="span">
            Stay tuned! {event?.short_name} starts on{' '}
            {format(new Date(event.publish_date), 'LLL d, yyy')}.
          </Text>
        )}
      </>
    );
  };

  const getContent = () => {
    if (enableFadeoutBehaviour) {
      return (
        <TicketingBox ticketingProduct={data?.ticketingProduct} event={data!.event}>
          {renderBlockedContent(true)}
        </TicketingBox>
      );
    }

    return (
      <Styles.ModalContent>
        <div>
          <Flexbox justifyContent="center">
            {isAPSEvent ? (
              <>
                {logo ? (
                  <LogoImageWrapper>
                    <NextImage src={logo} width={100} height={100} objectFit="cover" />
                  </LogoImageWrapper>
                ) : (
                  <EmptyLogoWrapper>
                    <EmptyLogo />
                  </EmptyLogoWrapper>
                )}
              </>
            ) : (
              <NoMoreUpcomingSVG color="lightGrey" style={{ marginBottom: '1rem' }}>
                <FaRegCalendarTimes size="48px" color="#8E949E" />
              </NoMoreUpcomingSVG>
            )}
          </Flexbox>
          {renderBlockedContent()}

          {!isAPSEvent && (
            <>
              {isArchived ? (
                <Text color="grey" as="span">
                  Continue your journey by accessing over 10,000 pieces of new research.
                </Text>
              ) : (
                <Text color="grey" as="span">
                  In the meantime, be among the first to read from over 10,000 pieces of new <br />
                  research.
                </Text>
              )}
              <StyledButton
                as="a"
                style={{ marginTop: 10 }}
                href={`${origin}/request-invite`}
                color="primary"
              >
                Explore Research
              </StyledButton>
            </>
          )}
        </div>
      </Styles.ModalContent>
    );
  };

  if (isFallback) {
    return (
      <>
        <MetaTags {...metaTagProps} />
        <LoadingComponent />
      </>
    );
  }
  if (error) {
    const eventId = (new RegExp(/[a-z0-9A-Z]+/).exec(query.eventId as string) || ['/home'])[0];
    return <ErrorPage {...error} eventId={eventId === query.eventId ? '/home' : eventId} />;
  }

  if (!event) {
    return (
      <>
        <MetaTags {...metaTagProps} />
        <LoadingComponent />
      </>
    );
  }

  return (
    <ErrorBoundary>
      <MetaTags {...metaTagProps} />
      <TicketingAndRegistrationProvider
        eventId={event.id}
        isPrivateEvent={event.embargoed}
        isTicketedEvent={
          !!data?.ticketingProduct?.ticketEnabled &&
          !!provider &&
          ['morressier', 'external'].includes(provider)
        }
        userHasAccess={!!hasAccess}
        currency={getCurrency(data?.ticketingProduct?.sales_setup?.currency)}
        userRoles={
          eventAccess && {
            ...omit(eventAccess, 'hasAccess'),
          }
        }
        isLoadingAccess={isLoadingAccess || loadingState}
        refetchAccess={refetchAccess}
        ticketedProduct={data?.ticketingProduct}
        provider={provider}
      >
        {!isPublished && eventAccess?.isOrganizer && (
          <Styles.UnpublishedBox>
            <FaEyeSlash />
            <Text color="white" fontWeight="semiBold">
              This is your unpublished event preview.
            </Text>
          </Styles.UnpublishedBox>
        )}
        {(isLoadingAccess || isBlocked || isArchived || isLoadingEmbargoedData) &&
        enableEventBlocking &&
        !shouldDisablePageBlock ? (
          <>
            <Styles.BlurryBackground
              isBlocked={isBlocked || isArchived || !!isLoadingEmbargoedData}
              onClick={e => e.stopPropagation()}
              enableFadeoutBehaviour={enableFadeoutBehaviour}
              isLoading={isLoadingAccess || isLoadingEmbargoedData}
            >
              <PageBodyPortal style={{ top: 64, backgroundColor: 'unset' }}>
                {isLoadingAccess || isLoadingEmbargoedData ? (
                  <SpinnerIcon size="3rem" color="#424d5d" />
                ) : (
                  getContent()
                )}
              </PageBodyPortal>
              {
                // Now we want to show real component but with dummy data
                enableFadeoutBehaviour && data ? (
                  <Component {...data} useDummyData />
                ) : (
                  <EventHome
                    stats={mockData.stats}
                    event={mockedEvent}
                    tabsConfig={data?.tabsConfig as MorressierEventTabsConfiguration}
                    ticketingProduct={{ ticketEnabled: true }}
                    popularPresentations={null}
                    popularSessions={null}
                    popularSpeakers={null}
                    speakersData={null}
                    featuredSessions={null}
                  />
                )
              }
            </Styles.BlurryBackground>
          </>
        ) : (
          <Component
            hasAccess={hasAccess}
            {...data}
            {...embargoedPageData}
            isLoadingAccess={isLoadingAccess}
          />
        )}
      </TicketingAndRegistrationProvider>
    </ErrorBoundary>
  );
};
