import { useState } from 'react';

import { Button } from '@morressier/ts';

import { simpleToast } from 'helpers/simpleToast';
import httpService from 'services/httpService';
import { useRegAndTicketingContext } from 'store/modules/eventTicketing';

export const RegisterButton: React.FC<{ fullWidth?: boolean }> = props => {
  const [isLoading, setIsLoading] = useState(false);
  const { state, refetchAccess } = useRegAndTicketingContext();

  // Users can only register as delegates on public free(non ticketed) events they don't have access to
  const handleRegisterToEvent = () => {
    if (state.isTicketedEvent || state.userHasAccess || state.provider !== 'open') return;

    setIsLoading(true);
    httpService
      .patch({ url: `/api/v4/standalone/events/${state.eventId}/attendees/register` })
      .then(() => {
        refetchAccess();
      })
      .catch(() => {
        simpleToast("Sorry, we couldn't register you to this event. Try again later.");
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Button
      loading={isLoading}
      disabled={state.userHasAccess || state.provider !== 'open'}
      onClick={handleRegisterToEvent}
      color="primary"
      fullWidth={props.fullWidth}
    >
      {state.userHasAccess ? 'Registered' : 'Register'}
    </Button>
  );
};
