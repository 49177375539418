import { differenceInDays, differenceInMinutes, format, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const toLocaleString = (date?: string, timeZone?: string) => {
  const jsDate = date ? new Date(date) : new Date();
  return jsDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    ...(timeZone && { timeZone }),
  });
};

export const getDateString = (date?: string) => {
  const jsDate = date ? new Date(date) : new Date();
  return jsDate.toDateString();
};

/**
 * Receive a date in UTC and set it's hours to midnight
 * By default a UTC date is set relative to the user's timezone
 * e.g 22:00
 */
export const setUTCToMidnight = (utcDate: Date): string => {
  const copiedDate = new Date(utcDate.getTime());
  copiedDate.setUTCHours(0, 0, 0, 0);

  return copiedDate.toISOString();
};

export const getWeekDay = (date?: string) => {
  const jsDate = date ? new Date(date) : new Date();
  return jsDate.toLocaleTimeString([], { weekday: 'long' }).split(' ')[0];
};

export const dateStringToUTC = (date: string | Date) =>
  utcToZonedTime(typeof date === 'string' ? new Date(date) : date, 'ETC/UTC');

export const getDayDifferenceWithCurrentDate = (date?: Date | string) =>
  date ? differenceInDays(new Date(date), new Date()) : 0;

export const getMinsDifferenceWithCurrentDate = (date?: Date | string) =>
  date ? differenceInMinutes(new Date(date), new Date(), { roundingMethod: 'floor' }) : 0;

export const getEventDays = (dates: (string | Date)[]) =>
  !!dates &&
  dates?.map(date => ({
    inUserTimeZone: format(startOfDay(new Date(date)), 'E, MMM d'),
    inUTC: new Date(date).toISOString(),
    inEventTimeZone:
      typeof date === 'string' ? date : format(new Date(date.toISOString()), 'E, MMM d'),
  }));

export const getTimeInputFormat = (date: string) => {
  const dateJS = new Date(date);
  if (Number.isNaN(dateJS.getTime())) return '';
  return format(dateJS, 'HH:mm');
};

export const secondsToTime = (seconds: number) => {
  const timeFormatted = new Date(seconds * 1000).toISOString().slice(11, 19);
  const timeArray = timeFormatted.split(':');
  const lessThanHour = timeArray.length > 2 && timeArray[0] === '00';
  if (lessThanHour) {
    return `${timeArray[1]}:${timeArray[2]}`;
  }
  return timeFormatted;
};
