import { useCallback, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

import { Button, Flexbox, PlainButton, SimpleText, Text, Toast } from '@morressier/ts';

import { faHourglassEnd } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addMinutes } from 'date-fns';
import { debounce, isEqual, omit } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { FaEyeSlash, FaTicketAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { createOrder, deleteDiscount, finalizeFreeOrder, updateOrder } from 'api/ticketing';
import { useEventAccess } from 'components/EventStandardContainer/hooks';
import { PageBodyPortal } from 'components/EventStandardContainer/Portal';
import SpinnerIcon from 'components/Spinner';
import { simpleToast } from 'helpers/simpleToast';
import { useLogIn } from 'hooks/useLogIn';
import { CreateOrderResponse } from 'pages/api/product/order/create';
import { logOut, useAuthContext } from 'store';
import { PusherHandler } from 'store/modules/pusher';
import { logger } from 'utils/logger';

import { Discount, ErrorFormat } from './ApplyCoupons';
import { ConfirmationModal } from './ConfirmationModal';
import { DetailsStep } from './DetailsStep';
import {
  useTickettingCart,
  useGetEventTickets,
  Steps,
  TicketCart,
  OrderPayload,
  Ticket,
} from './hooks';
import { OrderSummary } from './OrderSummary';
import { PaymentStep } from './PaymentStep';
import { ReservedOrder } from './ReserveOrder';
import { countSpecificTicketInOrder, SelectTicketsStep } from './SelectTicketStep';
import * as Styles from './styles';
import { TicketingStorage } from './TicketingStorage';
import { getAppliedDiscount } from './utils';
import { useYupValidationResolver, validationSchema } from './validationSchema';

export type CutomerData = {
  firstName?: string;
  lastName?: string;
  email: string;
};

export type OrderItem = {
  ticketId: string | number;
  ticketName: string;
  attendee: CutomerData;
  isAvailable?: boolean; // used to know if an item is still available for purchase
  availableQty?: number | null;
  reducedPrice?: number | null;
  price: number;
};

export type FormValues = {
  payingCustomer: CutomerData;
  items: Record<string, OrderItem>;
};

export const applyTax = (price: number, taxInfo: Ticket['tax_info']) => {
  let grossPrice = price;

  if (!taxInfo) return grossPrice;
  const rate = Number(taxInfo?.rate);

  if (!taxInfo?.price_includes_tax) {
    grossPrice = Number(price) + (Number(price) * rate) / 100;
  }

  return grossPrice;
};

const taxValue = (price: number, taxInfo: Ticket['tax_info']) => {
  let value = 0;

  if (!taxInfo) return value;
  const rate = Number(taxInfo?.rate);

  if (!taxInfo?.price_includes_tax) {
    value = Number(price) * (rate / 100);
  } else {
    value = Number(price) - Number(price * 100) / (100 + rate);
  }

  return value;
};

const calculateTotal = (payload: OrderPayload, ticketsObject: Record<string, Ticket> | undefined) =>
  payload.items.reduce(
    (acc, cur) => {
      const grossPrice = applyTax(
        Number(cur.reducedPrice ?? cur.price),
        ticketsObject![cur.ticketId].tax_info,
      );

      const grossTax = taxValue(
        Number(cur.reducedPrice ?? cur.price),
        ticketsObject![cur.ticketId].tax_info,
      );

      acc.grossTotal += Number(grossPrice);
      acc.grossTax += Number(grossTax.toFixed(2));
      return acc;
    },
    { grossTotal: 0, grossTax: 0 },
  );
/**
 *
 * Returns all items added to user cart
 * Some items may no longer be available for purchase
 */
const getPotentialOrderItems = (
  cart: TicketCart[],
  formValues?: FormValues,
  ticketsObject?: Record<string, Ticket> | undefined,
  createdOrder?: CreatedOrder,
): Record<string, OrderItem> =>
  cart.reduce((acc, cur) => {
    const heldQty = countSpecificTicketInOrder(cur.id, createdOrder?.payload);
    const availableCount = ticketsObject?.[cur.id]?.availableQuantity;

    let availableQty =
      createdOrder && typeof availableCount === 'number'
        ? Math.max(heldQty + availableCount, availableCount)
        : availableCount;

    Array.from({ length: cur.count }, (_, i) => i + 1).forEach(item => {
      const key = `${cur.id}_${item}` as const;

      const isOnlyOneOrder = cart.length === 1 && cur.count === 1;
      const attendee = isOnlyOneOrder
        ? {
            firstName: formValues?.payingCustomer.firstName || '',
            lastName: formValues?.payingCustomer.lastName || '',
            email: formValues?.payingCustomer.email || '',
          }
        : {
            firstName: formValues?.items?.[key]?.attendee?.firstName || '',
            lastName: formValues?.items?.[key]?.attendee?.lastName || '',
            email: formValues?.items?.[key]?.attendee?.email || '',
          };

      const unitItem: OrderItem & { isAvailable?: boolean; availableQty?: number | null } = {
        ticketId: cur.id,
        ticketName: cur.name,
        attendee,
        isAvailable: availableQty === null || Number(availableQty) > 0,
        availableQty,
        price: cur.price,
        reducedPrice: null,
      };

      if (typeof availableQty === 'number' && availableQty > 0) {
        availableQty -= 1;
      }

      acc[key] = unitItem;
    });
    return acc;
  }, {} as Record<string, OrderItem>);

const getUserNames = (fullName?: string) => {
  const name = fullName?.split(' ');

  return {
    firstName: name?.[0],
    lastName: name?.[name.length - 1],
  };
};

export type CreatedOrder = {
  orderId: string;
  payload: OrderPayload;
  clientSecret: string;
  status?: 'paid' | 'pending' | 'in_progress';
  expiresAt: Date;
};

export type FE_Discount = Discount & {
  discount_text: string; // BE needs to add this
  finalTotal?: number;
  discount_amount: number;
  hasInsufficientCoupons: boolean;
};

const debouncedPusherUpdates = debounce(
  (
    func: ReturnType<typeof useGetEventTickets>['refetch'],
    setIsGettingUpdates: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setIsGettingUpdates(true);

    // we want to give users some time to read updating message
    setTimeout(() => {
      func().finally(() => setIsGettingUpdates(false));
    }, 1500);
  },
  600,
);

export const BuyTicketModal: React.FC<{
  event: MorressierEvent;
  refetchAccess: ReturnType<typeof useEventAccess>['refetchAccess'];
}> = ({ event, refetchAccess }) => {
  const [isGuest, setIsGuest] = useState<boolean>(true);
  const [isGettingUpdates, setIsGettingUpdates] = useState<boolean>(false);

  const {
    data: ticketsData,
    isLoading,
    refetch,
    isPrivateTicket,
    isPrivateMode,
  } = useGetEventTickets(event.id);
  const { state: authState, dispatch } = useAuthContext();

  const email = authState?.profile?.email;
  const profile = authState?.profile;

  const {
    cart,
    getCartItem,
    handleCartChanges,
    isTicketingModalOpen,
    activeStep,
    navigableSections,
    goToNextStep,
    goToPrevStep,
    setActiveStep,
    storageKey,
    resetTicketingState,
    closeTicketingModal,
  } = useTickettingCart(event.id, ticketsData?.tickets);

  const [createdOrder, setCreatedOrder] = useState<CreatedOrder>();
  const [isCreatingOrder, setisCreatingOrder] = useState(false);
  const [showTimeUp, setShowTimeUp] = useState(false);

  const tickets = ticketsData?.tickets;
  const ticketsObject = tickets?.reduce((acc, cur) => {
    acc[cur.id] = cur;

    return acc;
  }, {} as Record<string, Ticket>);

  const resolver = useYupValidationResolver(
    validationSchema(getPotentialOrderItems(cart, undefined, ticketsObject, createdOrder)),
  );

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver,
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      payingCustomer: {
        firstName: getUserNames(profile?.full_name).firstName || '',
        lastName: getUserNames(profile?.full_name).lastName || '',
        email: profile?.email || '',
      },
      items: {},
    },
  });

  const { getValues, reset, setValue } = methods;

  useEffect(() => {
    if (profile?.email) {
      setIsGuest(false);
    }
  }, [profile?.email]);

  useEffect(() => {
    if (cart) {
      reset({
        items: getPotentialOrderItems(cart, getValues()),
        payingCustomer: getValues().payingCustomer,
      });
    }
  }, [cart, getValues, reset]);

  const { handleLogIn } = useLogIn();

  const handleNotYou = () => {
    dispatch(logOut());
    setValue('payingCustomer', {
      firstName: '',
      lastName: '',
      email: '',
    });
  };

  // const handleLogIn = () => handleLogIn();

  const { push, pathname, query } = useRouter();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [discountData, setDiscountData] = useState<Discount>();

  const handlePaymentSuccess = (orderId?: string) => {
    resetTicketingState(() => {
      reset();
      setCreatedOrder(undefined);
      setDiscountData(undefined);
    }).then(() => {
      setIsConfirmationModalOpen(true);

      push(
        { pathname, query: { ...query, orderId: createdOrder?.orderId || orderId } },
        undefined,
        {
          shallow: true,
        },
      );
    });
  };

  useEffect(() => {
    if (!createdOrder) {
      TicketingStorage.getCreatedOrder(storageKey).then(order => {
        if (order && order.orderId && order.clientSecret) {
          setCreatedOrder(order);
        }
      });
    } else {
      TicketingStorage.setCreatedOrder(storageKey, createdOrder);
    }
  }, [createdOrder, storageKey]);

  useEffect(() => {
    if (!createdOrder) return;

    if (!discountData) {
      TicketingStorage.getCoupon(storageKey).then(coupon => {
        if (coupon) {
          setDiscountData(coupon);
        }
      });
    } else {
      TicketingStorage.setCoupon(storageKey, discountData);
    }
  }, [createdOrder, discountData, storageKey]);

  /**
   * Contains all the items the user has added to cart. Some may no longer be available.
   */
  const potentialOrderItems = useMemo(
    () => getPotentialOrderItems(cart, getValues(), ticketsObject, createdOrder),
    [cart, createdOrder, getValues, ticketsObject],
  );

  /**
   * This payload filters out unavailable order items.
   * So at every point it contains items a user can buy.
   *
   * since this is a memoized function and we aren't in watch mode(useForm mode === 'onChange')...
   * getValues wouldn't subscribe to most recent input changes on every rerender
   * if we want the most recent values we need to pass values into this memoized function
   */
  const getOrderPayload = useCallback(
    (_values?: FormValues): OrderPayload => {
      const values = _values || getValues();

      const customer: FormValues['payingCustomer'] = {
        ...values.payingCustomer,
        email: values.payingCustomer.email || createdOrder?.payload.customer.email || '',
      };

      const formValues = values;
      formValues.payingCustomer = customer;

      return {
        customer,
        items: Object.values(getPotentialOrderItems(cart, values, ticketsObject, createdOrder))
          .filter(item => item.isAvailable === true)
          .map(({ ticketName, availableQty, isAvailable, ...rest }) => rest),
      };
    },
    [cart, createdOrder, getValues, ticketsObject],
  );

  const payloadItems = useMemo(() => getOrderPayload(), [getOrderPayload]);

  const appliedDiscount = getAppliedDiscount(discountData, payloadItems);
  const calculatedTotals = calculateTotal(payloadItems, ticketsObject);
  const total = calculatedTotals.grossTotal;

  const [missMatchMsg, setMissMatchMsg] = useState<string>();

  const removeAppliedDiscount = async () => {
    if (!createdOrder?.orderId || !discountData?.code) return;

    try {
      await deleteDiscount(createdOrder?.orderId, event.id, discountData?.code);
      await TicketingStorage.deleteCoupon(storageKey);
      setDiscountData(undefined);
    } catch (error) {
      simpleToast('Request failed, please try again.');
    }
  };

  // we call this the moment an order is created/updated
  const handleSetCreatedOrder = async (
    orderData: CreateOrderResponse,
    orderPayload: OrderPayload,
  ) => {
    const expiresAt = orderData.expires_at || addMinutes(new Date(orderData.created_at), 5);

    const _createdOrder: CreatedOrder = {
      orderId: orderData.order_id,
      payload: orderPayload,
      clientSecret: orderData.client_secret || orderData.payment_intent.client_secret,
      status: orderData.status,
      expiresAt,
    };

    // with createdOrder we can keep track of user held tickets, so...
    // just after an update/create we need to refetch tickets availability to recalculate potentially held/available tickets
    // this will help us to show miss matches if any
    await refetch();
    setCreatedOrder(_createdOrder);
    await TicketingStorage.setCreatedOrder(storageKey, _createdOrder);
    return _createdOrder;
  };

  const handleUpdate = async (goToNext = false, totalIsZero = false) => {
    const orderPayload = getOrderPayload(getValues());
    const hasOrderChanged = !!createdOrder && !isEqual(orderPayload, createdOrder?.payload);

    const orderId = createdOrder?.orderId;
    const customerEmail = orderPayload.customer.email || createdOrder?.payload.customer.email;

    if (orderId && orderPayload.items.length > 0 && event?.id && hasOrderChanged) {
      if (!customerEmail || createdOrder?.status === 'paid') return;

      const data = await updateOrder(orderPayload, orderId, event.id);

      await handleSetCreatedOrder(data, orderPayload);
    }

    if (totalIsZero && orderId) {
      await finalizeFreeOrder(orderId, event.id);
      handlePaymentSuccess(orderId);
    }

    if (goToNext) {
      goToNextStep();
    }
  };

  const handleProceed = async (forceLogin?: boolean, totalIsZero?: boolean) => {
    if (activeStep === Steps.Tickets) {
      if (!profile && forceLogin) {
        handleLogIn();
      } else {
        goToNextStep();
      }
    }

    if (activeStep === Steps.Details) {
      const isValid = await methods.trigger();

      if (isValid) {
        try {
          setisCreatingOrder(true);

          if (!createdOrder) {
            const orderPayload = getOrderPayload(getValues());

            const data = await createOrder(orderPayload, event.id);
            const { orderId } = await handleSetCreatedOrder(data, orderPayload);

            if (totalIsZero) {
              // no need to go to payment step for free tickets
              await finalizeFreeOrder(orderId, event.id);
              handlePaymentSuccess(orderId);
            } else {
              goToNextStep();
            }
          } else {
            await handleUpdate(true, totalIsZero);
          }
        } catch (err) {
          const error = err as ErrorFormat<{
            [ticketId: string]: {
              availableQuantity: number;
              message?: string;
            };
          }>;
          const message = error?.response?.data?.errors?.message;
          const details = error?.response?.data?.errors?.details;

          const availabilityMissmatch =
            details && Object.values(details).some(detail => detail.availableQuantity === 0);

          if (availabilityMissmatch) {
            refetch();
          }

          if (
            message &&
            [
              'Max. Registrations must be between 1 and 10',
              'The Event reached the maximum number of Registrations. Sales have closed.',
            ].includes(message)
          ) {
            setMissMatchMsg(
              'The Event reached the maximum number of Registrations. Sales have closed.',
            );
          }

          toast(() => (
            <Toast
              title={
                typeof message === 'string'
                  ? message
                  : 'An error occurred while processing this request. Please try again or contact our support team'
              }
            />
          ));
        } finally {
          setisCreatingOrder(false);
        }
      }
    }
  };

  const renderSelectionSteps = () => {
    switch (activeStep) {
      case Steps.Tickets:
        return (
          <SelectTicketsStep
            tickets={ticketsData?.tickets}
            getCartItem={getCartItem}
            handleCartChanges={handleCartChanges}
            createdOrder={createdOrder}
          />
        );

      case Steps.Details:
        return <DetailsStep isGuest={isGuest} profile={profile} items={potentialOrderItems} />;

      case Steps.Payment: {
        return createdOrder ? (
          <PaymentStep
            eventId={event.id}
            clientSecret={createdOrder.clientSecret}
            createdOrder={createdOrder}
            currentPayload={getOrderPayload(getValues())}
            handlePaymentSuccess={handlePaymentSuccess}
            discount={appliedDiscount}
            handleUpdate={handleUpdate}
            goToPrevStep={goToPrevStep}
            isTotalZero={total === 0 || appliedDiscount?.finalTotal === 0}
          />
        ) : null;
      }

      default:
        return null;
    }
  };

  const getStepName = (step: Steps) => Object.keys(Steps).find(key => Steps[key] === step);

  const isQtyMissmatch = useMemo(() => {
    const cartTotalItems = cart.reduce((acc, cur) => cur.count + acc, 0);
    const orderItemsTotal = getOrderPayload().items.length;

    return isLoading ? false : cartTotalItems !== orderItemsTotal;
  }, [cart, getOrderPayload, isLoading]);

  // we hide the payment step for free tickets or 100% discounted tickets (i.e when total is 0)
  const shouldHidePaymentStep =
    (total === 0 || appliedDiscount?.finalTotal === 0) && cart.length > 0;

  const shouldUpdate = (payload: { orderId: string; eventId: string }) =>
    new Promise(resolve => {
      setTimeout(() => {
        let orderId;

        // hacky way to get orderId
        setCreatedOrder(prev => {
          orderId = prev?.orderId;

          return prev;
        });

        return resolve(payload.orderId !== orderId);
      }, 200);
    });

  return (
    <>
      {isTicketingModalOpen && (
        <PageBodyPortal style={{ top: 0, backgroundColor: 'rgba(66,77,93, 0.5)' }}>
          <FormProvider {...methods}>
            <Styles.TicketingModal isPaymentStep={activeStep === Steps.Payment}>
              <Styles.CloseModal
                onClick={() => {
                  closeTicketingModal();

                  if (isConfirmationModalOpen) {
                    setIsConfirmationModalOpen(false);

                    // sometimes the access from API takes up to 2 secs before the data is updated.
                    // we can improve this later to refetch access by listening to pusher updates
                    refetchAccess(2500);
                  }

                  // shallow redirect to remove the orderId from url query
                  push(
                    { pathname, query: { ...omit(query, ['orderId', 'private-ticket']) } },
                    undefined,
                    {
                      shallow: true,
                    },
                  );
                }}
              />
              {isConfirmationModalOpen ? (
                <ConfirmationModal />
              ) : (
                <>
                  <Styles.StepsSelectionContainer>
                    <Styles.SelectionHeader>
                      <Text size="h4_new" fontWeight="bold">
                        {event.name}
                      </Text>
                      <Flexbox>
                        <Styles.StepTitle
                          isActive={activeStep === Steps.Tickets}
                          onClick={() => setActiveStep(Steps.Tickets)}
                        >
                          Select tickets
                        </Styles.StepTitle>
                        <Styles.StepTitle
                          isActive={activeStep === Steps.Details}
                          shouldDisable={!(navigableSections[Steps.Details] && cart.length > 0)}
                          onClick={() =>
                            navigableSections[Steps.Details] &&
                            cart.length > 0 &&
                            setActiveStep(Steps.Details)
                          }
                        >
                          Details
                        </Styles.StepTitle>
                        {shouldHidePaymentStep ? null : (
                          <Styles.StepTitle
                            isActive={activeStep === Steps.Payment}
                            shouldDisable={!(navigableSections[Steps.Payment] && cart.length > 0)}
                            onClick={async () =>
                              navigableSections[Steps.Payment] &&
                              cart.length > 0 &&
                              // we always need to be sure the form is valid before moving to the payment step
                              (await methods.trigger()) &&
                              setActiveStep(Steps.Payment)
                            }
                          >
                            Payment
                          </Styles.StepTitle>
                        )}
                      </Flexbox>
                    </Styles.SelectionHeader>

                    {isPrivateTicket && isPrivateMode && (
                      <Styles.PrivatTicketBar justifyContent="center" alignItems="center">
                        <FaEyeSlash className="mr1" />
                        <Text color="white" fontWeight="semiBold">
                          This is a private ticket
                        </Text>
                      </Styles.PrivatTicketBar>
                    )}

                    <Styles.Banner src={event.banner_url} />

                    {createdOrder && (
                      <ReservedOrder createdOrder={createdOrder} setShowTimeUp={setShowTimeUp} />
                    )}

                    {showTimeUp ? (
                      <Flexbox
                        data-name="scroll-body"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        style={{ textAlign: 'center', flex: '1 1 auto' }}
                      >
                        <Styles.TimeupLogoWrapper>
                          <FontAwesomeIcon icon={faHourglassEnd} />
                        </Styles.TimeupLogoWrapper>
                        <SimpleText fontWeight="bold" style={{ fontSize: '20px' }}>
                          Time's up! You'll have to start again
                        </SimpleText>
                        <SimpleText className="mt2">
                          Sorry, but reservations have to be completed within 30 mins.
                        </SimpleText>
                        <div className="mt2">
                          <Button
                            onClick={() => {
                              resetTicketingState(() => {
                                reset();
                                setCreatedOrder(undefined);
                                setDiscountData(undefined);
                                setActiveStep(Steps.Tickets);
                                setShowTimeUp(false);
                              }).then(() => refetch());
                            }}
                            color="primary"
                          >
                            Choose Tickets
                          </Button>
                        </div>
                      </Flexbox>
                    ) : (
                      <Flexbox data-name="scroll-body" flexDirection="column">
                        {(isLoading || isGettingUpdates) && (
                          <Styles.FetchingTicketsWrapper
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                          >
                            <SpinnerIcon
                              style={{
                                width: '56px',
                                height: '56px',
                              }}
                            />
                            <Text fontWeight="semiBold">Loading tickets updates.</Text>
                          </Styles.FetchingTicketsWrapper>
                        )}

                        {(isQtyMissmatch || missMatchMsg) && (
                          <Styles.InfoBox
                            type="error"
                            style={{
                              background: 'unset',
                              border: '1px solid #EA6060',
                            }}
                          >
                            {missMatchMsg ||
                              'The quantity and ticket type selected are no longer available.'}
                          </Styles.InfoBox>
                        )}

                        {ticketsData?.tickets.length ? (
                          <>
                            <Text size="h5_new" color="secondaryBrand" fontWeight="bold">
                              {getStepName(activeStep)?.replace('Tickets', 'Select Tickets')}
                            </Text>
                            <Flexbox style={{ margin: '1rem 0' }}>
                              {activeStep === Steps.Payment ? (
                                <>
                                  {email ? (
                                    <Text>
                                      You’re signed in as{' '}
                                      <SimpleText fontWeight="semiBold">{email} </SimpleText>
                                    </Text>
                                  ) : (
                                    <>
                                      <Text>
                                        You’re buying as a guest. We will send you an email to Sign
                                        up.
                                      </Text>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {email ? (
                                    <Text>
                                      You’re signed in as{' '}
                                      <SimpleText fontWeight="semiBold" color="secondaryBrand">
                                        {email}{' '}
                                      </SimpleText>
                                      <PlainButton onClick={handleNotYou}>
                                        <Text
                                          color="primaryLink"
                                          style={{ textDecoration: 'underline' }}
                                        >
                                          Not you?
                                        </Text>
                                      </PlainButton>
                                    </Text>
                                  ) : (
                                    <Text>
                                      Already have an account?{' '}
                                      <PlainButton onClick={handleLogIn}>
                                        <Text
                                          color="primaryLink"
                                          style={{ textDecoration: 'underline' }}
                                        >
                                          Sign in
                                        </Text>
                                      </PlainButton>
                                    </Text>
                                  )}
                                </>
                              )}
                            </Flexbox>
                            {renderSelectionSteps()}
                          </>
                        ) : (
                          <>
                            {isLoading ? null : (
                              <Styles.EmptyTicketState
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <div>
                                  <FaTicketAlt size="2.5rem" />
                                </div>
                                <Text fontWeight="semiBold">
                                  {isPrivateMode
                                    ? 'This ticket is no longer available.'
                                    : 'There are no tickets currently available.'}
                                </Text>
                              </Styles.EmptyTicketState>
                            )}
                          </>
                        )}
                      </Flexbox>
                    )}
                  </Styles.StepsSelectionContainer>

                  <OrderSummary
                    handleProceed={handleProceed}
                    cart={cart}
                    currentPayload={payloadItems}
                    setIsGuest={setIsGuest}
                    isGuest={isGuest}
                    total={total}
                    tax={calculatedTotals.grossTax}
                    activeStep={activeStep}
                    isCreatingOrder={isCreatingOrder}
                    appliedDiscount={appliedDiscount}
                    setDiscountData={setDiscountData}
                    createdOrder={createdOrder}
                    removeDiscount={removeAppliedDiscount}
                    handleUpdate={handleUpdate}
                  >
                    <Flexbox justifyContent="center" style={{ display: 'none' }}>
                      <Button onClick={() => debouncedPusherUpdates(refetch, setIsGettingUpdates)}>
                        Simulate ticket updates
                      </Button>
                    </Flexbox>
                  </OrderSummary>

                  <PusherHandler
                    channel={`product-tickets-${event.id}`}
                    event="availability_changed"
                    onUpdate={async (payload: { orderId: string; eventId: string }) => {
                      // TODO: delete this after testing
                      logger.log('TICKETS_SOLD');

                      if (await shouldUpdate(payload)) {
                        debouncedPusherUpdates(refetch, setIsGettingUpdates);
                      }
                    }}
                  />

                  <PusherHandler
                    channel={`product-tickets-${event.id}`}
                    event="order_expired"
                    onUpdate={async (payload: { orderId: string; eventId: string }) => {
                      // TODO: delete this after testing
                      logger.log('TICKETS_RELEASED');

                      if (await shouldUpdate(payload)) {
                        debouncedPusherUpdates(refetch, setIsGettingUpdates);
                      }
                    }}
                  />
                </>
              )}
            </Styles.TicketingModal>
          </FormProvider>
        </PageBodyPortal>
      )}
    </>
  );
};
