import { Button, StyledButton, StyledLink } from '@morressier/ts';

import { SignUpButton } from 'components/SingUpButton.tsx';
import { useAuthedUser } from 'hooks/useIsAuthedUser';

import { useRegAndTicketingContext } from '../../store/modules/eventTicketing';
import * as ticketingActions from '../../store/modules/eventTicketing/actions';
import { RegisterButton } from './RegisterButton';
import { canBuyTickets } from './utils';

export const getExternalUrl = (url?: string) => {
  if (!url) return undefined;

  return url.startsWith('https://') ? url : `https://${url}`;
};

export const BuyATicketButton: React.FC<{
  asLink?: boolean;
  fullWidth?: boolean;
  openExternal?: boolean;
}> = ({ asLink, fullWidth, openExternal = true }) => {
  const { dispatch, state } = useRegAndTicketingContext();
  const isAuthed = useAuthedUser();
  const allowBuyTickets = canBuyTickets(state.ticketedProduct?.sales_setup);

  const url = getExternalUrl(state.ticketedProduct?.alternative_provider?.link);
  const { provider } = state;

  if (provider === 'external') {
    return asLink ? (
      <StyledLink
        size="caption_new"
        style={{ cursor: url ? 'pointer' : 'not-allowed' }}
        onClick={() =>
          // if the prompt is open we just want to open the external website otherwise we show the prompt
          state.prompt.buyTicket || openExternal
            ? window.open(url, '_blank')
            : dispatch(ticketingActions.promptBuyTicket(true))
        }
        color="primaryLink"
      >
        Add Tickets
      </StyledLink>
    ) : (
      <StyledButton
        fullWidth={fullWidth}
        style={{ marginTop: 10 }}
        color="primary"
        onClick={() =>
          // if the prompt is open we just want to open the external website otherwise we show the prompt
          state.prompt.buyTicket || openExternal
            ? window.open(url, '_blank')
            : dispatch(ticketingActions.promptBuyTicket(true))
        }
        disabled={!!url === false}
      >
        {state.ticketedProduct?.alternative_provider?.button_cta || 'Get A Ticket'}
      </StyledButton>
    );
  }

  if (provider === 'open') {
    return isAuthed ? (
      <RegisterButton fullWidth={fullWidth} />
    ) : (
      <SignUpButton fullWidth={fullWidth} />
    );
  }

  if (allowBuyTickets) {
    return (
      <>
        {asLink ? (
          <StyledLink
            size="caption_new"
            style={{ cursor: 'pointer' }}
            onClick={e => {
              e.preventDefault();
              dispatch(ticketingActions.setIsTicketingModalOpen(true));
            }}
            color="primaryLink"
          >
            Add Tickets
          </StyledLink>
        ) : (
          <StyledButton
            fullWidth={fullWidth}
            onClick={() => dispatch(ticketingActions.setIsTicketingModalOpen(true))}
            style={{ marginTop: 10 }}
            color="primary"
          >
            Buy A Ticket
          </StyledButton>
        )}
      </>
    );
  }

  return null;
};
