import React, { useEffect } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { EntityLabel, Flexbox, Icon, Text } from '@morressier/ts';

import { Grid, GridSize, useTheme, useMediaQuery } from '@material-ui/core';
import { FaRegFileAlt, FaExternalLinkAlt, FaChevronRight as RightIcon } from 'react-icons/fa';

import AvatarBanner from 'components/AvatarBanner';
import { BlockedComponentType } from 'components/EventStandardContainer';
import { LocationDateFormat } from 'components/LocationDateFormat';
import MorressierMarkdown from 'components/MorressierMarkdown';
import Speakers from 'components/Speakers';
import StatsCard from 'components/StatsCard';
import { LibraryType } from 'constants/general';
import { UserReg, StoreAltReg, UserClassReg } from 'icons';
import { PageProps } from 'pages/event/[eventId]';
import { useEventContext } from 'store';
import { logEvent } from 'utils/eventTracking';
import { formatWebsiteShortNotation } from 'utils/formatWebsiteShortNotation';

import { TicketingWidget } from '../../components/BuyTicketModal/TicketWidget';
import * as ContainerStyles from '../styles';
import { FeaturedSessions } from './FeaturedSessions';
import { useSessionsHappeningNow, useUpcomingSessions } from './hooks';
import { MostPopularPresentations } from './MostPopularPresentations';
import { MostPopularSessions } from './MostPopularSessions';
import { OrganizerCard } from './OrganizerCard';
import { SessionsHappeningNow } from './SessionsHappeningNow';
import * as Styles from './styles';
import { UpcomingSessions } from './UpcomingSessions';

type LinkParams = {
  eventId: string;
  contentLibrary?: string;
  contentLibraryTitle?: string;
  fromSearch?: string;
  from?: string;
};

export const OutsideLink = (props: { href: string; children: React.ReactNode }) => (
  <Styles.OutsideLinkEl as="a" href={props.href}>
    {props.children}
    <FaExternalLinkAlt />
  </Styles.OutsideLinkEl>
);

const renderers = {
  a: ({ href, children }: { href: string; children: React.ReactChildren }) => (
    <OutsideLink href={href}>{children}</OutsideLink>
  ),
};

export const EventHome: React.FC<PageProps['data']> & BlockedComponentType = props => {
  const {
    event,
    stats,
    popularSessions,
    popularSpeakers,
    popularPresentations,
    speakersData,
    featuredSessions,
    ticketingProduct,
  } = props!;
  const { query } = useRouter();
  const banner = event.banner_url || event.brand_theme?.header_banner_url;
  const logo = event.logo_url || event.brand_theme?.header_logo_url;
  const websiteUrl = formatWebsiteShortNotation(event.website_url);

  const organization = event.organization_id;
  const orgLogo = organization?.brand_theme?.logo_url || organization?.logo_url;
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down('md'));

  const isPreEvent = new Date() < new Date(event.start_date);
  // const isPostEvent = new Date() > new Date(event.end_date);
  const endDate = new Date(event.end_date);
  endDate.setHours(23, 59, 59);
  const isFromNextDayOfEvent = new Date() > endDate;

  const {
    data: upcomingSessions,
    refetch: refetchUpcoming,
    loading: isLoadingUpcommingSessions,
  } = useUpcomingSessions(isFromNextDayOfEvent ? null : event.id);
  const {
    data: happenningNowSessions,
    refetch: refetchHappeningNow,
    loading: isLoadingHappeningNowSessions,
  } = useSessionsHappeningNow(isFromNextDayOfEvent ? null : event.id);

  const { state } = useEventContext();

  // the number of cards to show, we need this to manipulate the different grid breakpoints correctly
  let cardsCount = 0;

  // we increment count once for each enabled tab
  const showSessions = !!(state?.tabsConfig?.sessions?.isEnabled && (cardsCount += 1));
  const showSubmissions = !!(state?.tabsConfig?.submissions?.isEnabled && (cardsCount += 1));
  const showPeople = !!(state?.tabsConfig?.people?.isEnabled && (cardsCount += 1));
  const showExhibitors = !!(
    state?.tabsConfig?.exhibitors?.isEnabled &&
    stats?.exhibitions > 0 &&
    (cardsCount += 1)
  );

  useEffect(() => {
    logEvent('VIEWED_EVENT_PAGE', {
      id: event.id,
    });
  }, [event.id]);

  const getGridProps = (): {
    xs?: boolean | GridSize;
    sm?: boolean | GridSize;
    lg?: boolean | GridSize;
  } => {
    if (cardsCount <= 3) {
      return isBelowLg ? { xs: 12, sm: cardsCount === 3 ? 4 : 6 } : { xs: 12, sm: 6, lg: 4 };
    }

    return { xs: 12, sm: 6, lg: 3 };
  };

  const { eventId, contentLibrary, contentLibraryTitle, fromSearch, from, libraryType } = query as {
    eventId: string;
    contentLibrary?: string;
    contentLibraryTitle?: string;
    fromSearch?: string;
    from?: string;
    libraryType?: string;
  };

  const linkParams = {
    eventId,
    ...(fromSearch ? { fromSearch } : {}),
    ...(contentLibrary ? { contentLibrary, contentLibraryTitle } : {}),
    ...(from ? { from } : {}),
    ...(libraryType ? { libraryType } : {}),
  };

  const hostedByUrl =
    libraryType === LibraryType.Publisher
      ? `/cl/library/${contentLibrary}/societies/${organization?._id}`
      : `/cl/organizations/${organization?._id}`;

  return (
    <>
      <AvatarBanner
        noBanner={!banner}
        avatarUrl={logo ?? ''}
        bannerName={contentLibraryTitle || organization?.name}
        bannerUrl={banner ?? ''}
        eventName={event.name}
      />
      <ContainerStyles.PageContainer>
        {contentLibraryTitle ? (
          <Flexbox className="mt2 mb1">
            <Link href={`/library/${contentLibrary}`}>
              <div style={{ cursor: 'pointer' }}>
                <Text color="primaryLink" fontWeight="bold">
                  {contentLibraryTitle}
                  <Icon as={RightIcon} style={{ position: 'relative', paddingTop: '3px' }} />
                </Text>
              </div>
            </Link>
            <Link href={`/library/${contentLibrary}/events`}>
              <div style={{ cursor: 'pointer' }}>
                <Text color="primaryLink" fontWeight="bold">
                  Events
                  <Icon as={RightIcon} style={{ position: 'relative', paddingTop: '3px' }} />
                </Text>
              </div>
            </Link>
            <div style={{ cursor: 'pointer' }}>
              <Text>{event.name}</Text>
            </div>
          </Flexbox>
        ) : (
          <EntityLabel className="mt2 mb1">Conference</EntityLabel>
        )}
        <Grid container spacing={isBelowLg ? 2 : 4}>
          <Grid item xs={12} md={9}>
            <Styles.EventTitle data-test-id="event-title">{event.name}</Styles.EventTitle>
            <Text color="grey" fontWeight="regular" size="body1_new" style={{ margin: '8px 0' }}>
              <LocationDateFormat
                startDate={event.start_date}
                endDate={event.end_date}
                location={
                  event.location ||
                  `${event.city ? `${event.city}, ${event.country}` : event.country}`
                }
              />{' '}
              {websiteUrl && (
                <OutsideLink href={event.website_url}>
                  {websiteUrl.replace(/^(www\.)/, '')}
                </OutsideLink>
              )}
            </Text>
            <MorressierMarkdown source={event.description || ''} customRenderers={renderers} />
          </Grid>

          <TicketingWidget
            event={event}
            ticketingProduct={ticketingProduct}
            hasAccessToProduct={!!props.hasAccess}
            isLoadingAccess={!!props.isLoadingAccess}
          />
        </Grid>
        <Grid
          container
          spacing={isBelowLg ? 2 : 4}
          style={{ marginTop: '24px', marginBottom: '24px' }}
        >
          {showSessions && (
            <Grid {...getGridProps()} item>
              <StatsCard
                label={state?.tabsConfig?.sessions?.label || 'Sessions'}
                icon={UserClassReg}
                stat={stats?.sessions}
                link={`/event/${event.id}/sessions`}
                linkParams={linkParams as LinkParams}
                passQueryParam
              />
            </Grid>
          )}
          {showSubmissions && (
            <Grid {...getGridProps()} item>
              <StatsCard
                label={state?.tabsConfig?.submissions?.label || 'Submissions'}
                icon={FaRegFileAlt}
                stat={stats?.submissions}
                link={`/event/${event.id}/submissions`}
                linkParams={linkParams as LinkParams}
                passQueryParam
              />
            </Grid>
          )}
          {showPeople && (
            <Grid {...getGridProps()} item>
              <StatsCard
                label={state?.tabsConfig?.people?.label || 'People'}
                icon={UserReg}
                stat={stats?.people}
                link={`/event/${event.id}/people`}
                linkParams={linkParams as LinkParams}
                passQueryParam
              />
            </Grid>
          )}
          {showExhibitors && (
            <Grid {...getGridProps()} item>
              <StatsCard
                label={state?.tabsConfig?.exhibitors?.label || 'Exhibitors'}
                icon={StoreAltReg}
                stat={stats?.exhibitions}
                link={`/event/${event.id}/exhibitors`}
                linkParams={linkParams as LinkParams}
                passQueryParam
              />
            </Grid>
          )}
        </Grid>
        {organization && (
          <Grid container spacing={isBelowLg ? 2 : 4}>
            <Grid item xs={12} lg={6}>
              <EntityLabel>Hosted By</EntityLabel>
              {organization?.content_library ? (
                <a
                  href={hostedByUrl}
                  tabIndex={0}
                  style={{ textDecorationColor: '#424D5D' }}
                  onClick={() => {
                    logEvent('STANDALONE_CLICKED_HOSTED_BY', {
                      organization_id: organization._id,
                    });
                  }}
                  onKeyDown={() => undefined}
                >
                  <OrganizerCard
                    eventName={event.organization_id?.name}
                    organizationName={organization.name}
                    organizationLogo={orgLogo}
                  />
                </a>
              ) : (
                <span>
                  <OrganizerCard
                    eventName={event.organization_id?.name}
                    organizationName={organization.name}
                    organizationLogo={orgLogo}
                  />
                </span>
              )}
            </Grid>
          </Grid>
        )}
      </ContainerStyles.PageContainer>

      {(!event.embargoed || props.hasAccess) && (
        <>
          <ContainerStyles.PageContainer>
            {isFromNextDayOfEvent ? (
              <>
                {showSessions && popularSessions && (
                  <MostPopularSessions
                    sessions={popularSessions}
                    event={event}
                    isEmbargoed={event.embargoed}
                  />
                )}
              </>
            ) : (
              <>
                {!isPreEvent && showSessions && (
                  <SessionsHappeningNow
                    event={event}
                    isLoading={isLoadingHappeningNowSessions}
                    data={happenningNowSessions}
                    refetch={refetchHappeningNow}
                    isEmbargoed={event.embargoed}
                    nextSessionStartTime={upcomingSessions?.sessions[0]?.start_date}
                  />
                )}
                {showSessions && (
                  <UpcomingSessions
                    event={event}
                    isLoading={isLoadingUpcommingSessions}
                    data={upcomingSessions}
                    refetch={async () => {
                      refetchUpcoming();
                      refetchHappeningNow();
                    }}
                    isEmbargoed={event.embargoed}
                  />
                )}
              </>
            )}
          </ContainerStyles.PageContainer>
          {isFromNextDayOfEvent ? (
            <>
              {popularSpeakers && !!popularSpeakers.count && showSessions && (
                <ContainerStyles.SectionContainer>
                  <ContainerStyles.PageContainer>
                    <Speakers
                      event={event}
                      data={popularSpeakers || { count: 0, speakers: [] }}
                      title="Most Popular Speakers"
                      hideTotalSpeakersMessage
                    />
                  </ContainerStyles.PageContainer>
                </ContainerStyles.SectionContainer>
              )}
              {showSubmissions && (
                <ContainerStyles.PageContainer>
                  <MostPopularPresentations
                    presentations={popularPresentations || []}
                    eventId={event.id}
                  />
                </ContainerStyles.PageContainer>
              )}
            </>
          ) : (
            <>
              {!!speakersData?.speakers?.length && showSessions && (
                <ContainerStyles.SectionContainer>
                  <ContainerStyles.PageContainer>
                    <Speakers event={event} data={speakersData} />
                  </ContainerStyles.PageContainer>
                </ContainerStyles.SectionContainer>
              )}
            </>
          )}

          {!!featuredSessions?.sessions.length && showSessions && (
            <ContainerStyles.PageContainer>
              <FeaturedSessions
                data={featuredSessions}
                isEmbargoed={event.embargoed}
                event={event}
              />
            </ContainerStyles.PageContainer>
          )}
        </>
      )}
    </>
  );
};

EventHome.disableEmbargoedPageBlock = true;
