import { useRouter } from 'next/router';

import { Button } from '@morressier/ts';

import { Grid, useTheme, useMediaQuery } from '@material-ui/core';

import { useSessionBookmarksInView } from 'containers/SessionsList/hooks';
import { SessionsListItem } from 'containers/SessionsList/SessionsListItem';
import { logEvent } from 'utils/eventTracking';



import * as ContainerStyles from '../styles';
import { getSessionsRenderInfo } from './helpers';

export const MostPopularSessions: React.FC<{
  sessions?: MorressierSession[];
  event: MorressierEvent;
  isEmbargoed: boolean;
}> = ({ sessions, event, isEmbargoed }) => {
  const eventId = event.id;
  const { push } = useRouter();
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down('md'));
  const { cardMode, getGridProps } = getSessionsRenderInfo(sessions);
  const { bookmarkData, elementRef } = useSessionBookmarksInView(eventId, sessions);

  return sessions?.length ? (
    <ContainerStyles.Section ref={elementRef}>
      <ContainerStyles.SubTitle>Most Popular Sessions</ContainerStyles.SubTitle>
      <Grid
        container
        spacing={isBelowLg ? 2 : 4}
        style={{ marginTop: '0.5rem', marginBottom: '2rem' }}
      >
        {sessions?.map(session => (
          <Grid item {...getGridProps()} key={`popular-${session._id}`}>
            <SessionsListItem
              session={{ ...session, type: 'PRESENTATIONS' }}
              event={event}
              embargoed={isEmbargoed}
              isSponsored={session.is_sponsored}
              cardMode={cardMode}
              withBookmarkData={bookmarkData}
            />
          </Grid>
        ))}
      </Grid>

      <Button
        color="primary"
        onClick={() => {
          logEvent('STANDALONE_CLICKED_EXPLORE_SESSIONS');
          push(`/event/${eventId}/sessions?most-popular=true`);
        }}
      >
        Explore Sessions
      </Button>
    </ContainerStyles.Section>
  ) : null;
};
