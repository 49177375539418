import React from 'react';

import { Modal, OverlayPortal, Button, Flexbox } from '@morressier/ts';

import { FaTimes } from 'react-icons/fa';

import { usePosterWithThumbnail } from 'containers/SubmissionsList/hooks';

import { AuthorSectionProps } from './ContributionSection';
import User, { UserProps } from './index';
import * as Styles from './style';

interface ProfileModalProps {
  participant: UserProps;
  cancelAction: () => void;
}

export const ProfileModal: React.FC<ProfileModalProps> = ({ participant, cancelAction }) => {
  const eventId = participant.event.id;
  const { data: authorList } = usePosterWithThumbnail(eventId, participant.author);

  return (
    <OverlayPortal>
      <Modal wrapperComponent={Styles.WrapperModal} close={cancelAction}>
        <Styles.CloseModalBanner>
          <Flexbox style={{ padding: '0 0.8rem 0 0.5rem' }}>
            <Button onClick={cancelAction}>
              <Flexbox alignItems="end">
                <FaTimes style={{ margin: '0.3rem' }} />
                Close
              </Flexbox>
            </Button>
          </Flexbox>
        </Styles.CloseModalBanner>
        <User {...participant} author={authorList as AuthorSectionProps[]} />
      </Modal>
    </OverlayPortal>
  );
};
