import React, { useCallback, useState } from 'react';
import { CardGrid, UserCard, EmptyState, Flexbox, Text, StyledInput } from '@morressier/ts';
import { LinkProps } from 'next/link';
import { FaChalkboardTeacher, FaUsers, FaSearch } from 'react-icons/fa';
import { PeopleType } from 'api/people';
import { useRouter } from 'next/router';
import { PageProps } from 'pages/event/[eventId]/people';
import Paging from 'components/Paging';
import { commaFormat } from 'utils/numberFormatter';
import { useAuthedUser } from 'hooks/useIsAuthedUser';
import { logEvent } from 'utils/eventTracking';
import { useEventContext } from 'store';
import { BlockedComponentType } from 'components/EventStandardContainer';
import throttle from 'lodash/throttle';
import { useMediaQuery } from '@material-ui/core';
import mTheme from '@morressier/ts/_dist/cjs/styles/Theme';
import { CustomButton } from './styles';
import * as ContainerStyles from '../styles';
import { usePeopleList } from './hooks';

export const defaultPageSize = 18;
const getEmptyText = (people: PeopleType) => `No ${people} have been announced yet`;

const getEmptyIcon = {
  presenters: <FaChalkboardTeacher size="70px" color="lightGrey" />,
  authors: <FaUsers size="70px" color="lightGrey" />,
  delegates: <FaUsers size="70px" color="lightGrey" />,
  chairs: <FaUsers size="70px" color="lightGrey" />,
  users: <FaUsers size="70px" color="lightGrey" />,
};

export const PeopleList: React.FC<PageProps['data'] & { label: string }> & BlockedComponentType =
  props => {
    const { query, pathname, push } = useRouter();
    const { label, defaultFilter } = props;
    const [searchQuery, setSearchQuery] = useState('');
    const [q, setQ] = useState('');
    const isBelowLg = useMediaQuery(`(max-width: 960px)`);
    const isSmall = useMediaQuery(`(max-width: 650px)`);
    const {
      filter: queryFilter = defaultFilter,
      page = 1,
      eventId,
      contentLibrary,
      contentLibraryTitle,
      from,
    } = query as {
      eventId: string;
      page: string;
      filter: PeopleType;
      contentLibrary?: string;
      contentLibraryTitle?: string;
      from?: string;
    };

    const { total, people, totalUsers, isLoading } = usePeopleList(
      eventId,
      Number(page),
      queryFilter,
      props.useDummyData,
      q,
    );

    const { state } = useEventContext();
    const peopleSettings = state?.tabsConfig?.people?.settings;
    const isAuthed = useAuthedUser();
    const pageRouteBuilder = (pageNumber: number): LinkProps['href'] => ({
      pathname,
      query: { ...query, page: pageNumber },
    });

    const handleFilterChange = (peopleFilter: PeopleType) => {
      push({
        pathname,
        query: {
          eventId,
          filter: peopleFilter,
          contentLibrary,
          searchQuery,
          ...(contentLibraryTitle ? { contentLibraryTitle } : {}),
          ...(from ? { from } : {}),
        },
      });
    };

    const clearFilters = () => {
      setSearchQuery('');
      setQ('');
    };

    const hasFeatured = people && people.some(presenter => presenter.featured);
    const showSessions = !!state?.tabsConfig?.sessions?.isEnabled;
    const showSubmissions = !!state?.tabsConfig?.submissions?.isEnabled;

    // Throttle search query, otherwise each key will trigger a request
    const onChange = useCallback(
      throttle((value: string) => {
        setQ(value);
      }, 500),
      [],
    );

    return (
      <ContainerStyles.PageContainer withTopMargin>
        <ContainerStyles.PageTitle>{label}</ContainerStyles.PageTitle>
        <Flexbox
          className="mb1"
          alignItems={isBelowLg ? 'flex-start' : 'center'}
          flexDirection={isBelowLg ? 'column' : 'row'}
          style={{ width: '100%' }}
        >
          <Flexbox
            alignItems="center"
            style={{
              position: 'relative',
              marginBottom: isBelowLg ? '1rem' : 0,
              width: isSmall ? '100%' : '40rem',
            }}
          >
            <StyledInput
              placeholder="Search by name, organization or department"
              value={searchQuery}
              onChange={e => {
                setSearchQuery(e.currentTarget.value);
                onChange(e.currentTarget.value);
              }}
              className="mr2"
              style={{ borderRadius: '0.25rem', width: '100%', paddingRight: '3rem' }}
            />
            <FaSearch
              style={{
                position: 'absolute',
                right: '2rem',
                color: mTheme.color.grey,
              }}
            />
          </Flexbox>
          <Flexbox
            style={{
              width: '100%',
              overflow: 'auto',
              paddingBottom: isBelowLg ? '1rem' : '0',
            }}
          >
            <CustomButton
              selected={queryFilter === PeopleType.users}
              onClick={() => handleFilterChange(PeopleType.users)}
            >
              Show All
            </CustomButton>
            {
              // speakers and presenters seem to be used interchangeably
              (peopleSettings?.speakers || peopleSettings?.presenters) && showSessions && (
                <CustomButton
                  selected={queryFilter === PeopleType.presenters}
                  onClick={() => handleFilterChange(PeopleType.presenters)}
                >
                  Speakers
                </CustomButton>
              )
            }

            {peopleSettings?.authors && showSubmissions && (
              <CustomButton
                selected={queryFilter === PeopleType.authors}
                onClick={() => handleFilterChange(PeopleType.authors)}
              >
                Authors
              </CustomButton>
            )}

            {peopleSettings?.delegates && (
              <CustomButton
                selected={queryFilter === PeopleType.delegates}
                onClick={() => handleFilterChange(PeopleType.delegates)}
              >
                Delegates
              </CustomButton>
            )}

            {peopleSettings?.chairs && showSessions && (
              <CustomButton
                selected={queryFilter === PeopleType.chairs}
                onClick={() => handleFilterChange(PeopleType.chairs)}
              >
                Chairs
              </CustomButton>
            )}
          </Flexbox>
        </Flexbox>

        <div className="mb4">
          <Text color="grey">
            {isLoading ? (
              'Loading...'
            ) : (
              <>
                {searchQuery?.length ? `${total} of ` : null}
                {commaFormat(totalUsers)} {totalUsers === 1 ? 'person' : label.toLocaleLowerCase()}
                {` `}
                {searchQuery?.length ? (
                  <span
                    role="button"
                    tabIndex={0}
                    style={{
                      cursor: 'pointer',
                      color: mTheme.color.secondaryBrand,
                      textDecoration: 'underline',
                    }}
                    onClick={clearFilters}
                    onKeyDown={clearFilters}
                  >
                    Clear Filters
                  </span>
                ) : null}
              </>
            )}
          </Text>
        </div>
        {people && people.length ? (
          <div style={{ marginTop: '1rem' }}>
            <CardGrid distribution="grid">
              {people.map(person => (
                //
                <UserCard
                  key={person._id}
                  user={person}
                  onClick={() => {
                    logEvent('STANDALONE_CLICKED_USER_CARD', {
                      user_id: person._id,
                    });
                    push({
                      pathname: `${pathname}/[userId]`,
                      query: {
                        eventId,
                        userId: person._id,
                        contentLibrary,
                        ...(contentLibraryTitle ? { contentLibraryTitle } : {}),
                        ...(from ? { from } : {}),
                      },
                    });
                  }}
                  featuredHeight={hasFeatured}
                  isPrivate={!isAuthed && !person.public_profile}
                />
              ))}
            </CardGrid>

            {total ? (
              <Paging
                currentPage={Number(page)}
                totalPages={Math.ceil(total / defaultPageSize)}
                pageRouteBuilder={pageRouteBuilder}
              />
            ) : null}
          </div>
        ) : (
          <div style={{ margin: '5rem 0 15rem 0' }}>
            <EmptyState title={getEmptyText(queryFilter)} icon={getEmptyIcon[queryFilter]} />
          </div>
        )}
      </ContainerStyles.PageContainer>
    );
  };
