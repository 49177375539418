import React, { useReducer } from 'react';

import { useRouter } from 'next/router';

import {
  EntityLabel,
  Avatar,
  Flexbox,
  Button,
  CircleSkeleton,
  StyledMenu,
  StyledMenuButton,
  BorderMenuList,
} from '@morressier/ts';

import { MenuItem } from '@reach/menu-button';
import { FaComment, FaVideo } from 'react-icons/fa';
import styled from 'styled-components';

import { EventPerson } from 'api/people';
import OutsideLink from 'components/OutsideLink';
import { RowsSkeleton } from 'components/Skeleton/Rows';
import { useAuthContext } from 'store/modules/auth';
import { useRegAndTicketingContext } from 'store/modules/eventTicketing';
import { logEvent } from 'utils/eventTracking';
import { useFeatureFlagContext } from 'utils/unleashFeatureFlags/FeatureFlagProvider';
import { FEATURE_FLAGS } from 'utils/unleashFeatureFlags/flags';

import { ScheduleMeetingModal } from './ScheduleMeetingModal';
import * as Styles from './style';

export interface UserProfileProps {
  userId?: string;
  profile?: EventPerson;
  isLoading?: boolean;
  hideDetails?: boolean;
  isAuthed?: boolean;
}

const StyledDropdown = styled.div`
  [data-reach-menu-popover] {
    position: relative;
  }
`;

export const UserProfile: React.FC<UserProfileProps> = ({
  userId,
  profile,
  isLoading,
  hideDetails,
  isAuthed,
}) => {
  const { features } = useFeatureFlagContext();
  const meetingSchedulerEnabled = !!features[FEATURE_FLAGS.EVT_MEETING_SCHEDULER];
  const { query } = useRouter();
  const authedUser = useAuthContext();
  const [showScheduleModal, toggleScheduleModal] = useReducer(state => !state, false);
  const scheduleMeeting = () => toggleScheduleModal();
  const { processAction } = useRegAndTicketingContext();
  const deprecateChatFlag = features[FEATURE_FLAGS.DEPRECATE_CHAT];

  return (
    <>
      {showScheduleModal && (
        <ScheduleMeetingModal
          toggleScheduleModal={toggleScheduleModal}
          fullName={profile?.full_name}
          userId={userId!}
          eventId={query.eventId as string}
        />
      )}
      <div>
        <EntityLabel className="mt2 mb2">Participant Profile</EntityLabel>
        {isLoading ? (
          <>
            <CircleSkeleton size="128px" radius="100%" />
            <Flexbox className="mt1" flexDirection="column" style={{ maxWidth: '200px' }}>
              <RowsSkeleton />
            </Flexbox>
          </>
        ) : (
          <>
            <Avatar
              fullName={profile?.full_name ?? ''}
              size="medium"
              customSize="128px"
              src={hideDetails ? '' : profile?.picture_url}
              style={{ marginLeft: 0 }}
            />
            <Flexbox alignItems="center" className="mt1">
              <Styles.ParticipantName>{profile?.full_name}</Styles.ParticipantName>
              {profile?.featured && (
                <div className="ml1">
                  <Styles.FeaturedLabel>Featured</Styles.FeaturedLabel>
                </div>
              )}
            </Flexbox>
            {!hideDetails && (
              <>
                <Styles.ParticipantInfo>{profile?.department}</Styles.ParticipantInfo>
                <Styles.ParticipantInfo>{profile?.organization}</Styles.ParticipantInfo>
                <Styles.ParticipantInfo>
                  <OutsideLink
                    href={`/profile/${userId}`}
                    hideUnderline
                    fontWeight="semiBold"
                    onClick={() => {
                      logEvent('STANDALONE_CLICKED_FULL_PROFILE');
                    }}
                  >
                    View full profile
                  </OutsideLink>
                </Styles.ParticipantInfo>
              </>
            )}
            {hideDetails && (
              <Styles.ParticipantInfo color="grey">
                This user has a private profile.
              </Styles.ParticipantInfo>
            )}
          </>
        )}
      </div>
    </>
  );
};
