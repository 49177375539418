import { logger } from 'utils/logger';
import httpService from 'services/httpService';
import { ScheduledMeeting } from './events';

interface MeetingData {
  inviter: string;
  invitee: string;
  inviter_action: 'SENT';
  inviteeAction: 'PENDING';
  date?: string;
  time?: string;
  description?: string;
  time_zone?: string;
  session?: {
    event_id?: string;
  };
}

export const scheduleMeeting = (meetingData: MeetingData) =>
  httpService
    .post<ScheduledMeeting>({
      url: 'api/v4/user-preference/meeting-invitations',
      data: meetingData,
    })
    .then(response => response.data)
    .catch(logger.log);
