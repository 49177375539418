import { useRouter } from 'next/router';

import { SimpleText, Text, Flexbox, Button } from '@morressier/ts';

import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { BiCalendarStar } from 'react-icons/bi';

import { CardSkeleton } from 'components/Skeleton/Card';
import { useSessionBookmarksInView } from 'containers/SessionsList/hooks';
import { SessionsListItem } from 'containers/SessionsList/SessionsListItem';
import { useRunAt } from 'hooks/useRunAt';
import { logEvent } from 'utils/eventTracking';

import * as ContainerStyles from '../styles';
import { getSessionsRenderInfo } from './helpers';
import { useUpcomingSessions } from './hooks';
import * as Styles from './styles';

type Props = {
  event: MorressierEvent;
  data: ReturnType<typeof useUpcomingSessions>['data'];
  refetch: () => Promise<void>;
  isEmbargoed: boolean;
  isLoading: boolean;
};

export const UpcomingSessions: React.FC<Props> = props => {
  const total = props.data?.count;
  const sessions = props.data?.sessions;
  const eventId = props.event.id;

  const { push } = useRouter();
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down('md'));
  const isBelowSm = useMediaQuery(theme.breakpoints.down('xs'));
  const { titleClamp, cardMode, getGridProps } = getSessionsRenderInfo(sessions, isBelowSm);

  const clossestStartTime =
    sessions?.length &&
    sessions.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime())[0]
      .start_date;

  useRunAt({
    date: clossestStartTime,
    callback: props.refetch,
  });

  const { bookmarkData, elementRef } = useSessionBookmarksInView(eventId, sessions);

  return (
    <ContainerStyles.Section ref={elementRef}>
      <ContainerStyles.SubTitle>Upcoming Sessions</ContainerStyles.SubTitle>

      {props.isLoading && <CardSkeleton />}

      {total ? (
        <>
          <Styles.SubtitleCountCaption>
            {sessions?.length} of {total} Upcoming Sessions
          </Styles.SubtitleCountCaption>

          <Grid container spacing={isBelowLg ? 2 : 4} style={{ marginBottom: '32px' }}>
            {sessions?.map(({ description, ...session }) => (
              <Grid item {...getGridProps()} key={session._id}>
                <SessionsListItem
                  session={session}
                  embargoed={props.isEmbargoed}
                  titleClamp={titleClamp}
                  isSponsored={session.is_sponsored}
                  event={props.event}
                  sessionToCalendarExport={{ ...session, description }}
                  cardMode={cardMode}
                  withBookmarkData={bookmarkData}
                />
              </Grid>
            ))}
          </Grid>

          <Button
            color="primary"
            onClick={() => {
              logEvent('STANDALONE_CLICKED_EXPLORE_SESSIONS');
              push(`/event/${eventId}/sessions?time=upcoming`);
            }}
          >
            Explore Upcoming Sessions
          </Button>
        </>
      ) : (
        <Flexbox justifyContent="center">
          {!props.isLoading && (
            <Styles.NoMoreUpcoming>
              <Styles.NoMoreUpcomingSVG>
                <BiCalendarStar size="48px" color="#8E949E" />
              </Styles.NoMoreUpcomingSVG>
              <SimpleText
                as="span"
                color="secondaryBrand"
                fontWeight="semiBold"
                style={{ fontSize: '24px' }}
                className="mb2 mt2"
              >
                There are no further Upcoming Sessions.
              </SimpleText>
              <Text as="span" fontWeight="regular" color="grey">
                As soon as the Event has finished, we will post highlights for you to explore.
              </Text>
            </Styles.NoMoreUpcoming>
          )}
        </Flexbox>
      )}
    </ContainerStyles.Section>
  );
};
