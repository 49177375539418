import httpService from 'services/httpService';
import { logger } from 'utils/logger';

export interface DiscussionsResponse {
  discussions: MorressierDiscussion[];
  total: number;
}

export const approveDiscussion = (discussionId: string) =>
  httpService
    .put<{ discussion: MorressierDiscussion }>({
      url: `api/v3/discovery/discussions/${discussionId}/approve`,
    })
    .then(response => response.data);

export const updateDiscussion = (
  payload: {
    content: string;
    raw_content: string;
    mentions: string[];
  },
  commentId: string,
) =>
  httpService
    .patch<MorressierDiscussion>({
      url: `api/v3/discovery/discussions/${commentId}`,
      data: payload,
    })
    .then(response => response.data);

export const deleteDiscussion = (discussionId: string) =>
  httpService
    .delete<number>({ url: `api/v3/discovery/discussions/${discussionId}` })
    .then(response => response.status);

interface FetchDiscussionArgs {
  proceeding_id?: string;
  poster_id?: string;
  session_id?: string;
  limit?: number;
  offset?: number;
  sort_order?: 'ascending' | 'descending';
}
export const fetchDiscussions = ({
  poster_id,
  session_id,
  limit = 100,
  offset = 0,
  sort_order = 'ascending',
}: FetchDiscussionArgs) =>
  httpService
    .get<DiscussionsResponse>({
      url: `api/v3/discovery/posters/${poster_id}/discussions?limit=${limit}&offset=${offset}${
        session_id ? `&session=${session_id}` : ''
      }&sort_order=${sort_order}`,
    })
    .then(response => response.data)
    .catch(e => logger.log(e));

export const postDiscussion = (
  payload: {
    content: string;
    session?: string;
    parent?: string;
    raw_content: string;
    mentions: string[];
    approved: boolean;
  },
  posterId: string,
) =>
  httpService
    .post<MorressierDiscussion>({
      url: `api/v3/discovery/posters/${posterId}/discussions`,
      data: payload,
    })
    .then(response => response.data);

interface TaggableUser {
  full_name: string;
  id: string;
  picture_url: string;
  _id: string;
  department: string;
  organization: string;
}

export const getTaggableUsers = (poster_id: string, session_id?: string) =>
  httpService
    .get<TaggableUser[]>({
      url: `api/v3/discovery/posters/${poster_id}/discussions/taggable-users?session=${session_id}`,
    })
    .then(response => response.data);

export const createProceedingDiscussion = (
  payload: {
    content: string;
    session?: string;
    parent?: string;
    raw_content: string;
    mentions: string[];
    approved: boolean;
  },
  proceedingId: string,
) =>
  httpService
    .post<MorressierDiscussion>({
      url: `api/v3/standalone/proceedings/${proceedingId}/discussions`,
      data: payload,
    })
    .then(response => response.data);

export const fetchProceedingDiscussions = ({
  proceeding_id,
  session_id,
  limit = 100,
  offset = 0,
  sort_order = 'ascending',
}: FetchDiscussionArgs) =>
  httpService
    .get<DiscussionsResponse>({
      url: `api/v3/standalone/proceedings/${proceeding_id}/discussions?limit=${limit}&offset=${offset}${
        session_id ? `&session=${session_id}` : ''
      }&sort_order=${sort_order}`,
    })
    .then(response => response.data)
    .catch(e => logger.log(e));
