import { format, isSameDay, isToday, isTomorrow } from 'date-fns';

import { toLocaleString } from 'utils/dates';

type TimeTextArgs = {
  start_date: string;
  end_date: string;
  mode?: 'Grid' | 'List';
  showTimezoneAbbr?: boolean;
  showFullTime?: boolean;
  showEndDate?: boolean;
  timezone?: string;
};

export const getTimezoneName = (date: Date | string): string => {
  const dateString =
    date.constructor?.name === 'Date' ? date.toString() : new Date(date).toString();
  const [_, timezone] = dateString.match(/\((.+)\)/) as string[]; // eslint-disable-line

  return timezone;
};

export const getTimeInTimezone = (date: Date | string, timezone: string): string => {
  const dateString =
    date.constructor?.name === 'Date' ? date.toString() : new Date(date).toString();

  const fullDate = new Date(dateString).toLocaleDateString('en-US', {
    timeZone: timezone,
    timeZoneName: 'long',
  });

  const [_, tz] = fullDate.split(', ');

  return tz;
};

export const getHourMinutesInTimezone = (date: Date, timezone: string) => {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    hour: 'numeric',
    minute: 'numeric',
  };
  const formatter = new Intl.DateTimeFormat([], options);

  return formatter.format(date);
};

export const getTimezoneAbbreviation = (date: Date | string, timezone?: string) => {
  const tz = timezone ? getTimeInTimezone(date, timezone) : getTimezoneName(date);

  return tz.includes(' ')
    ? tz
        .split(' ')
        .map(word => word[0])
        .join('')
    : tz;
};

export const displayDateInLocalTimezone = (
  date: Date | number,
  timeZone: string,
  additionalOptions?: Omit<Intl.DateTimeFormatOptions, 'timeZone'>,
) => {
  const options = {
    month: 'short',
    day: '2-digit',
    timeZone,
    ...additionalOptions,
  };

  // @ts-ignore
  const formatter = new Intl.DateTimeFormat('en-US', options);
  return formatter.format(date);
};

export const getTimeText = ({
  start_date,
  end_date,
  mode,
  showTimezoneAbbr = true,
  showEndDate = true,
  showFullTime,
  timezone,
}: TimeTextArgs) => {
  const sameDay = isSameDay(new Date(start_date), new Date(end_date));
  const abbr = getTimezoneAbbreviation(start_date, timezone);

  const startTimeText = toLocaleString(start_date, timezone);
  let endTimeText = sameDay
    ? `–${toLocaleString(end_date, timezone)}`
    : ` – ${format(new Date(end_date), 'LLL d')}, ${toLocaleString(end_date, timezone)}`;

  if (!showEndDate) endTimeText = '';

  let timeText = `${startTimeText}${endTimeText}`;
  let prefix = '';

  if (isToday(new Date(start_date))) {
    prefix = `Today, `;
  }

  if (isTomorrow(new Date(start_date))) {
    prefix = `Tomorrow, `;
  }

  if (mode === 'Grid' || showFullTime) {
    const day = timezone
      ? displayDateInLocalTimezone(new Date(start_date), timezone)
      : format(new Date(start_date), 'LLL d');

    timeText = `${day}, ${toLocaleString(start_date, timezone)}${endTimeText}`;
  }

  return `${prefix}${timeText}${showTimezoneAbbr ? ` ${abbr}` : ''}`;
};

export const isHappeningNow = (start_date: string, end_date: string) => {
  const currentTime = Date.now();
  const startTime = new Date(start_date).getTime();
  const endTime = new Date(end_date).getTime();

  return currentTime >= startTime && currentTime < endTime;
};
