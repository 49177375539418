import { Button } from '@morressier/ts';
import PosterGrid from 'components/PosterGrid';
import { useRouter } from 'next/router';
import { logEvent } from 'utils/eventTracking';
import * as ContainerStyles from '../styles';
import * as Styles from './styles';

export const MostPopularPresentations: React.FC<{
  presentations: MorressierPresentation[];
  eventId: string;
}> = ({ presentations, eventId }) => {
  const { push } = useRouter();
  const showPresentations = presentations?.length ? presentations : null;

  return (
    showPresentations && (
      <ContainerStyles.Section>
        <ContainerStyles.SubTitle>Most Popular Presentations</ContainerStyles.SubTitle>
        <div
          style={{
            paddingTop: '16px',
            marginBottom: '2rem',
          }}
        >
          <PosterGrid items={presentations} event={eventId} submissionType="PRESENTATION" />
        </div>
        <Button
          color="primary"
          onClick={() => {
            logEvent('STANDALONE_CLICKED_EXPLORE_PRESENTATIONS');
            push(`/event/${eventId}/submissions?most-popular=true`);
          }}
        >
          Explore Presentations
        </Button>
      </ContainerStyles.Section>
    )
  );
};
