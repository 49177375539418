import {
  checkSessionsBookmarkStatus,
  deleteBookmark,
  fetchEventSessionsDates,
  fetchEventSessionsV3,
  saveBookmark,
  SessionQueryParamsV3,
  SessionTypeFilters,
} from 'api/session';
import { cloneDeep } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import useSWR, { KeyedMutator } from 'swr';
import { useDidElementComeIntoView } from 'hooks/useDidElementComeIntoView';
import { useAuthContext } from 'store';

export const useEventSessionsV3 = (query: SessionQueryParamsV3<SessionTypeFilters>) => {
  const { state } = useAuthContext();
  const userId = state?.profile?.id;
  const withAgenda = String(query.with_agenda) === 'true';

  const shouldFetch = withAgenda ? !!userId : !!query.eventId;

  const { data, error, mutate } = useSWR(
    shouldFetch ? [query, withAgenda ? userId : undefined] : null,
    fetchEventSessionsV3,
    { revalidateOnFocus: false },
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const removeSession = (dayString: string, sessionId: string) => {
    /**
     No need to mutate locally to avoid complexities in keeping session state in sync with BE

     locally mutate eliminates needless API call's but we'd leave this for now
     mutate(bounData => {
       const clonedData = cloneDeep(bounData);
 
       if (clonedData?.sessions[dayString]) {
         clonedData.sessions[dayString] = clonedData.sessions[dayString].filter(
           s => s._id !== sessionId,
         );
 
         clonedData.filter_total -= 1;
         clonedData.total -= 1;
         clonedData.total_pages = Math.ceil(clonedData.filter_total / 10);
 
         if (clonedData.sessions[dayString].length === 0) {
           delete clonedData.sessions[dayString];
         }
       }
 
       return clonedData;
     }, false);
     */

    mutate();
  };

  return {
    data,
    loading: !data && !error,
    removeSession,
  };
};

export const useEventSessionsDates = (eventId: string, fallbackDates: string[] = []) => {
  const { data, error } = useSWR<ResolvedPromise<ReturnType<typeof fetchEventSessionsDates>>>(
    eventId ? [eventId] : null,
    fetchEventSessionsDates,
    { fallbackData: fallbackDates, revalidateOnFocus: false },
  );

  return {
    sessionDates: data || [],
    loading: !data && !error,
  };
};

type HandleToggle = {
  type: 'SAVE' | 'DELETE';
  eventId: string;
  sessionId: string;
  session?: MorressierSession;
  userId?: string;
  boundMutate?: KeyedMutator<ReturnType<typeof useSessionsBookmarks>['bookmarks']>;
  setIsToggling?: (id: { [id: string]: boolean }) => void;
};

const handleToggleBookmark = async ({
  type,
  eventId,
  sessionId,
  session,
  userId,
  boundMutate,
  setIsToggling,
}: HandleToggle) => {
  if (!userId || !session) return;

  try {
    setIsToggling?.({ [sessionId]: true });
    const response =
      type === 'SAVE'
        ? await saveBookmark(session, eventId, userId)
        : await deleteBookmark(sessionId, eventId, userId);

    boundMutate?.(boundData => {
      const cloneData = cloneDeep(boundData);

      if (cloneData) {
        cloneData[sessionId] = response[sessionId];
      }

      return cloneData;
    }, false);
    toast(type === 'SAVE' ? `Session bookmarked` : 'Bookmark removed');
  } catch {
    toast('An error has occured. Please try again');
  } finally {
    setIsToggling?.({ [sessionId]: false });
  }
};

export const useSessionsBookmarks = (
  shouldFetch: boolean,
  eventId: string,
  sessionIds?: string, // sessionIds can be one or more comma seperated ids
  userId?: string,
) => {
  const urlParams =
    shouldFetch && userId && sessionIds
      ? [sessionIds, eventId, `session-bookmarks${sessionIds}${eventId}`]
      : null;

  const [isToggling, setIsToggling] = useState<{ [sessionId: string]: boolean }>({});
  const { data, error, mutate } = useSWR(urlParams, checkSessionsBookmarkStatus, {
    revalidateOnFocus: false,
  });

  // added this state to eliminate the case where the bookmark status toggles slightly durring a refetch
  const [bookmarks, setBookmarks] = useState(data);

  useEffect(() => {
    if (data) {
      setBookmarks(data);
    }
  }, [data]);

  const toggleBookmark = async (
    type: 'SAVE' | 'DELETE',
    sessionId: string,
    session?: MorressierSession,
    deleteCallback?: () => void,
  ) => {
    handleToggleBookmark({
      type,
      eventId,
      sessionId,
      session,
      userId,
      boundMutate: mutate,
      setIsToggling,
    }).then(() => {
      if (type === 'DELETE' && deleteCallback) {
        deleteCallback();
      }
    });
  };

  return { bookmarks, isLoading: urlParams && !data && !error, toggleBookmark, isToggling };
};

const getSessionIds = (sessions?: { _id: string }[]) =>
  sessions && sessions?.map(s => s._id).join();

export const useSessionBookmarksInView = (eventId: string, sessions?: { _id: string }[]) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const didElementGetIntoView = useDidElementComeIntoView(elementRef.current, { top: 50 });

  const { state: authState } = useAuthContext();
  const userId = authState?.profile?.id;

  const sessionIds = getSessionIds(sessions);
  const bookmarkData = useSessionsBookmarks(didElementGetIntoView, eventId, sessionIds, userId);

  return { bookmarkData, elementRef };
};
