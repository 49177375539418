import { Button, color } from '@morressier/ts';
import styled, { css } from 'styled-components';

export const CustomButton = styled(Button)<{ selected?: boolean }>`
  && {
    border-radius: 1.5rem;
    margin-right: 0.5rem;

    ${p =>
      p.selected
        ? css`
            border-color: ${color('secondaryBrand')};
          `
        : ''}
  }
`;
