import React from 'react';

import Link from 'next/link';

import { CardGrid, Flexbox, StyledLink, Text, UserCard } from '@morressier/ts';

import { FaRegFileAlt } from 'react-icons/fa';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { useEventAccess } from 'components/EventStandardContainer/hooks';
import ZeroStateBox from 'components/Utilities/ZeroStateBox';
import { FeatureFlags } from 'config/featureFlags';
import { useSessionsBookmarks } from 'containers/SessionsList/hooks';
import * as ContainerStyles from 'containers/styles';
import { useAuthedUser } from 'hooks/useIsAuthedUser';
import { useAuthContext, useEventContext } from 'store';
import { logEvent } from 'utils/eventTracking';

import {
  ContributionSection,
  AuthorSectionProps,
  SpeakerChairSectionProps,
} from './ContributionSection';
import { usePrivateProfile } from './hooks';
import * as Styles from './style';
import { UserProfile, UserProfileProps } from './UserProfile';

const mockData = [
  {
    _id: '5f02c336234e4118f6fb55d8',
    full_name: 'Henry Pereowei',
    department: 'Engineering',
    organization: 'Morressier',
    picture_url:
      'https://storage.googleapis.com/morressier_avatars_staging/4f95d076-b9a0-45b8-b0f4-8be860421545.jpeg',
    id: '5f02c336234e4118f6fb55d8',
  },
  {
    _id: '5ccaf1b009cf54000a4d345e',
    full_name: 'Mac Edgerly',
    organization: 'Morressier',
    department: 'Customer Success',
    picture_url: null,
    id: '5ccaf1b009cf54000a4d345e',
  },
  {
    _id: '5f049c38c737db52f37161f4',
    full_name: 'Margiorie Vielma Super Califragilistico Espialidoso',
    department: 'Fisica',
    organization: 'Universidad de Los Andes',
    picture_url:
      'https://storage.googleapis.com/morressier_avatars_staging/acef97c2-a7bb-48dd-bbbe-b6b57439ba1a.jpeg',
    id: '5f049c38c737db52f37161f4',
  },
];

export type UserProps = UserProfileProps & {
  userId?: string;
  event: MorressierEvent;
  author?: AuthorSectionProps[];
  speaker?: SpeakerChairSectionProps[];
  chair?: SpeakerChairSectionProps[];
  navigationTitle?: string;
  showNavigation?: boolean;
  showExploreAllSpeakers?: boolean;
  otherParticipants?: MorressierUser[] | EventSpeaker[];
  selectParticipant: (id: string) => void;
  pagination?: {
    paging: boolean;
    previous?: boolean;
    next?: boolean;
    setPrevious: () => void;
    setNext: () => void;
  };
};

const User: React.FC<UserProps> = ({
  userId,
  event,
  profile,
  author,
  speaker,
  chair,
  isLoading,
  navigationTitle = 'Other Speakers',
  showNavigation = false,
  showExploreAllSpeakers,
  otherParticipants = mockData,
  selectParticipant,
  pagination,
}) => {
  const eventId = event.id;
  const isAuthed = useAuthedUser();
  const { isLoadingAccess } = useEventAccess(eventId);
  const { hideDetails, isLoading: isLoadingAuth } = usePrivateProfile(!!profile?.public_profile);
  const isLoadingState = isLoading || isLoadingAuth || isLoadingAccess;
  const isContributing = !!(author?.length || speaker?.length || chair?.length);

  const { state } = useEventContext();
  const { state: authState } = useAuthContext();
  const showSubmissions = state?.tabsConfig?.submissions?.isEnabled;
  const showSessions = state?.tabsConfig?.sessions?.isEnabled;

  const getSession = (session?: SpeakerChairSectionProps[]) =>
    !!session?.length && !isLoadingState ? (session as unknown as MorressierSession[]) : undefined;

  const speakerSession = getSession(speaker)?.map(s => s._id);
  const chairSession = getSession(chair)?.map(s => s._id);

  const isTicketingFlagEnabled = FeatureFlags.Event_Ticketing.isEnabled();

  // previously we only wanted to show buttons when user is logged in. With Reg&Ticketing, we don't care cause the action is blocked at a higher level
  const canShowBookmarkButton = (hasSessions: boolean) => {
    if (isTicketingFlagEnabled) return !!speakerSession?.length;

    return hasSessions && !!authState?.profile?.id;
  };

  const speakerBookmarkData = useSessionsBookmarks(
    canShowBookmarkButton(!!speakerSession?.length),
    eventId,
    speakerSession?.join(),
    authState?.profile?.id,
  );

  const chairBookmarkData = useSessionsBookmarks(
    canShowBookmarkButton(!!chairSession?.length),
    eventId,
    chairSession?.join(),
    authState?.profile?.id,
  );

  return (
    <>
      <ContainerStyles.PageContainer>
        <UserProfile
          userId={userId}
          profile={profile}
          isLoading={isLoadingState}
          hideDetails={hideDetails}
          isAuthed={isAuthed}
        />
        {(!hideDetails || isLoadingState) && (
          <>
            {isContributing && !isLoadingState && (
              <Text
                size="h4_new"
                fontWeight="bold"
                color="secondaryBrand"
                style={{ marginTop: '3.5rem' }}
              >
                {profile?.full_name ?? ''}’s contribution(s)
              </Text>
            )}
            {showSubmissions && (
              <ContributionSection
                event={event}
                sectionType="author"
                sectionName="Author"
                section={author}
                submissionType="SUBMISSION"
                isLoading={isLoadingState}
              />
            )}
            {showSessions && (
              <>
                <ContributionSection
                  event={event}
                  sectionType="speaker"
                  sectionName="Speaker"
                  section={speaker}
                  submissionType="PRESENTATION"
                  isLoading={isLoadingState}
                  withBookmarkData={speakerBookmarkData}
                />
                <ContributionSection
                  event={event}
                  sectionType="chair"
                  sectionName="Chair"
                  section={chair}
                  submissionType="PRESENTATION"
                  isLoading={isLoadingState}
                  withBookmarkData={chairBookmarkData}
                />
              </>
            )}
            {!isContributing && (
              <ZeroStateBox
                roundedIcon
                noBackground
                icon={<FaRegFileAlt size="3rem" color="lightGrey" />}
                title={`${profile?.full_name} is not a Contributor to this Event`}
                label="They are participating as an Attendee."
              />
            )}
          </>
        )}
      </ContainerStyles.PageContainer>
      {showNavigation && (
        <ContainerStyles.SectionContainer>
          <ContainerStyles.PageContainer>
            <Flexbox alignItems="center" justifyContent="space-between">
              <Text size="h4_new" fontWeight="bold" color="secondaryBrand">
                {navigationTitle}
              </Text>
              {(pagination?.paging || showExploreAllSpeakers) && (
                <Flexbox>
                  {showExploreAllSpeakers && (
                    <Link
                      passHref
                      href={{
                        pathname: '/event/[eventId]/people',
                        query: { eventId },
                      }}
                    >
                      <StyledLink
                        size="body2_new"
                        fontWeight="bold"
                        color="secondaryBrand"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          textDecoration: 'none',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          logEvent('STANDALONE_CLICKED_EXPLORE_SPEAKERS');
                        }}
                      >
                        Explore all Speakers
                      </StyledLink>
                    </Link>
                  )}
                  {pagination?.paging && (
                    <>
                      <Styles.CircleButton
                        onClick={pagination.setPrevious}
                        disabled={!pagination.previous}
                        style={{ marginLeft: showExploreAllSpeakers ? '1rem' : 0 }}
                      >
                        <IoIosArrowBack />
                      </Styles.CircleButton>
                      <Styles.CircleButton
                        onClick={pagination.setNext}
                        disabled={!pagination.next}
                        style={{ marginLeft: '1.5rem' }}
                      >
                        <IoIosArrowForward />
                      </Styles.CircleButton>
                    </>
                  )}
                </Flexbox>
              )}
            </Flexbox>
            <div style={{ marginTop: '1rem' }}>
              <CardGrid distribution="grid">
                {otherParticipants?.map(person => (
                  <Styles.UserCardStyle key={person._id}>
                    <UserCard
                      user={person}
                      onClick={() => {
                        logEvent('STANDALONE_CLICKED_USER_CARD', {
                          user_id: person._id,
                        });
                        selectParticipant(person._id);
                      }}
                      featuredHeight={person.featured}
                      isPrivate={!isAuthed && !person.public_profile}
                    />
                  </Styles.UserCardStyle>
                ))}
              </CardGrid>
            </div>
          </ContainerStyles.PageContainer>
        </ContainerStyles.SectionContainer>
      )}
    </>
  );
};

export default User;
