import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { UseFormStateReturn } from 'react-hook-form';
import useSWR from 'swr';

import { useLocation } from 'hooks/useLocation';
import { EventTicketsRepsone } from 'pages/api/event-tickets';
import httpService from 'services/httpService';

import { OrderItem, FormValues, applyTax } from '.';
import { useRegAndTicketingContext } from '../../store/modules/eventTicketing';
import * as ticketingActions from '../../store/modules/eventTicketing/actions';
import { storageKey, TicketingStorage } from './TicketingStorage';

export type Ticket = {
  id: string | number;
  active?: boolean;
  name: string;
  category?: string;
  price: number;
  defaultPrice: number;
  maxPerOrder?: number;
  description?: string;
  availableUntil: Date | null;
  availableFrom: Date | null;
  availableQuantity: number | null;
  free?: boolean;
  isQuotaEnabled?: boolean;
  is_private?: boolean;
  tax_info?: {
    rate: number; // the tax percentage value in number
    price_includes_tax: boolean; // if the tax is included in the net_price
    value?: number; // optional but a nice to have value
    name: string;
    id: string;
    internal_name: string;
    gross_price?: number; // A nice to have value indicating the overal price of the ticket which includes tax
  };
};

export type BillingAddress = {
  address_line_one: string;
  country: string;
  zip_code: string;
  city: string;
  is_gdpr_accepted: boolean;
};

export type OrderPayload = {
  customer: FormValues['payingCustomer'];
  items: Omit<OrderItem, 'ticketName'>[];
};

const getEventTickets = async (eventId: string, privateTicketId?: string) =>
  httpService
    .get<EventTicketsRepsone>({
      url: `/o/api/event-tickets?eventId=${eventId}${
        privateTicketId ? `&private-ticket=${privateTicketId}` : ''
      }`,
    })
    .then(r => r.data.data);

const getQueryParams = (url = '') => {
  const q = url.split('?');
  const result: Record<string, string> = {};
  if (q.length >= 2) {
    q[1].split('&').forEach(item => {
      try {
        const [key, value] = item.split('=');
        result[key] = value;
      } catch (e) {
        result[item.split('=')[0]] = '';
      }
    });
  }

  return result;
};

export const useGetEventTickets = (eventId: string) => {
  const privateTicketId = getQueryParams(useLocation()?.href)['private-ticket'] || '';

  const { data, error, mutate } = useSWR(
    [eventId, encodeURIComponent(privateTicketId), `useGetEventTickets-${eventId}`],
    getEventTickets,
    {
      revalidateOnFocus: false,
    },
  );

  return {
    data,
    isLoading: !data && !error,
    refetch: mutate,
    isPrivateMode: !!privateTicketId,
    isPrivateTicket: data?.tickets.length === 1 && !!data.tickets[0].is_private,
  };
};

export interface TicketCart extends Ticket {
  count: number;
  total: number;
}

export enum Steps {
  Tickets = 1,
  Details = 2,
  Payment = 3,
}

const defaultNavigableSteps = {
  [Steps.Tickets]: true,
  [Steps.Details]: false,
  [Steps.Payment]: false,
};

/**
 * We want to keep the ticketing modal state and cart items even if the user refreshes the screen or navigates to other pages.
 * This hook stores and retrieves usefull data to prepopulate ticketing modal
 * TODO: remember to delete everythin in storage after succesfull checkout
 */
const useTicketingCartStorage = (
  eventId: string,
  cart: TicketCart[],
  setCart: Dispatch<SetStateAction<TicketCart[]>>,
  tickets?: Ticket[],
) => {
  const [activeStep, setActiveStep] = useState<Steps>(Steps.Tickets);
  const [navigableSections, setNavigableSections] = useState(defaultNavigableSteps);

  const { state, dispatch } = useRegAndTicketingContext();

  const isTicketingModalOpen = state?.isBuyTicketModalOpen;

  useEffect(() => {
    TicketingStorage.init();
  }, []);

  useEffect(() => {
    TicketingStorage.get(storageKey(eventId)).then(data => {
      const value = data?.value;

      if (value?.cart) {
        const ticketIds =
          tickets?.reduce((acc, cur) => {
            acc[String(cur.id)] = String(cur.id);
            return acc;
          }, {} as Record<string, string>) || {};

        // the cart should only keep track of tickets returned from the API
        const ticketsToKeep = value.cart.filter(item => !!ticketIds[String(item.id)]);
        setCart(ticketsToKeep);
      }

      if (value?.activeStep) {
        setActiveStep(value.activeStep);

        if (value.activeStep >= Steps.Details && !!value.cart.length) {
          setNavigableSections(prev => ({ ...prev, [Steps.Details]: true }));
        }
      }

      dispatch(ticketingActions.setIsTicketingModalOpen(value?.isModalOpen === true));
    });
  }, [dispatch, eventId, setCart, tickets]);

  // when cart changes from parent we need to store it.
  useEffect(() => {
    if (cart && eventId) {
      TicketingStorage.saveCart(storageKey(eventId), cart);
    }
  }, [cart, eventId]);

  // when activeStep changes from parent we need to store it.
  useEffect(() => {
    if (activeStep && eventId) {
      TicketingStorage.saveStep(
        storageKey(eventId),
        activeStep === Steps.Payment ? Steps.Details : activeStep,
      );
    }
  }, [activeStep, eventId]);

  const handleModalToggle = () => {
    dispatch(ticketingActions.toggleTicketingModal());
  };

  const closeTicketingModal = () => {
    dispatch(ticketingActions.setIsTicketingModalOpen(false));
  };

  const goToNextStep = () => {
    setActiveStep(prev => {
      const section = prev > Steps.Payment ? prev : prev + 1;
      setNavigableSections(nvs => ({ ...nvs, [section]: true }));

      return section;
    });
  };

  const goToPrevStep = () => {
    setActiveStep(prev => (prev > Steps.Tickets ? prev - 1 : 1));
  };

  const resetTicketingState = async (callback?: () => void) => {
    TicketingStorage.delete(storageKey(eventId)).then(() => {
      setActiveStep(Steps.Tickets);
      setNavigableSections(defaultNavigableSteps);
      setCart([]);
      callback?.();
    });
  };

  return {
    isTicketingModalOpen,
    handleModalToggle,
    activeStep,
    navigableSections,
    goToNextStep,
    goToPrevStep,
    setActiveStep,
    storageKey: storageKey(eventId),
    resetTicketingState,
    TicketingStorage,
    closeTicketingModal,
  };
};

export const useTickettingCart = (eventId: string, tickets?: Ticket[]) => {
  const [cart, setCart] = useState<TicketCart[]>([]);
  const store = useTicketingCartStorage(eventId, cart, setCart, tickets);

  const getCartItem = (id: string | number) => cart.find(c => c.id === id);

  const handleCartChanges = (ticket: Ticket, count: number) => {
    if (count === 0) {
      return setCart(prev => prev.filter(t => t.id !== ticket.id));
    }

    const isItemInCart = !!cart.some(c => c.id === ticket.id);
    const grossPrice = ticket.tax_info?.gross_price || applyTax(ticket.price, ticket.tax_info);

    if (isItemInCart) {
      return setCart(prev => {
        const result = prev.map(cartItem => {
          if (cartItem.id === ticket.id) {
            return { ...cartItem, count, total: count * grossPrice };
          }

          return cartItem;
        });

        return result;
      });
    }

    return setCart(prev => [...prev, { ...ticket, count, total: count * grossPrice }]);
  };

  return {
    cart,
    getCartItem,
    handleCartChanges,
    getTotal: () => cart.reduce((acc, prev) => acc + prev.total, 0),
    ...store,
  };
};

const isAnObject = (value: unknown) =>
  typeof value === 'object' && !Array.isArray(value) && value !== null;

const flattend = (Obj: Record<string, any>, _path = [] as string[]) =>
  Object.keys(Obj).reduce((acc, cur) => {
    const path = [..._path];
    let result = {};
    const value = Obj[cur];

    // our yup errors object usually where we have type and message
    if (isAnObject(value) && !Object.keys(value).includes('type')) {
      path.push(cur);
      result = { ...acc, ...flattend(value, path) };
    } else {
      path.push(cur);

      const finalPath = path.join('.');
      result[finalPath] = value;
    }

    return result;
  }, {} as Record<string, unknown>);

export const useScrollToError = (errors: UseFormStateReturn<FormValues>['errors']) => {
  useEffect(() => {
    const elements = Object.keys(flattend(errors))
      .map(
        name =>
          document.getElementsByName(name)[0] ||
          document.querySelectorAll(`[controlled-name=${name}]`)[0],
      )
      .filter(el => !!el);

    elements.sort((a, b) => b.scrollHeight - a.scrollHeight);
    elements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [errors]);
};

const pdfFetcher = (callBack: () => void) => (url: string) => {
  if (url) {
    return httpService.get<string>({ url }).then(resp => {
      if (resp?.data.indexOf('PDF') || typeof resp.data === 'string') {
        callBack();
        return resp;
      }

      throw resp;
    });
  }

  return null;
};

export const useValidPDF = (pdfUrl: string | null = null) => {
  const [isInValid, setIsInValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // The PDF can be invalid the first few seconds when it's generated.
  // We need to retry it a few times to be sure it's valid before rendering the download button
  useSWR(pdfUrl, {
    fetcher: pdfFetcher(() => {
      setIsInValid(false);
      setIsLoading(false);
    }),
    onErrorRetry: (err, key, config, revalidate, { retryCount }) => {
      setIsLoading(true);

      // Only retry up to 30 times.
      if (retryCount >= 30) {
        setIsInValid(true);
        setIsLoading(false);

        return;
      }

      // Retry after 2500 milliseconds.
      setTimeout(() => revalidate({ retryCount }), 2500);
    },
  });

  return {
    isLoading,
    isInValid,
    pdfUrl: isLoading || isInValid ? undefined : pdfUrl,
  };
};
