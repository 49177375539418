import { IError } from 'utils/handleAxiosError';
import { logger } from 'utils/logger';
import { Text, Flexbox, StyledLink, ErrorPageMessage } from '@morressier/ts';
import MorressierMarkdown from 'components/MorressierMarkdown';

export const ErrorPage: React.FC<Pick<IError, 'code' | 'message'> & { eventId: string }> = ({
  eventId,
  ...error
}) => {
  // TODO: remove this in production
  logger.log(error);

  // TODO: update page styling after design review
  return (
    <Flexbox
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: 'calc(100vh - 64px - 64px - 74px)' }}
    >
      <ErrorPageMessage
        error={error}
        homeURL={eventId === '/home' ? eventId : `/o/event/${eventId}`}
        onReloadClick={() => {
          window.location.reload();
        }}
        renderMessage={message => (
          <MorressierMarkdown
            customRenderers={{
              p: props => <Text {...props} color="grey" style={{ padding: '0.5rem 0' }} />,
              a: props => <StyledLink {...props} color="secondaryBrand" />,
            }}
            source={message}
          />
        )}
      />
    </Flexbox>
  );
};
