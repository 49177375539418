import { useCallback } from 'react';

import * as yup from 'yup';

import { OrderItem } from '.';

// function to convert an object with string paths to nested object
// e.g {'a.b.c': 'foo'} => {a: {b: {c: 'foo'}}}
const deepen = (obj: Record<string, any>) => {
  const result = {};

  Object.keys(obj).forEach(key => {
    // Split path into component parts
    const parts = key.split('.');

    // Create sub-objects along path as needed
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift() as string;
      target[part] = target[part] || {};
      target = target[part];
    }

    //  Set value at end of path
    target[parts[0]] = obj[key];
  });

  return result;
};

export const useYupValidationResolver = (validationSchema: yup.AnySchema) =>
  useCallback(
    async data => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (err) {
        const errors = err as any;

        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) =>
              deepen({
                ...allErrors,
                // path is sometimes in dot notation
                [currentError.path]: {
                  type: currentError.type ?? 'validation',
                  message: currentError.message,
                },
              }),
            {},
          ),
        };
      }
    },
    [validationSchema],
  );

const getOrderItemsSchema = (items: Record<string, OrderItem>) =>
  // we only want to validate available items
  Object.keys(items)
    .filter(key => items[key].isAvailable === true)
    .reduce((acc, cur) => {
      acc[cur] = yup.object().shape({
        attendee: yup.object().shape({
          firstName: yup.string(),
          lastName: yup.string(),
          email: yup.string().required('This field is required').email('Email is invalid'),
        }),
      });

      return acc;
    }, {});

export const validationSchema = (items: Record<string, OrderItem>) =>
  yup.object().shape({
    payingCustomer: yup.object().shape({
      firstName: yup.string().required('This field is required'),
      lastName: yup.string().required('This field is required'),
      email: yup.string().required('This field is required').email('Email is invalid'),
    }),
    ...(Object.keys(items).length > 1 && { items: yup.object().shape(getOrderItemsSchema(items)) }),
  });

export const validationSchemaForBillingAddress = () =>
  yup.object().shape({
    searchCountry: yup.string(),
    billing_address: yup.object().shape({
      address_line_one: yup
        .string()
        .required('Missing required field. Add billing Street and number.'),
      country: yup.string().required('Missing required field. Add County.'),
      zip_code: yup.string().required('Missing required field. Add Postal Code.'),
      city: yup.string().required('Missing required field. Add City.'),
      is_gdpr_accepted: yup
        .boolean()
        .isTrue('You must accept Morressier’s terms to complete this purchase.')
        .required(),
    }),
  });
