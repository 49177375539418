import { FC } from 'react';
import { borderRadius, color, ColorNames, Text } from '@morressier/ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAd, faCoffee } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

const AdWrap = styled.div`
  position: relative;
  ${borderRadius('small')};
  background: ${color('white')};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const AdImage = styled(AdWrap)<{ src: string }>`
  ${borderRadius('small')};
  background-image: url(${props => props.src});
`;

const AdCTA = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  ${borderRadius('small')};
  background: ${color('white')};
  position: absolute;
  left: 0.8rem;
  bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid ${color('lightGrey')};
`;

const PauseLabel = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  ${borderRadius('small')};
  background: ${color('lightGrey')};
  color: ${color('secondaryBrand')};
  position: absolute;
  left: 1.5rem;
  top: 1rem;
  padding: 0.1rem 0.3rem;
`;

// TODO: Move to shared-ts repo
export const IconHolder = styled(FontAwesomeIcon)<{
  color?: ColorNames;
}>`
  color: ${props => color(props.color ?? 'white')};
`;

interface AdRendererProps {
  ad?: AdMaterial;
  isPaused?: boolean;
}

const AdRenderer: FC<AdRendererProps> = ({ ad, isPaused }) =>
  ad ? (
    <AdImage src={ad?.url}>
      {isPaused && (
        <PauseLabel>
          <IconHolder icon={faCoffee} color="secondaryBrand" />
          <Text style={{ fontSize: '12px', letterSpacing: '0.17' }}>Pause</Text>
        </PauseLabel>
      )}
      <AdCTA>
        <IconHolder icon={faAd} color="secondaryBrand" />
        <Text size="body2_new" color="secondaryBrand" fontWeight="bold">
          {ad.headline}
        </Text>
      </AdCTA>
    </AdImage>
  ) : null;

export default AdRenderer;
