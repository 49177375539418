import { fetchEventPeople, PeopleType } from 'api/people';
import useSWR from 'swr';

export const usePeopleList = (
  eventId: string,
  page: number,
  peopleType: PeopleType,
  _useDummyData?: boolean,
  q?: string,
) => {
  const fetchArgs =
    eventId && page && peopleType ? `people-list${eventId}-${page}-${peopleType}-${q}` : null;

  // TODO: return dummyData when _useDummyData is true
  const { data: peopleInfo, error } = useSWR(fetchArgs, () =>
    fetchEventPeople({ eventId, page, peopleType, q }),
  );

  return {
    ...peopleInfo,
    total: peopleInfo?.total || 0,
    totalUsers: peopleInfo?.totalUsers || 0,
    isLoading: !peopleInfo && !error,
  };
};
