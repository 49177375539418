import { Text, DropDown, Flexbox, InlineText } from '@morressier/ts';

import { Grid } from '@material-ui/core';
import { isAfter, format } from 'date-fns';
import Dotdotdot from 'react-dotdotdot';

import { useCurrency } from 'store/modules/eventTicketing';

import { applyTax, CreatedOrder } from '.';
import { Ticket, useTickettingCart } from './hooks';
import * as Styles from './styles';

export const countSpecificTicketInOrder = (
  ticketId: string | number,
  orderPayload?: CreatedOrder['payload'],
) => orderPayload?.items.filter(item => String(item.ticketId) === String(ticketId)).length || 0;

const renderTaxInfo = (taxInfo: Ticket['tax_info']) =>
  taxInfo ? ` (${`Incl.`} ${taxInfo?.rate}% ${taxInfo?.name})` : '';

const renderPrice = (price: number, tax_info: Ticket['tax_info'], currency: string) => {
  const grossPrice = tax_info?.gross_price || applyTax(price, tax_info);

  if (grossPrice) {
    return Number(grossPrice) ? `${currency}${Number(grossPrice).toFixed(2)}` : 'Free';
  }

  return 'Free';
};

const MAX_QTY = 30;
export const SelectTicketsStep: React.FC<{
  tickets?: Ticket[];
  getCartItem: ReturnType<typeof useTickettingCart>['getCartItem'];
  handleCartChanges: ReturnType<typeof useTickettingCart>['handleCartChanges'];
  createdOrder: CreatedOrder | undefined;
}> = ({ tickets, getCartItem, handleCartChanges, createdOrder }) => {
  const currency = useCurrency();

  const generateTicketOptions = (ticket: Ticket) => {
    const maxPerOrder = ticket.maxPerOrder || MAX_QTY;
    const availableQuantity =
      typeof ticket.availableQuantity === 'number' ? ticket.availableQuantity : maxPerOrder;
    const count = countSpecificTicketInOrder(ticket.id, createdOrder?.payload);

    const shouldSumCount = !!(createdOrder && typeof ticket.availableQuantity === 'number');

    const numberOfItems =
      (shouldSumCount
        ? Math.min(maxPerOrder, Number(ticket.availableQuantity) + count)
        : Math.min(maxPerOrder, availableQuantity)) + 1;

    return Array.from(new Array(numberOfItems >= 0 ? numberOfItems : 0).keys()).map((_x, i) => ({
      value: i,
      text: `${i}`,
    }));
  };

  const getSalesDateMessages = (startDate: Date | null, endDate: Date | null) => {
    if (startDate && new Date() < new Date(startDate)) {
      return `Sales start on ${format(new Date(startDate), 'LLL d, y')}`;
    }

    if (endDate && new Date() < new Date(endDate)) {
      return `Sales end on ${format(new Date(endDate), 'LLL d, y')}`;
    }

    return null;
  };

  return (
    <>
      {tickets?.map((ticket, _index) => {
        const showRemainingQty = !!ticket.availableQuantity && ticket.availableQuantity <= 5;

        return (
          <Styles.TicketCard key={ticket.id} justifyContent="space-between" alignItems="center">
            <Grid container justifyContent="space-between">
              <Grid item xs={9}>
                <div>
                  <Text color="secondaryBrand" fontWeight="bold">
                    {ticket.name}
                  </Text>

                  <Text color="secondaryBrand" fontWeight="bold">
                    {renderPrice(ticket.price, ticket.tax_info, currency)}
                    <InlineText color="grey" size="caption_new">
                      {renderTaxInfo(ticket.tax_info)}
                    </InlineText>
                  </Text>
                  <Flexbox>
                    {showRemainingQty && (
                      <Text color="red" size="caption_new" fontWeight="semiBold">
                        {ticket.availableQuantity} Remaining
                      </Text>
                    )}
                    {ticket.availableQuantity !== 0 &&
                      !!getSalesDateMessages(ticket.availableFrom, ticket.availableUntil) && (
                        <Text
                          color="secondaryBrand"
                          size="caption_new"
                          style={{ marginBottom: '0.5rem' }}
                        >
                          {showRemainingQty && (
                            <InlineText as="span" color="red" size="caption_new">
                              &nbsp;·&nbsp;
                            </InlineText>
                          )}
                          {getSalesDateMessages(ticket.availableFrom, ticket.availableUntil)}
                        </Text>
                      )}
                  </Flexbox>

                  {ticket.description && (
                    <Dotdotdot clamp={2} tagName="div">
                      <Text color="grey" size="caption_new">
                        {ticket.description}
                      </Text>
                    </Dotdotdot>
                  )}
                </div>
              </Grid>

              <Grid item xs={3} container justifyContent="flex-end" alignItems="center">
                {ticket.availableQuantity === 0 &&
                !countSpecificTicketInOrder(ticket.id, createdOrder?.payload) ? (
                  <Text fontWeight="semiBold" color="red">
                    Sold Out
                  </Text>
                ) : (
                  <>
                    {ticket.availableFrom && new Date(ticket.availableFrom) > new Date() ? (
                      <></>
                    ) : (
                      <>
                        {ticket.availableUntil &&
                        isAfter(new Date(), new Date(ticket.availableUntil)) ? (
                          <Text fontWeight="semiBold" color="grey">
                            Sales ended
                          </Text>
                        ) : (
                          <DropDown
                            width="75px"
                            options={generateTicketOptions(ticket)}
                            initialValue={getCartItem(ticket.id)?.count || 0}
                            onChange={selected => {
                              handleCartChanges(ticket, Number(selected.value));
                            }}
                            textAlign="left"
                            flyoutStyle={{ maxHeight: '20vh', overflowY: 'auto' }}
                            placement={
                              _index === tickets.length - 1 && tickets.length > 3
                                ? 'top'
                                : undefined
                            }
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Styles.TicketCard>
        );
      })}
    </>
  );
};
