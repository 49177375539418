import { color, SimpleText, Text } from '@morressier/ts';
import styled, { css } from 'styled-components';

export const SubTitle = styled(SimpleText).attrs(() => ({
  as: 'h2',
  color: 'secondaryBrand',
  fontWeight: 'bold',
}))<{ withBottomPadding?: boolean }>`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;

  ${props =>
    props.withBottomPadding &&
    css`
      padding-bottom: 16px;
    `}
`;

export const SubtitleCountCaption = styled(Text)`
  font-size: 14px;
  color: ${color('grey')};
  font-weight: 600;
  margin-bottom: 0.5rem;
`;
