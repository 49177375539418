import React from 'react';
import { color, Text } from '@morressier/ts';
import styled, { css } from 'styled-components';

//
// STYLES
//

const Box = styled.div<{
  noBackground?: boolean;
}>`
  ${prop =>
    !prop.noBackground &&
    css`
      background-color: #fcfcfd;
      border: 1px solid #d4d5d6;
      border-radius: 3px;
    `}
`;

const BoxWrapper = styled.div`
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RoundedIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 128px;
  border-radius: 100%;
  background-color: ${color('sidebarHover')};
`;

interface ZeroStateBoxProps {
  icon: React.ReactElement;
  title?: string;
  label?: string;
  roundedIcon?: boolean;
  noBackground?: boolean;
}

const ZeroStateBox: React.FC<ZeroStateBoxProps> = props => {
  const { title, label, icon, roundedIcon, noBackground } = props;
  return (
    <Box noBackground={noBackground}>
      <BoxWrapper>
        {roundedIcon ? <RoundedIcon>{icon}</RoundedIcon> : icon}
        {title && (
          <Text
            fontWeight="semiBold"
            color="secondaryBrand"
            style={{ textAlign: 'center', marginTop: '1rem' }}
          >
            {title}
          </Text>
        )}
        {label && (
          <Text color="grey" style={{ textAlign: 'center', marginTop: title ? '0.5rem' : '1rem' }}>
            {label}
          </Text>
        )}
      </BoxWrapper>
    </Box>
  );
};

export default ZeroStateBox;
